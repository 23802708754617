import { Antwort } from '@shared/model/Antwort';
import { UUID } from '@util/export-types';

export class AbschnittAntwortChange {
	id: string;
	frage_id: string;

	static forAntwort(antwort: Antwort) {
		return new AbschnittAntwortChange(antwort.id, antwort.frage_id);
	}

	constructor(id: UUID, frage_id: UUID) {
		this.id = id;
		this.frage_id = frage_id;

	}
}
