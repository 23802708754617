import { NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTypeEditComponent } from '@shared/components/input-type/InputTypeEditComponent';
import { InputType } from '@shared/model/frage/input/InputType';
import { InputTypeEmail } from "@shared/model/frage/input/InputTypeEmail";
import { InputTypeTelefon } from "@shared/model/frage/input/InputTypeTelefon";
import { InputTypeText } from "@shared/model/frage/input/InputTypeText";
import { TabellenFrage } from '@shared/model/frage/TabellenFrage';
import { TabellenSpalte } from '@shared/model/frage/TabellenSpalte';
import { TabelleFrageTitleEditComponent } from '../titel/TabelleFrageTitleEditComponent';

const debug = require('debug')('TabelleErweiterbarFrageEditComponent');

@Component({
	selector: "app-editor-abschnitt-frage-edit-tabelle-erweiterbar",
	templateUrl: "./TabelleErweiterbarFrageEditComponent.html",
	styleUrls: ["./TabelleErweiterbarFrageEditComponent.less"],
	standalone: true,
	imports: [NgFor, FormsModule, TabelleFrageTitleEditComponent, InputTypeEditComponent],
})
export class TabelleErweiterbarFrageEditComponent implements OnInit {
	@Input()
	frage: TabellenFrage;

	@Input()
	availableTypes: (typeof InputType)[];

	ngOnInit(): void {
		if (!this.frage.hasSpalten()) {
			this.frage.addSpalte();
		}
	}

	getSpalten(): TabellenSpalte[] {
		return this.frage.spalten;
	}

	addSpalte() {
		this.frage.addSpalte();
	}

	removeSpalte(spalte: TabellenSpalte) {
		this.frage.removeSpalte(spalte);
	}

	hasMultipleSpalten(): boolean {
		return this.frage.spalten.length > 1;
	}

	removeSpaltenDisabled(): boolean {
		return !this.hasMultipleSpalten();
	}

	onSpalteChanged(spalte: TabellenSpalte, type: InputType) {
		if (spalte.type !== type) {
			if (
				spalte.type.type != InputTypeText.ID ||
				!(type.type in [InputTypeEmail.ID, InputTypeTelefon.ID])
			) {
				debug(
					"Löschen der Vorjahres-Referenz aufgrund von Änderung des Feld-Typs"
				);
				spalte.vorjahresTabellenFrageSpalte = null;
			}
		}

		spalte.type = type;
	}
}
