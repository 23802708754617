import { NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { AbschnittSortierenDialogComponent } from '@form-editor/abschnitt-sortieren-dialog/AbschnittSortierenDialogComponent';
import { FragebogenMetadataDialogComponent } from '@form-editor/fragebogen-metadata-dialog/FragebogenMetadataDialogComponent';
import { AbschnittEditorService } from '@form-editor/service/AbschnittEditorService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { Fragebogen } from '@shared/model/Fragebogen';
import { AlertComponent } from "../shared/alert/component/AlertComponent";
import { SpinnerComponent } from "../shared/components/spinner/SpinnerComponent";
import { FragebogenService } from "../shared/service/FragebogenService";
import { AbschnittListeComponent } from "./abschnitt-liste/AbschnittListeComponent";
import { FormEditorHeaderComponent } from "./editor-header/FormEditorHeaderComponent";

const debug = require('debug')('FormEditorComponent');

@Component({
	selector: "app-form-editor",
	templateUrl: "./FormEditorComponent.html",
	styleUrls: ["./FormEditorComponent.less"],
	standalone: true,
	imports: [
    NgIf,
    AbschnittSortierenDialogComponent,
    FragebogenMetadataDialogComponent,
    FormEditorHeaderComponent,
    AlertComponent,
    SpinnerComponent,
    AbschnittListeComponent
],
})
export class FormEditorComponent implements OnInit {
	abschnitte: Abschnitt[];

	@Input()
	fragebogen: Fragebogen;

	@ViewChild("abschnittSortierenDialog", { static: true })
	abschnittSortierenDialog: AbschnittSortierenDialogComponent;

	@ViewChild("fragebogenMetadataDialog", { static: true })
	fragebogenMetadataDialog: FragebogenMetadataDialogComponent;

	constructor(
		private route: ActivatedRoute,
		private fragebogenService: FragebogenService,
		private abschnittService: AbschnittEditorService,
		private title: Title
	) {
		title.setTitle("Fragebogen-Editor - Juve Recherche");
	}

	ngOnInit(): void {
		let fragebogenId = this.route.snapshot.paramMap.get("fragebogenId");
		this.fragebogenService
			.getFragebogenToEdit(fragebogenId)
			.then((fragebogen) => (this.fragebogen = fragebogen))
			.then((fragebogen) => this.fetchAbschnitte(fragebogen));
	}

	fetchAbschnitte(fragebogen: Fragebogen) {
		this.abschnittService.getAbschnitte(fragebogen).then((abschnitte) => {
			this.abschnitte = abschnitte;
		});
	}

	isLoaded() {
		return !!this.abschnitte;
	}

	beginMove() {
		this.abschnittSortierenDialog.show();
	}

	commitMove(abschnitte: Abschnitt[]) {
		this.abschnittService
			.saveAbschnittReihenfolge(this.fragebogen, abschnitte)
			.then(() => {
				this.abschnitte = abschnitte;
				this.abschnittSortierenDialog.enableAndHide();
			});
	}

	beginEditMetadata() {
		this.fragebogenMetadataDialog.show();
	}

	hasAbschnitte() {
		return !!this.abschnitte;
	}

	abschnitteWithId() {
		if (!this.hasAbschnitte()) {
			return [];
		}
		return this.abschnitte.filter((abschnitt) => abschnitt.hasId());
	}

	canMove() {
		return this.abschnitteWithId().length > 1;
	}
}
