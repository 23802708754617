import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";

@Component({
	selector: "app-karten-add-button",
	templateUrl: "./KartenAddButtonComponent.html",
	styleUrls: ["./KartenAddButtonComponent.less"],
	standalone: true,
	imports: [ScrollToFocusedElementDirective]
})
export class KartenAddButtonComponent {
	@Output()
	click = new EventEmitter();

	@Input()
	dimmed = false;

	onClick(e) {
		e.stopPropagation();
		this.click.emit();
	}
}
