import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Ausfueller} from "@form-viewer/model/Ausfueller";
import {AlertService} from '@shared/alert/service/AlertService';
import {Fragebogen} from '@shared/model/Fragebogen';
import {AktuelleBerechtigungService} from '@shared/service/AktuelleBerechtigungService';
import {FragebogenBuilderUtil} from "@shared/util/FragebogenBuilderUtil";
import {UUID} from '@util/export-types';
import {firstValueFrom} from 'rxjs';
import {tap} from 'rxjs/internal/operators/tap';

@Injectable({ providedIn: "root" })
export class FragebogenService {
	constructor(
		private http: HttpClient,
		private alerts: AlertService,
		private aktuelleBerechtigungService: AktuelleBerechtigungService
	) {}

	getFrageboegen(): Promise<Fragebogen[]> {
		return firstValueFrom(this.http.get<string>(`/api/editor/fragebogen`))
			.then((response) => FragebogenBuilderUtil.buildList(response))
			.catch(this.alerts.handleHttpError);
	}

	getFragebogenToEdit(id: string): Promise<Fragebogen> {
		return firstValueFrom(
			this.http.get<Fragebogen>(`/api/editor/fragebogen/${id}`)
		)
			.then((response) => new Fragebogen(response as Fragebogen))
			.catch(this.alerts.handleHttpError);
	}

	getFragebogenVorschau(id: string): Promise<Fragebogen> {
		return firstValueFrom(
			this.http.get<Fragebogen>(`/api/editor/fragebogen/${id}/vorschau`)
		)
			.then((response) => new Fragebogen(response as Fragebogen))
			.catch(this.alerts.handleHttpError);
	}

	getFragebogen(id: string): Promise<Fragebogen> {
		return firstValueFrom(
			this.http.get<Fragebogen>(`/view/fragebogen/${id}`, {
				params: this.aktuelleBerechtigungService.addCommonParams({}),
			})
		)
			.then((response) => new Fragebogen(response as Fragebogen))
			.catch(this.alerts.handleHttpError);
	}

	getRecherchekomplexAdmins(id: string): Promise<Ausfueller[]> {
		return firstValueFrom(
			this.http.get<Ausfueller[]>(
				`/view/fragebogen/recherchekomplex_admins/${id}`,
				{ params: this.aktuelleBerechtigungService.addCommonParams({}) }
			)
		)
			.then((response) => Ausfueller.buildList(response as Ausfueller[]))
			.catch(this.alerts.handleHttpError);
	}

	saveFragebogen(fragebogen: Fragebogen): Promise<Fragebogen> {
		return firstValueFrom(
			this.http.post("/api/editor/fragebogen/edit", fragebogen)
		)
			.then((response) => {
				return new Fragebogen(response as Fragebogen);
			})
			.catch(this.alerts.handleHttpError);
	}

	getOffeneFrageboegen(): Promise<Fragebogen[]> {
		return firstValueFrom(
			this.http.get<Fragebogen[]>("/view/fragebogen/listOffen", {
				params: this.aktuelleBerechtigungService.addCommonParams({}),
			})
		)
			.then((response) => {
				return response.map((fragebogen) => new Fragebogen(fragebogen));
			})
			.catch(this.alerts.handleHttpError);
	}

	uebernehmeAntworten(
		fragebogenId: UUID,
		institutionId: UUID
	): Promise<void> {
		return firstValueFrom(
			this.http
				.post<void>("/view/fragebogen/antworten-uebernehmen", null, {
					params: {
						institution: institutionId,
						fragebogen: fragebogenId,
					},
				})
				.pipe(
					tap(() =>
						this.alerts.showSuccess(
							"Antworten aus dem Vorjahr wurden erfolgreich übernommen."
						)
					)
				)
		).catch(this.alerts.handleHttpError);
	}

	hatAbgeschlosseneFragebogenVersion(
		fragebogenId: UUID,
		institutionId: UUID
	): Promise<boolean> {
		return firstValueFrom(
			this.http.get<boolean>(
				"/viewer/fragebogenstatus/hat-abgeschlossene-fragebogen-version",
				{
					params: {
						institution: institutionId,
						fragebogen: fragebogenId,
					},
				}
			)
		).catch(this.alerts.handleHttpError);
	}
}
