import { Component } from '@angular/core';
import { AbschnittSaveEventServiceService } from "../../service/AbschnittSaveEventServiceService";

enum AutosaveState {
	HIDDEN,
	SAVING,
	SAVED,
	ERROR
}

@Component({
	selector: "app-viewer-frage-autosave-indicator",
	templateUrl: "./ViewerFrageAutosaveIndicatorComponent.html",
	styleUrls: ["./ViewerFrageAutosaveIndicatorComponent.less"],
	standalone: true,
})
export class ViewerFrageAutosaveIndicatorComponent {
	static readonly HIDE_MARKER_TIMEOUT_MS = 2500;

	private state = AutosaveState.HIDDEN;
	private timeout: number | undefined;

	constructor(private abschnSaveSvc: AbschnittSaveEventServiceService) {}

	isSaving() {
		return this.state === AutosaveState.SAVING;
	}

	isSaved() {
		return this.state === AutosaveState.SAVED;
	}

	isError() {
		return this.state === AutosaveState.ERROR;
	}

	setSaving() {
		this.abschnSaveSvc.emitSavingEvent(true);
		this.state = AutosaveState.SAVING;
		this.clearTimeout();
	}

	setSaved() {
		this.abschnSaveSvc.emitSavingEvent(false);
		this.state = AutosaveState.SAVED;
		this.startTimeout();
	}

	private startTimeout() {
		this.timeout = window.setTimeout(() => {
			this.state = AutosaveState.HIDDEN;
		}, ViewerFrageAutosaveIndicatorComponent.HIDE_MARKER_TIMEOUT_MS);
	}

	private clearTimeout() {
		clearTimeout(this.timeout);
	}

	setError() {
		this.abschnSaveSvc.emitSavingEvent(false);
		this.state = AutosaveState.ERROR;
		this.startTimeout();
	}
}
