import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FragebogenMetadataFormComponent } from '@form-editor/fragebogen-metadata-form/FragebogenMetadataFormComponent';
import { FragebogenDuplizierenService } from '@form-editor/service/FragebogenDuplizierenService';
import { AlertService } from '@shared/alert/service/AlertService';
import { Fragebogen } from '@shared/model/Fragebogen';
import { Recherchekomplex } from '@shared/model/Recherchekomplex';
import { RecherchekomplexService } from '@shared/service/RecherchekomplexService';
import { KopieSubstringBuilder } from '@shared/util/KopieSubstringBuilder';
import { PreventDefaultDirective } from '@util/PreventDefaultDirective';
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { LastIdPipe } from "../../../util/LastIdPipe";
import { NewIdPipe } from "../../../util/NewIdPipe";

@Component({
	selector: "app-editor-fragebogen-list-duplicate-modal",
	templateUrl: "./EditorFragebogenListeDuplicateModalComponent.html",
	styleUrls: ["./EditorFragebogenListeDuplicateModalComponent.less"],
	providers: [],
	standalone: true,
	imports: [
    PreventDefaultDirective,
    NgIf,
    NgFor,
    FormsModule,
    FragebogenMetadataFormComponent,
    NewIdPipe,
    LastIdPipe,
	ModalModule
],
})
export class EditorFragebogenListeDuplicateModalComponent implements OnInit {
	@Input()
	fragebogen: Fragebogen;

	@Output()
	duplicateCreated = new EventEmitter<Fragebogen>();

	editFragebogen: Fragebogen = null;

	@ViewChild("duplicateDialogModal", { static: true })
	duplicateDialogModal: ModalDirective;

	modalDisabled = false;

	recherchekomplexe: Recherchekomplex[] = [];

	constructor(
		private duplizierenService: FragebogenDuplizierenService,
		private recherchekomplexService: RecherchekomplexService,
		private alertService: AlertService
	) {}

	ngOnInit(): void {
		this.duplicateDialogModal.onShow.subscribe(() =>
			this.initialisiereDialog()
		);
	}

	isOpen(): boolean {
		return this.editFragebogen !== null;
	}

	initialisiereDialog(): void {
		this.editFragebogen = new Fragebogen(this.fragebogen);
		this.editFragebogen.name = this.erzeugeKopieName(
			this.editFragebogen.name
		);
		this.ladeRecherchekomplexe();
	}

	erzeugeKopieName(name: string) {
		return (
			name +
			" " +
			KopieSubstringBuilder.buildKopieSubstringFor(new Date())
		);
	}

	dupliziereFragebogen(number: number): void {
		this.setModalReadonly();
		this.duplizierenService
			.dupliziereFragebogen(this.editFragebogen, !!number)
			.then((response) => new Fragebogen(response))
			.then((fragebogen) => {
				this.zeigeErfolgsmeldung();
				this.duplicateCreated.emit(fragebogen);
				this.duplicateDialogModal.hide();
			})
			.finally(() => this.setModalEditable());
	}

	openDuplicateModalClicked(event: any) {
		this.duplicateDialogModal.show();
		event.stopPropagation();
	}

	byIdComparator(a: Recherchekomplex, b: Recherchekomplex) {
		return (a == null && b == null) || (a && b && a.id === b.id);
	}

	private ladeRecherchekomplexe() {
		this.recherchekomplexService
			.getRecherchekomplexe()
			.then(
				(recherchekomplexe) =>
					(this.recherchekomplexe = recherchekomplexe)
			);
	}

	private setModalEditable() {
		return (this.modalDisabled = false);
	}

	private setModalReadonly() {
		this.modalDisabled = true;
	}

	private zeigeErfolgsmeldung() {
		this.alertService.showSuccess(
			"Fragebogen " +
				this.editFragebogen.name +
				" erfolgreich angelegt. Lade Seite neu."
		);
	}
}
