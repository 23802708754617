import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { Observable } from 'rxjs/internal/Observable';
import { UserService } from '../form-viewer/service/UserService';
import { Institution } from "../shared/model/Institution";
import { Recherchekomplex } from "../shared/model/Recherchekomplex";
import { selectInstitution } from "../store/selector/institution.selectors";
import { selectRecherchekomplex } from "../store/selector/recherchekomplex.selectors";

@Injectable()
export class AuthGuardViewerRK {

	constructor(private userService: UserService, private router: Router, private store: Store) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (route.url.toString().includes('/ansprechpartner-login')){
			console.log('AuthGuardViewerRK returning for url ansprechpartner-login')
			this.router.navigate(['/ansprechpartner-login']);
		}
		const ausfueller = this.userService.getAusfueller();
		if (ausfueller) {
			if (ausfueller.authorityStrings.includes('ROLE_AUSFUELLER')) {
				let institution: Institution;
				let recherchekomplex: Recherchekomplex;
				this.store.pipe(select(selectInstitution)).subscribe(value => institution = value);
				this.store.pipe(select(selectRecherchekomplex)).subscribe(value => recherchekomplex = value);

				if (institution && recherchekomplex) {
					return true;
				}
				return this.router.navigate(['/portal-allgemein']);
			} else {
				return this.router.navigate(['/403']);
			}
		}
		return false;
		//else {
		//	this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
		//}
	}

}
