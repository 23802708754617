import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IInputTypeEditComponent } from '@shared/components/input-type/IInputTypeEditComponent';
import { InputTypeGanzzahl } from "@shared/model/frage/input/InputTypeGanzzahl";
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
	selector: "app-input-type-ganzzahl-edit",
	templateUrl: "./InputTypeGanzzahlEditComponent.html",
	styleUrls: ["./InputTypeGanzzahlEditComponent.less"],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule, InputNumberModule],
})
export class InputTypeGanzzahlEditComponent
	implements OnInit, IInputTypeEditComponent<InputTypeGanzzahl>
{
	@Input()
	inputType: InputTypeGanzzahl;

	platzhalterText = "";
	vorkommastellen = 0;

	getEditorTitel(): string {
		return "Optionen";
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
		this.vorkommastellen = this.inputType.vorkommastellen;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
		this.inputType.vorkommastellen = this.vorkommastellen;
	}
}
