import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { MessageComponent } from '@shared/components/message/MessageComponent';

@Component({
	templateUrl: "./UnbekannterCodeComponent.html",
	standalone: true,
	imports: [MessageComponent]
})
export class UnbekannterCodeComponent {
	constructor(private title: Title) {
		title.setTitle("Registrieren - Unbekannter Code");
	}
}
