import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PreviewService {
	inPreview: boolean;

	constructor() {
		this.inPreview = !!document.getElementById('isInPreviewMarker');
	}

	isInPreview() {
		return this.inPreview;
	}

	isNotInPreview() {
		return !this.inPreview;
	}
}
