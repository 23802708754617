import { DatePipe, Location, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FrageGeprueftMarker } from '@form-viewer/model/FrageGeprueftMarker';
import { AbschnittSaveEventServiceService } from '@form-viewer/service/AbschnittSaveEventServiceService';
import { AlertService } from '@shared/alert/service/AlertService';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs/internal/Subscription';
import { FrageGeprueftMarkerService } from '@form-viewer/service/FrageGeprueftMarkerService';
import { AusfuellerBerechtigungsService } from '@form-viewer/service/AusfuellerBerechtigungsService';
import { FrageFertigMarkerService } from '@form-viewer/service/FrageFertigMarkerService';
import { AbschnittAusfuellerService } from '@form-viewer/service/AbschnittAusfuellerService';
import { ViewerAbschnittComponent } from '@form-viewer/abschnitt/ViewerAbschnittComponent';

@Component({
			   selector: 'app-viewer-antwort-als-geprueft-markieren',
			   templateUrl: './ViewerAntwortAlsGeprueftMarkierenComponent.html',
			   styleUrls: ['./ViewerAntwortAlsGeprueftMarkierenComponent.less'],
			   standalone: true,
			   imports: [NgIf, DatePipe]
		   })
export class ViewerAntwortAlsGeprueftMarkierenComponent implements OnInit, OnDestroy {
	@BlockUI() blockUI: NgBlockUI;
	@Input() frageGeprueftMarker: FrageGeprueftMarker;
	@Input() abschnitt: any;
	@Output() alsGeprueftMarkiert = new EventEmitter<void>();
	@Output() alsUngeprueftMarkiert = new EventEmitter<void>();
	@Input() readonly = false;

	private saving: boolean;
	private subscription: Subscription;
	frageFertigMarker: any[] = [];
	ausfueller: any[] = [];
	meineFragenFertigMarker: any[] = [];

	constructor(
		private alertService: AlertService,
		private abschnSaveSvc: AbschnittSaveEventServiceService,
		private location: Location,
		private frageGeprueftMarkerService: FrageGeprueftMarkerService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
		private frageFertigMarkerService: FrageFertigMarkerService,
		private abschnittAusfuellerService: AbschnittAusfuellerService,
		private viewerAbschnittComponent: ViewerAbschnittComponent
	) {}

	ngOnInit() {
		this.subscription = this.abschnSaveSvc.savingEventListner().subscribe((info) => {
			this.saving = JSON.parse(String(info));
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	isSaving() {
		console.log('isSaving ... ' + this.saving);
		return this.saving;
	}

	istAlsGeprueftMarkiert() {
		return this.frageGeprueftMarker != null;
	}

	alsGeprueftMarkieren() {
		this.alsGeprueftMarkiert.emit();
		this.reloadAbschnitt();
	}

	alsUngeprueftMarkieren() {
		this.alsUngeprueftMarkiert.emit();
		this.reloadAbschnitt();
	}

	reloadAbschnitt() {
		this.viewerAbschnittComponent.resetAntwortState();
	}

	toggleGeprueft() {
		this.blockUI.start('Daten werden verarbeitet...');
		if (this.isSaving()) {
			this.blockUI.stop();
			this.alertService.clearAlerts();
			this.alertService.showDanger(
				'Fehler beim Ausführen der Aktion! \nWährend dem Speichern kann der Status nicht verändert werden. \nBitte versuchen Sie es erneut!');
		} else {
			try {
				if (this.istAlsGeprueftMarkiert()) {
					this.alsUngeprueftMarkieren();
				} else {
					this.alsGeprueftMarkieren();
				}
			}
			finally {
				this.blockUI.stop();
			}
		}
		setTimeout(() => {
			this.blockUI.stop();
		}, 1500);
	}

	istVersteckt() {
		return this.readonly && !this.istAlsGeprueftMarkiert();
	}

	reloadPage() {
		window.location.href = this.location.path(true);
	}
}
