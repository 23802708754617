import { SafeHtml } from "@angular/platform-browser";
import { InputTypeAntwort } from "@shared/model/antwort/input/InputTypeAntwort";
import { InputTypeGanzzahl } from "@shared/model/frage/input/InputTypeGanzzahl";

export class InputTypeGanzzahlAntwort extends InputTypeAntwort<InputTypeGanzzahl> {
	wert = null;

	constructor(antwort?: InputTypeGanzzahlAntwort) {
		super(antwort);

		if (antwort) {
			this.wert = antwort.wert;
		}
	}

	getType() {
		return InputTypeGanzzahl.ID;
	}

	isEmpty(): boolean {
		return !this.wert || this.wert.length === 0;
	}

    getHtml(): SafeHtml {
		return this.wert?.trim();
	}

	istInhaltlichGleich(o: InputTypeAntwort<any>): boolean {
		return this.wert?.trim() === (o as InputTypeGanzzahlAntwort).wert?.trim();
	}
}
