import {NgIf} from "@angular/common";
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FragebogenMetadataFormComponent} from '@form-editor/fragebogen-metadata-form/FragebogenMetadataFormComponent';
import {Fragebogen} from '@shared/model/Fragebogen';
import {FragebogenService} from '@shared/service/FragebogenService';
import {PreventDefaultDirective} from '@util/PreventDefaultDirective';
import {ModalDirective, ModalModule} from 'ngx-bootstrap/modal';

@Component({
	selector: "app-editor-fragebogen-metadata-dialog",
	templateUrl: "./FragebogenMetadataDialogComponent.html",
	styleUrls: ["./FragebogenMetadataDialogComponent.less"],
	standalone: true,
	imports: [PreventDefaultDirective, FragebogenMetadataFormComponent, ModalModule, NgIf],
})
export class FragebogenMetadataDialogComponent {
	@Input()
	fragebogen: Fragebogen;

	editFragebogen: Fragebogen = null;

	@Output()
	fragebogenChange = new EventEmitter<Fragebogen>();

	@ViewChild("editDialogModal", { static: true })
	editDialogModal: ModalDirective;

	disabled = false;

	constructor(private fragebogenService: FragebogenService) {}

	show() {
		this.editFragebogen = new Fragebogen(this.fragebogen);
		this.editDialogModal.show();
	}

	hide() {
		this.editDialogModal.hide();
	}

	commitSave() {
		this.disabled = true;
		this.fragebogenService
			.saveFragebogen(this.editFragebogen)
			.then((fragebogen) => {
				this.fragebogen = fragebogen;
				this.fragebogenChange.emit(fragebogen);

				this.disabled = false;
				this.hide();
			});
	}
}
