import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IInputTypeEditComponent } from '@shared/components/input-type/IInputTypeEditComponent';
import { InputTypeTelefon } from "@shared/model/frage/input/InputTypeTelefon";
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: "app-input-type-telefon-edit",
	templateUrl: "./InputTypeTelefonEditComponent.html",
	styleUrls: ["./InputTypeTelefonEditComponent.less"],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule],
})
export class InputTypeTelefonEditComponent
	implements OnInit, IInputTypeEditComponent<InputTypeTelefon>
{
	@Input()
	inputType: InputTypeTelefon;

	platzhalterText = "";

	getEditorTitel(): string {
		return "Optionen";
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
	}
}
