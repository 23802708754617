import { Antwort } from '../Antwort';

export class EntscheidungsfragenAntwort extends Antwort {
	static override readonly FRAGEN_TYPE_ID = 'EntscheidungsFrage';

	auswahl: string = null;

	begruendung = '';

	constructor(antwort?: EntscheidungsfragenAntwort) {
		super(antwort);
		if (antwort) {
			this.auswahl = antwort.auswahl || null;
			this.begruendung = antwort.begruendung || '';
		}
	}

	public isEntscheidungGetroffen(): boolean {
		return !!this.auswahl;
	}

	isEmpty() {
		return this.auswahl === null && this.begruendung.length === 0;
	}

	istInhaltlichGleich(o: Antwort): boolean {
		if (!o || !(o instanceof EntscheidungsfragenAntwort)) {
			return false;
		}

		return this.gleicheAuswahl(this.auswahl, o.auswahl) && this.gleicheBegruendung(this.begruendung, o.begruendung);
	}

	gleicheBegruendung(value1: string, value2: string): boolean {
		return this.trimmedEqual(value1, value2);
	}

	gleicheAuswahl(auswahl1: string, auswahl2: string): boolean {
		return auswahl1 != null && auswahl2 != null && this.trimmedEqual(auswahl1, auswahl2);
	}

	trimmedEqual(value1: string, value2: string): boolean {
		return value1.trim() === value2.trim();
	}
}
