import { SafeHtml } from "@angular/platform-browser";
import { InputTypeEmail } from "@shared/model/frage/input/InputTypeEmail";
import { InputTypeAntwort } from './InputTypeAntwort';

export class InputTypeEmailAntwort extends InputTypeAntwort<InputTypeEmail> {
	wert = null;

	constructor(antwort?: InputTypeEmailAntwort) {
		super(antwort);

		if (antwort) {
			this.wert = antwort.wert;
		}
	}

	getType() {
		return InputTypeEmail.ID;
	}

	isEmpty(): boolean {
		return !this.wert || this.wert.trim().length === 0;
	}

    getHtml(): SafeHtml {
		return this.wert?.trim();
	}

	istInhaltlichGleich(o: InputTypeAntwort<any>): boolean {
		return this.wert?.trim() === (o as InputTypeEmailAntwort).wert?.trim();
	}

	override isValid(): boolean {
		var g = '[\\w\\+\\-_~]'
		return this.isEmpty() || new RegExp(`^${g}+@${g}+\\.${g}+$`).test(this.wert.trim())
	}
}
