import {NgClass, NgIf} from '@angular/common';
import {Component, Input, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IInputTypeViewComponent} from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {InputTypeGanzzahlAntwort} from "@shared/model/antwort/input/InputTypeGanzzahlAntwort";
import {InputTypeGanzzahl} from "@shared/model/frage/input/InputTypeGanzzahl";
import {InputNumberModule} from 'primeng/inputnumber';

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Vollflächiges Eingabefeld ohne Rand zum Einbinden in Tabellenzellen
 */
@Component({
	selector: "app-input-type-ganzzahl-view",
	templateUrl: "./InputTypeGanzzahlViewComponent.html",
	styleUrls: ["./InputTypeGanzzahlViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgClass, FormsModule, InputNumberModule, ScrollToFocusedElementDirective],
})
export class InputTypeGanzzahlViewComponent
	implements IInputTypeViewComponent<InputTypeGanzzahl>
{
	context = {};

	variant: string;

	@ViewChild("tooltip") tooltip;

	@Input()
	antwort: InputTypeGanzzahlAntwort;

	@Input()
	type: InputTypeGanzzahl;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	getPlatzhalterText(): string {
		return this.type.platzhalterText;
	}

	getMaxLength(): number {
		return this.type.vorkommastellen ? this.type.vorkommastellen : null;
	}

	showTooltipIfMaxlength(value) {
		if (
			this.getMaxLength() &&
			Math.floor(Math.log10(Number(value))) + 1 > this.getMaxLength()
		) {
			this.tooltip.show();
		} else {
			this.tooltip.hide();
		}
	}

	variantClass(): string {
		return this.variant;
	}
}
