import { AbschnittAntwortChange } from '@form-viewer/abschnitt/abschnitt-antwort-change-detector/AbschnittAntwortChange';
import { AbschnittAntwortDto } from '@shared/model/AbschnittAntwortDto';

const debug = require('debug')('AbschnittAntwortChangeDetector');

export class AbschnittAntwortChangeDetector {
	private known: { [key: string]: string } = {};

	compareToAndInitialize(antworten: AbschnittAntwortDto): AbschnittAntwortChange[] {
		const changes = this.compareTo(antworten);
		this.initialize(antworten);
		return changes;
	}

	initialize(antworten: AbschnittAntwortDto): void {
		this.known = {};

		if (!antworten) {
			debug('Keine Antworten vorhanden, lasse Initialisierungszustand leer');
			return;
		}

		antworten.antworten.forEach(antwort => {
			this.known[antwort.frage_id] = JSON.stringify(antwort);
		});
	}

	compareTo(antworten: AbschnittAntwortDto): AbschnittAntwortChange[] {
		const changes = [];

		if (!antworten) {
			debug('Keine Antworten vorhanden, lasse Änderungsprüfung aus');
			return [];
		}

		antworten.antworten.forEach(antwort => {
			const id = antwort.frage_id;

			const serialized = JSON.stringify(antwort);
			const knownSerialized = this.known[id];

			if (serialized !== knownSerialized) {
				changes.push(AbschnittAntwortChange.forAntwort(antwort));
			}
		});

		return changes;
	}

	countChanges(antwort: AbschnittAntwortDto) {
		return this.compareTo(antwort).length;
	}
}
