import { NgIf } from '@angular/common';
import { Component, ComponentFactoryResolver, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { AusfuellerAnlegenService } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerAnlegenService';
import { AusfuellerBenutzerdatenService } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerBenutzerdatenService';
import { AusfuellerVerwaltungFormConfiguration } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration';
import { AusfuellerVerwaltungInnerFormComponent } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungInnerFormComponent';
import { AusfuellerAnlegenDto } from '@form-viewer/ausfueller-verwaltung/model/AusfuellerAnlegenDto';
import { AlertService } from '@shared/alert/service/AlertService';
import { StyledSelectComponent } from '@shared/components/select/styled/StyledSelectComponent';
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

const debug = require('debug')('AusfuellerVerwaltungFormComponent');

@Component({
	selector: "app-ausfueller-verwaltung-form",
	templateUrl: "./AusfuellerVerwaltungFormComponent.html",
	styleUrls: ["./AusfuellerVerwaltungFormComponent.less"],
	standalone: true,
	imports: [FormsModule, NgIf, NewIdPipe, LastIdPipe, StyledSelectComponent]
})
export class AusfuellerVerwaltungFormComponent {
	@Output()
	close = new EventEmitter<void>();

	dto: AusfuellerAnlegenDto;

	_disabled: boolean;

	@ViewChild("verwaltungsForm", { static: true })
	verwaltungsForm: NgForm;

	@ViewChild("optionForm", { read: ViewContainerRef, static: true })
	optionFormContainer: ViewContainerRef;

	protected optionForm: AusfuellerVerwaltungInnerFormComponent;

	protected _configuration: AusfuellerVerwaltungFormConfiguration;

	constructor(
		protected alertService: AlertService,
		protected componentFactoryResolver: ComponentFactoryResolver,
		public benutzerdatenService: AusfuellerBenutzerdatenService,
		protected ausfuellerAnlegenService: AusfuellerAnlegenService
	) {
		benutzerdatenService.benutzerdatenErkannt.subscribe((benutzerdaten) => {
			if (this.dto) {
				this.dto.anrede = benutzerdaten.anrede;
				this.dto.anzeigename = benutzerdaten.anzeigename;
			}
		});

		benutzerdatenService.benutzerdatenUngueltig.subscribe(() => {
			if (this.dto) {
				this.dto.anrede = "";
				this.dto.anzeigename = "";
			}
		});
	}

	@Input()
	set configuration(configuration: AusfuellerVerwaltungFormConfiguration) {
		if (configuration) {
			debug(
				"set configuration called, resetting innerForm",
				configuration
			);
			this.optionFormContainer.clear();

			const factory =
				this.componentFactoryResolver.resolveComponentFactory(
					configuration.FORM_COMPONENT
				);
			const component = this.optionFormContainer.createComponent(factory);

			this.optionForm = component.instance;
			this.optionForm.configuration = configuration;
			this.optionForm.dto = this.dto = new configuration.DTO_TYPE();
		} else {
			this.optionForm = undefined;
			this.dto = undefined;
		}

		this._configuration = configuration;
	}

	get configuration(): AusfuellerVerwaltungFormConfiguration {
		return this._configuration;
	}

	set disabled(value: boolean) {
		this._disabled = this.optionForm.disabled = value;
	}

	get disabled(): boolean {
		return this._disabled;
	}

	reset() {
		debug("reset, resetting state");
		this.optionForm.dto = this.dto = new this._configuration.DTO_TYPE();

		this.disabled = false;

		this.benutzerdatenService.reset();
	}

	onInvite() {
		this.dto = this._configuration.applyToAnlegenDto(this.dto);

		debug("onInvite", this._configuration, this.dto);

		this.disabled = true;
		this.ausfuellerAnlegenService
			.anlegen(this._configuration, this.dto)
			.then(() => {
				this.alertService.showSuccess(
					`Der ${this.dto.getType()} <i>${this.dto.getVollenNamen()}</i> wurde erfolgreich eingeladen`
				);
				this.disabled = false;
				this.close.emit();
			})
			.catch(() => {
				this.disabled = false;
			});
	}

	isValid() {
		// Anrede ist ein StyledSelect und ich hab's nicht hinbekommen, dies in die Angular-Form-Validierung einzubinden. Daher dieser
		// Work-Around.
		return this.verwaltungsForm.form.valid && this.dto && this.dto.anrede;
	}
}
