import {NgIf} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AlertComponent} from '@shared/alert/component/AlertComponent';
import {SpinnerComponent} from '@shared/components/spinner/SpinnerComponent';
import {Institution} from '@shared/model/Institution';
import {InstitutionFragebogenStatus} from '@shared/model/InstitutionFragebogenStatus';
import {InstitutionAntwortService} from '@shared/service/InstitutionAntwortService';
import {UUID} from '@util/export-types';
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";
import {ModalDirective, ModalModule} from 'ngx-bootstrap/modal';
import {InstitutionsUebersichtComponent} from './institution-uebersicht/InstitutionsUebersichtComponent';

@Component({
	selector: "app-institutions-auswahl",
	templateUrl: "./InstitutionsAuswahlComponent.html",
	styleUrls: ["./InstitutionsAuswahlComponent.less"],
	standalone: true,
	imports: [
		AlertComponent,
		InstitutionsUebersichtComponent,
		SpinnerComponent,
		ModalModule,
		NgIf,
		PreventDefaultDirective,
	],
})
export class InstitutionsAuswahlComponent implements OnInit {
	@Input() institutionId: UUID;
	@Input() fragebogenId: UUID;
	@Input() fragebogenName: string;
	institutionenUndStatus: InstitutionFragebogenStatus[];

	@Output() institutionSelected = new EventEmitter<Institution>();

	canClose: boolean;

	@ViewChild("institutionModal", { static: true })
	institutionModal: ModalDirective;

	constructor(private institutionAntwortService: InstitutionAntwortService) {}

	ngOnInit(): void {
		this.canClose = this.hasInstitution();

		this.institutionModal.config = {
			backdrop: this.canClose ? true : "static",
		};

		if (!this.hasInstitution()) {
			this.institutionModal.show();
		}

		this.fetchInstitutionen();
	}

	hasInstitution() {
		return !!this.institutionId;
	}

	isLoaded() {
		return !!this.institutionenUndStatus;
	}

	private fetchInstitutionen() {
		this.institutionAntwortService
			.getInstitutionenUndStatusFuerFragebogen(this.fragebogenId)
			.then(
				(institutionenUndStatus) =>
					(this.institutionenUndStatus = institutionenUndStatus)
			);
	}

	onSelect(institution: Institution) {
		this.institutionSelected.emit(institution);
		this.institutionModal.hide();
		// window.location.href = '/editor/fragebogen/' + this.fragebogenId + '/antworten/' + institution.id;
	}
}
