import { InputTypeTelefonViewComponent } from "@form-viewer/abschnitt/frage-liste/frage/view/input/input-type-telefon-view/InputTypeTelefonViewComponent";
import { InputTypeTelefonEditComponent } from "@shared/components/input-type/telefon/InputTypeTelefonEditComponent";
import { InputTypeTelefonAntwort } from "@shared/model/antwort/input/InputTypeTelefonAntwort";
import { InputType } from './InputType';

export class InputTypeTelefon extends InputType {
	static override readonly ID = 'InputTypeTelefon';
	static override readonly TYPE_DISPLAY_NAME = 'Telefon';
	static override readonly ANTWORT = InputTypeTelefonAntwort;

	// add to @NgModule.entryComponents in app.module.ts
	static override readonly VIEWER = InputTypeTelefonViewComponent;
	static override readonly EDITOR = InputTypeTelefonEditComponent;

	platzhalterText = '';

	constructor(type?: InputTypeTelefon) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
		}
	}

}
