import { EntscheidungsFrageEditComponent } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/entscheidung/EntscheidungsFrageEditComponent';
import { EntscheidungsFrageViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/entscheidung/EntscheidungsFrageViewComponent';
import { Frage } from "@shared/model/Frage";
import { EntscheidungsfragenAntwort } from "@shared/model/antwort/EntscheidungsfragenAntwort";

export class EntscheidungsFrage extends Frage {

	static readonly TYPE_DISPLAY_NAME = 'Entscheidung';
	static readonly TYPE_ICON_NAME = 'yn';
	static override readonly ID = 'EntscheidungsFrage';

	static override readonly EDITOR = EntscheidungsFrageEditComponent;

	static override readonly VIEWER = EntscheidungsFrageViewComponent;

	beschriftungPositiv = '';
	beschriftungNegativ = '';
	begruendungsTextfeldAktiviert = false;
	beschriftungBegruendung = '';
	begruendungBei = 'BEIDES';

	constructor(frage?: EntscheidungsFrage) {
		super(frage);

		if (frage) {
			this.beschriftungPositiv = frage.beschriftungPositiv;
			this.beschriftungNegativ = frage.beschriftungNegativ;
			this.begruendungsTextfeldAktiviert = frage.begruendungsTextfeldAktiviert;
			this.beschriftungBegruendung = frage.beschriftungBegruendung;
			this.begruendungBei = frage.begruendungBei;

			this.aeltesteAntwort = frage.aeltesteAntwort ? new EntscheidungsfragenAntwort(frage.aeltesteAntwort as EntscheidungsfragenAntwort) : null;
			this.neuesteAntwort = frage.neuesteAntwort ? new EntscheidungsfragenAntwort(frage.neuesteAntwort as EntscheidungsfragenAntwort) : null;
		}
	}

	override typeIconName() {
		return EntscheidungsFrage.TYPE_ICON_NAME;
	}

	override typeDisplayName() {
		return EntscheidungsFrage.TYPE_DISPLAY_NAME;
	}

	istBegruendungAktivBei(auswahl: string): boolean {
		if (!auswahl) {
			return false;
		} else if (this.begruendungsTextfeldAktiviert === false) {
			return false;
		} else if (this.begruendungBei === 'BEIDES') {
			return true;
		} else {
			return (this.begruendungBei === 'POSITIV' && auswahl === 'POSITIV') ||
				(this.begruendungBei === 'NEGATIV' && auswahl === 'NEGATIV');
		}
	}

	override isExportableAsCsv() {
		return false;
	}
}
