import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormViewerComponent } from "@form-viewer/FormViewerComponent";
import { SpinnerComponent } from "@shared/components/spinner/SpinnerComponent";
import { Fragebogen } from '@shared/model/Fragebogen';
import { FragebogenService } from "@shared/service/FragebogenService";

@Component({
	selector: "app-form-editor-vorschau",
	templateUrl: "./FormEditorVorschauComponent.html",
	standalone: true,
 imports: [FormViewerComponent, SpinnerComponent, NgIf],
})
export class FormEditorVorschauComponent implements OnInit {
	fragebogen: Fragebogen;

	constructor(
		private route: ActivatedRoute,
		private fragebogenService: FragebogenService
	) {}

	ngOnInit(): void {
		const fragebogenId = this.route.snapshot.paramMap.get("fragebogenId");
		this.fragebogenService
			.getFragebogenVorschau(fragebogenId)
			.then((response) => (this.fragebogen = response));
	}
}
