import { UUID } from "@util/export-types";
import { AbschnittAntwortDto } from "./AbschnittAntwortDto";
import { KartenAntwortDiffDto } from "./KartenAntwortDiffDto";

export class KartenAntwortDiffWrapperDto {
	abschnitt_id: UUID;
	institution_id: UUID;
	antworten: KartenAntwortDiffDto[] = []

	constructor(abschnittAntwortDto: AbschnittAntwortDto) {
		this.abschnitt_id = abschnittAntwortDto.abschnitt_id
		this.institution_id = abschnittAntwortDto.institution_id
	}
}
