import { SafeHtml } from "@angular/platform-browser";
import { InputTypeAntwort } from "@shared/model/antwort/input/InputTypeAntwort";
import { InputTypeCheckbox } from "@shared/model/frage/input/InputTypeCheckbox";

export class InputTypeCheckboxAntwort extends InputTypeAntwort<InputTypeCheckbox> {
	wert = false;

	constructor(antwort?: InputTypeCheckboxAntwort) {
		super(antwort);

		if (antwort) {
			this.wert = antwort.wert;
		}
	}

	getType() {
		return InputTypeCheckbox.ID;
	}

	isEmpty(): boolean {
		/*
		 Technisch betrachtet enthalten Checkboxen immer einen logischen Wert - Ja oder Nein - beides drückt eine Aussage aus.
		 Eine Checkbox als immer-gefüllt zu definieren ist jedoch nicht nützlich und führt zu nicht erwartetem Verhalten für den Nutzer,
		 da unsere Checkboxen üblicherweise initial leer sind.

		 Daher wird im Folgenden "true" als gefüllt interpretiert und "false" als leer.
		*/

		return !this.wert;
	}

    getHtml(): SafeHtml {
		return `<span class="checkbox-sign ${ this.wert ? 'yes' : 'no' }">${ this.wert ? '✓' : '✗' }</span>`;
	}

	istInhaltlichGleich(o: InputTypeAntwort<any>): boolean {
		return this.wert === (o as InputTypeCheckboxAntwort).wert;
	}

}
