import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@shared/alert/service/AlertService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { AnzahlGepruefterFragenProAbschnittDto } from '@shared/model/AnzahlGepruefterFragenProAbschnittDto';
import { Fragebogen } from '@shared/model/Fragebogen';
import { AktuelleBerechtigungService } from '@shared/service/AktuelleBerechtigungService';
import { AbschnittBuilderUtil } from '@shared/util/AbschnittBuilderUtil';
import { AnzahlGepruefterFragenProAbschnittDtoBuilderUtil } from '@shared/util/AnzahlGepruefterFragenProAbschnittDtoBuilderUtil';

const debug = require('debug')('service:ajax:ViewerAbschnittService');

/*
 * Zugriff auf die Daten werden ueber AbschnittStorageService gewaehrt
 */
@Injectable({providedIn: 'root'})
export class ViewerAbschnittService {

	constructor(private http: HttpClient, private alerts: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	// Shared, von Ausfüller und Editor Preview verwendet
	getAbschnitte(fragebogen: Fragebogen): Promise<Abschnitt[]> {
		return this.http.get<Abschnitt[]>('/api/shared/fragebogen/abschnitte', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				fragebogen: fragebogen.id,
			})
		})
			.toPromise()
			.then(response => {
				return AbschnittBuilderUtil.buildList(response);
			})
			.catch(this.alerts.handleHttpError);
	}

	getAnzahlGepruefterFragenProAbschnitt(fragebogen: Fragebogen): Promise<AnzahlGepruefterFragenProAbschnittDto[]> {
		debug('Lade Anzahl der geprueften Fragen pro Abschnitt fuer den Fragebogen mit der ID ', fragebogen.id);

		return this.http.get<AnzahlGepruefterFragenProAbschnittDto[]>('/viewer/fragebogen/abschnitte/anzahl-gepruefter-fragen', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				fragebogen: fragebogen.id,
			})
		})
			.toPromise()
			.then(response => AnzahlGepruefterFragenProAbschnittDtoBuilderUtil.buildList(response))
			.catch(this.alerts.handleHttpError);
	}
}
