import { Institution } from '@shared/model/Institution';
import { UUID } from '@util/export-types';

export class FrageGeprueftMarker {
	id: UUID;
	frage_id: UUID;
	institution: Institution;
	created: Date;
	created_by: string;

	static build(marker: FrageGeprueftMarker): FrageGeprueftMarker {
		return new FrageGeprueftMarker(marker);
	}

	static buildList(list: FrageGeprueftMarker[]): FrageGeprueftMarker[] {
		return list.map(marker => FrageGeprueftMarker.build(marker));
	}

	constructor(frageGeprueftMarker?: FrageGeprueftMarker) {
		if (frageGeprueftMarker) {
			this.id = frageGeprueftMarker.id;
			this.frage_id = frageGeprueftMarker.frage_id;
			this.institution = new Institution(frageGeprueftMarker.institution);
			this.created = new Date(frageGeprueftMarker.created);
			this.created_by = frageGeprueftMarker.created_by;
		}
	}
}
