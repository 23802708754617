import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AusfuellerBenutzerdatenDto } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerBenutzerdatenDto';
import { AlertService } from '@shared/alert/service/AlertService';

const debug = require('debug')('service:ajax:AusfuellerBenutzerdatenApiService');

@Injectable({providedIn: 'root'})
export class AusfuellerBenutzerdatenApiService {
	constructor(private http: HttpClient, private alertService: AlertService) {
	}

	ladeBenutzerdaten(email: string): Promise<AusfuellerBenutzerdatenDto> {
		debug('Lade Benutzerdaten für E-Mail', email);
		return this.http.get<AusfuellerBenutzerdatenDto>('/view/ausfueller/existiert-ausfueller-bereits', {
			params: {
				email: email
			}
		})
			.toPromise()
			.then(response => new AusfuellerBenutzerdatenDto(response))
			.catch(this.alertService.handleHttpError);
	}
}
