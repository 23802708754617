import { Ausfueller } from '@form-viewer/model/Ausfueller';
import { DateFormatterUtil } from '@shared/util/DateFormatterUtil';
import { UUID } from '@util/export-types';
import { Fragebogen } from './Fragebogen';

export class FragebogenStatus {

	id: UUID;
	fragebogen: Fragebogen;
	institutionId: string;
	abschlussdatum: string;
	abschlussGeaendertDurchUser: Ausfueller;

	constructor(fragebogenStatus?: FragebogenStatus) {
		if (fragebogenStatus) {
			this.id = fragebogenStatus.id;
			this.fragebogen = fragebogenStatus.fragebogen;
			this.institutionId = fragebogenStatus.institutionId;
			this.abschlussdatum = DateFormatterUtil.transformToDateOnly(new Date(fragebogenStatus.abschlussdatum));

			this.abschlussGeaendertDurchUser = new Ausfueller(fragebogenStatus.abschlussGeaendertDurchUser);
		}
	}
}
