import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from "@shared/alert/service/AlertService";
import {Recherchekomplex} from "@shared/model/Recherchekomplex";
import {firstValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class RecherchekomplexService {

	constructor(private http: HttpClient, private alerts: AlertService) {
	}

	getRecherchekomplexe(): Promise<Recherchekomplex[]> {
		return firstValueFrom(this.http.get<Recherchekomplex[]>('/api/editor/recherchekomplex'))
			.then(response => {
				return response.map(recherchekomplex => new Recherchekomplex(recherchekomplex));
			})
			.catch(this.alerts.handleHttpError);
	}
}
