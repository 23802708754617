import {NgFor} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Alert} from '@shared/alert/service/Alert';
import {AlertService} from '@shared/alert/service/AlertService';
import {AlertModule} from "ngx-bootstrap/alert";
import {Subscription} from "rxjs/internal/Subscription";

@Component({
	selector: "app-alert",
	templateUrl: "./AlertComponent.html",
	styleUrls: ["./AlertComponent.less"],
	standalone: true,
	imports: [NgFor, AlertModule],
})
export class AlertComponent implements OnInit, OnDestroy {
	alerts: Alert[];
	private sub1: Subscription;
	private sub2: Subscription;

	constructor(private alertService: AlertService) {}

	closeAlert(alert: Alert) {
		this.alertService.dismissAlert(alert);
	}

	ngOnInit() {
		this.sub1 = this.alertService.newAlert.subscribe(() => this.updateAlertList());
		this.sub2 = this.alertService.removeAlert.subscribe(() => this.updateAlertList());
	}

	ngOnDestroy(): void {
		this.sub1.unsubscribe()
		this.sub2.unsubscribe()
	}

	private updateAlertList() {
		this.alerts = this.alertService.getAlerts();
	}
}
