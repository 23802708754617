import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IFrageEditComponent } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';
import { TextfeldFrage } from '@shared/model/frage/TextfeldFrage';
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: "app-editor-abschnitt-frage-edit-textfeld",
	templateUrl: "./TextfeldFrageEditComponent.html",
	styleUrls: ["./TextfeldFrageEditComponent.less"],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule],
})
export class TextfeldFrageEditComponent
	implements IFrageEditComponent<TextfeldFrage>
{
	@Input()
	frage: TextfeldFrage;
}
