import {NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {InfoTextViewComponent} from "@form-viewer/shared/info-text/InfoTextViewComponent";
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {KartenAntwort} from '@shared/model/antwort/KartenAntwort';
import {KartenFrage} from '@shared/model/frage/KartenFrage';
import {LastIdPipe} from "@util/LastIdPipe";
import {NewIdPipe} from "@util/NewIdPipe";

@Component({
	selector: 'app-karten-view-title-field',
	templateUrl: './KartenViewTitleFieldComponent.html',
	styleUrls: ['./KartenViewTitleFieldComponent.less'],
	standalone: true,
 	imports: [
		InfoTextViewComponent,
		NewIdPipe,
		LastIdPipe,
		NgIf,
		FormsModule,
		ScrollToFocusedElementDirective
	],
})
export class KartenViewTitleFieldComponent {
	@Input()
	frage: KartenFrage;

	@Input()
	antwort: KartenAntwort;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isValid() {
		return this.antwort.kartenTitel.trim().length > 0;
	}

	isReadonly() {
		return this.readonly;
	}
}
