import {Component, Input, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IInputTypeEditComponent} from '@shared/components/input-type/IInputTypeEditComponent';
import {InputTypeTextarea} from '@shared/model/frage/input/InputTypeTextarea';
import {LastIdPipe} from "@util/LastIdPipe";
import {NewIdPipe} from "@util/NewIdPipe";
import {PreventSubmitOnEnterDirective} from "@util/PreventSubmitOnEnterDirective";

@Component({
	selector: "app-input-type-textarea-edit",
	templateUrl: "./InputTypeTextareaEditComponent.html",
	styleUrls: ["./InputTypeTextareaEditComponent.less"],
	standalone: true,
	imports: [NewIdPipe, LastIdPipe, FormsModule, PreventSubmitOnEnterDirective],
})
export class InputTypeTextareaEditComponent
	implements OnInit, IInputTypeEditComponent<InputTypeTextarea>
{
	@Input()
	inputType: InputTypeTextarea;

	platzhalterText = "";

	getEditorTitel(): string {
		return "Optionen";
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
	}
}
