import { InputType } from './frage/input/InputType';
import { InputTypeAuswahl } from './frage/input/InputTypeAuswahl';
import { InputTypeCheckbox } from './frage/input/InputTypeCheckbox';
import { InputTypeEmail } from "./frage/input/InputTypeEmail";
import { InputTypeGanzzahl } from './frage/input/InputTypeGanzzahl';
import { InputTypeKommazahl } from "./frage/input/InputTypeKommazahl";
import { InputTypeMehrfachauswahl } from './frage/input/InputTypeMehrfachauswahl';
import { InputTypeTelefon } from "./frage/input/InputTypeTelefon";
import { InputTypeText } from './frage/input/InputTypeText';
import { InputTypeTextarea } from './frage/input/InputTypeTextarea';

const debug = require('debug')('InputTypes');

export class InputTypes {
	static readonly TYPES: typeof InputType[] = [
		InputTypeText,
		InputTypeTextarea,
		InputTypeCheckbox,
		InputTypeAuswahl,
		InputTypeMehrfachauswahl,
		InputTypeGanzzahl,
		InputTypeKommazahl,
		InputTypeEmail,
		InputTypeTelefon,
	];

	static typeForID(id: string): typeof InputType {
		const typeClass = InputTypes.TYPES.find(type => type.ID === id);
		if (!typeClass) {
			debug('InputType nicht definiert: ', id);
		}

		return typeClass;
	}
}
