import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { VerwaltbarerAusfuellerDto } from '@benutzerverwaltung/model/VerwaltbarerAusfuellerDto';
import { AusfuellerService } from '@benutzerverwaltung/services/AusfuellerService';
import { select, Store } from '@ngrx/store';
import { FooterComponent } from '@shared/components/footer/FooterComponent';
import { HeaderComponent } from '@shared/components/header/HeaderComponent';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { Subscription } from 'rxjs/internal/Subscription';
import { UserService } from '../../form-viewer/service/UserService';
import { SichtbareInstitutionDto } from '../../portal-selector/model/SichtbareInstitutionDto';
import { AlertService } from '../../shared/alert/service/AlertService';
import { Institution } from '../../shared/model/Institution';
import { selectInstitution } from '../../store/selector/institution.selectors';
import { RecherchekomplexMitSichtbarenAbschnittenDto } from '../model/RecherchekomplexMitSichtbarenAbschnittenDto';
import { OffeneEinladungenComponent } from './offene-einladungen/offene-einladungen.component';
import { RecherchekomplexeComponent } from './recherchekomplexe/recherchekomplexe.component';
import { RegistrierteBenutzerComponent } from './registrierte-benutzer/registrierte-benutzer.component';

@Component({
	selector: "app-benutzerverwaltung",
	templateUrl: "./BenutzerverwaltungComponent.html",
	styleUrls: [
		"./BenutzerverwaltungComponent.less",
		"../../pages/content-page.less",
	],
	standalone: true,
	imports: [
		HeaderComponent,
		FooterComponent,
		TabViewModule,
		RegistrierteBenutzerComponent,
		OffeneEinladungenComponent,
		RecherchekomplexeComponent,
		NgIf,
		DropdownModule,
		FormsModule
	],
})
export class BenutzerverwaltungComponent implements OnInit {
	public ausfuellerListe: VerwaltbarerAusfuellerDto[];
	public activeIndex = 0;
	public sichtbareInstitution: SichtbareInstitutionDto[] = [];
	public loaded: boolean;
	@BlockUI() blockUI: NgBlockUI;
	private ausfuellerListeMitAllemAnzeigen: RecherchekomplexMitSichtbarenAbschnittenDto[];
	private sub: Subscription;

	constructor(
		public ausfuellerService: AusfuellerService,
		private title: Title,
		private alertService: AlertService,
		private userService: UserService,
		private route: ActivatedRoute,
		private router: Router,
		private httpClient: HttpClient,
		private store: Store,
		private messageService: MessageService
	) {
		title.setTitle("Benutzerverwaltung - Juve Recherche");
		// this.userService.collectSichtbareInstitutionen()

		this.sub = this.route.queryParams.subscribe((params) => {
			// console.log(params);
			if (params.activeIndex) {
				this.activeIndex = params.activeIndex;
			} else {
				this.activeIndex = 0;
			}

			// console.log(this.activeIndex);
		});

		this.removeTabIndexParam();
	}

	private _aktuelleInstitution: SichtbareInstitutionDto;

	get aktuelleInstitution(): SichtbareInstitutionDto {
		return this._aktuelleInstitution;
	}

	set aktuelleInstitution(value: SichtbareInstitutionDto) {
		this._aktuelleInstitution = value;
		this.userService.setAktuelleInstitution(value.institution);
		location.reload();
	}

	rufeNeueAusfuellerlisteAb() {
		this.alertService.showInfo("Daten werden geladen...");
		this.ausfuellerService
			.holeAusfuellerForCurrentInst(true)
			.then((ausfueller) => this.setAusfuellerListeAndLog(ausfueller));
	}

	getAnzahlRegistrierteNutzer() {
		return (this.ausfuellerListe || []).filter((o) => o.enabled).length;
	}

	getAnzahlNichtRegistrierterNutzer() {
		return (this.ausfuellerListe || []).filter(
			(o) => !o.enabled && o.eingeladen
		).length;
	}

	ngOnInit() {
		this.blockUI.start("Daten werden verarbeitet...");
		try {
			try {
				this.messageService.clear();
			} finally {
			}

			this.isAlive();

			if (
				!this.userService.getSichtbareInstitutionen() ||
				this.userService.getSichtbareInstitutionen().length == 0
			) {
				// console.log("BenutzerverwaltungComponent- LADE sichtbareInstitution by Service")
				this.collectSichtbareInstitutionen();
			} else {
				// console.log("BenutzerverwaltungComponent - LADE sichtbareInstitution by LIST")
				this.sichtbareInstitution =
					this.userService.getSichtbareInstitutionen();
				this.extractCurrentInstitute(false);
				this.blockUI.stop();
			}
		} finally {
			this.blockUI.stop();
		}
	}

	baumveraenderungBehandeln() {
		this.rufeNeueAusfuellerlisteAb();
	}

	trackeVeraenderungenAmBaum(index, verwaltbarerAusfueller) {
		return verwaltbarerAusfueller.id;
	}

	hatRegistierteNutzer() {
		for (const verwaltbarerAusfueller of this.ausfuellerListe) {
			if (verwaltbarerAusfueller.enabled) {
				return true;
			}
		}

		return false;
	}

	hatNichtRegistierteNutzer() {
		for (const verwaltbarerAusfueller of this.ausfuellerListe) {
			if (!verwaltbarerAusfueller.enabled) {
				return true;
			}
		}

		return false;
	}

	private collectSichtbareInstitutionen() {
		this.blockUI.start("Daten werden verarbeitet...");
		console.log("collectSichtbareInstitutionen");
		this.httpClient
			.get<SichtbareInstitutionDto[]>(`/content/portal-allgemein`)
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.then((json) => SichtbareInstitutionDto.buildList(json))
			.then((dtos) => (this.sichtbareInstitution = dtos))
			.then(() => {
				this.extractCurrentInstitute(true);
			})
			.finally(this.blockUI.stop);
	}

	handleChange(e) {
		var index = e.index;
		this.messageService.clear();
		this.isAlive();
	}

	isAlive() {
		this.userService.isAlive();
	}

	private extractCurrentInstitute(setUserServiceInstituteList) {
		this.loaded = true;
		let tmpInst: Institution;
		if (this.sichtbareInstitution.length > 0) {
			this.sichtbareInstitution.sort((a, b) =>
				a.institution.anzeigename
					.toUpperCase()
					.localeCompare(b.institution.anzeigename.toUpperCase())
			);

			if (setUserServiceInstituteList) {
				this.userService.setSichtbareInstitutionen(
					this.sichtbareInstitution
				);
			}

			// this.messageService.clear();
			this.store
				.pipe(select(selectInstitution))
				.subscribe((value) => (tmpInst = new Institution(value)));

			this._aktuelleInstitution = this.sichtbareInstitution[0];

			if (this.userService.getAktuelleInstitution()) {
				for (const aktuelleInstitutionElement of this
					.sichtbareInstitution) {
					if (
						aktuelleInstitutionElement.institution.id ===
						this.userService.getAktuelleInstitution().id
					) {
						this._aktuelleInstitution = aktuelleInstitutionElement;
						break;
					}
				}
			} else {
				this._aktuelleInstitution = this.sichtbareInstitution[0];
			}

			this.userService.setAktuelleInstitution(
				this._aktuelleInstitution.institution
			);

			for (const s of this.sichtbareInstitution) {
				if (s.institution.id === tmpInst.id) {
					this._aktuelleInstitution = s;
				}
			}
		} else {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary: "Es konnten keine Daten geladen werden.",
			});
		}
	}

	private removeTabIndexParam() {
		let url = window.location.href;
		if (url.includes("activeIndex")) {
			let urlList = url.split("?");
			window.history.pushState({}, document.title, urlList[0]);
		}
	}

	private setAusfuellerListeAndLog(ausfueller: VerwaltbarerAusfuellerDto[]) {
		this.ausfuellerListe = ausfueller;
		return this.ausfuellerListe;
	}
}
