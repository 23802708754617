import { Injectable } from "@angular/core";
import { Abschnitt } from '@shared/model/Abschnitt';
import { AbschnittSperre } from '@shared/model/AbschnittSperre';
import { IFormViewerAbschnittSperrenHelper } from './IFormViewerAbschnittSperrenHelper';

@Injectable({providedIn: 'root'})
export class DummyFormViewerAbschnittSperrenHelper implements IFormViewerAbschnittSperrenHelper {

	istAbschnittFuerAktuellenUserGesperrt(abschnitt: Abschnitt): boolean {
		return false;
	}

	sperreAbschnitt(abschnitt: Abschnitt): Promise<AbschnittSperre> {
		return Promise.resolve(null);
	}

	entsperrenAbschnitt(abschnitt: Abschnitt): Promise<void> {
		return Promise.resolve();
	}

	starteRegelmaessigeVerlaengerung(abschnitt: Abschnitt) {
		// nop
	}

	stoppeRegelmaessigeVerlaengerung() {
		// nop
	}
}
