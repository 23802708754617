import { AusfuellerAnlegenDto } from '@form-viewer/ausfueller-verwaltung/model/AusfuellerAnlegenDto';
import { AusfuellerType } from '@form-viewer/model/AusfuellerType';
import { UUID } from '@util/export-types';

export class AbschnittsausfuellerAnlegenDto extends AusfuellerAnlegenDto {
	type: AusfuellerType = AusfuellerType.Abschnittsausfueller;

	fuerAbschnitt: UUID;
	abgabefrist = '';
}
