import { AbschnittAntwortChange } from '@form-viewer/abschnitt/abschnitt-antwort-change-detector/AbschnittAntwortChange';

const debug = require('debug')('helper:AbschnittAutosaveWatcher');

declare type SaveCallback = () => void;
declare type HasChangesCallback = () => AbschnittAntwortChange[];

export class AbschnittAutosaveWatcher {
	stabilityTimeoutCallback() {
		throw new Error('Method not implemented.');
	}
	private static readonly CHECK_FOR_CHANGES_INTERVAL_MS = 2000;

	private saveCallback: SaveCallback;
	private hasChangesCallback: HasChangesCallback;

	private checkChangesTimeout:number | undefined;

	private paused: boolean;

	constructor(saveCallback: SaveCallback, hasChangesCallback: HasChangesCallback) {
		this.saveCallback = saveCallback;
		this.hasChangesCallback = hasChangesCallback;
	}

	setupCheckChangesTimer() {
		this.clearCheckChangesTimer();
		this.checkChangesTimeout = window.setInterval(
			() => this.checkChanges(),
			AbschnittAutosaveWatcher.CHECK_FOR_CHANGES_INTERVAL_MS);
	}

	clearCheckChangesTimer() {
		if (this.checkChangesTimeout) {
			clearTimeout(this.checkChangesTimeout);
			this.checkChangesTimeout = null;
		}
	}

	checkChanges() {
		if (this.paused) {
			console.log("checkChanges paused")
			return
		}

		console.log("checkChanges");

		const changes = this.hasChangesCallback();
		const hasChanges = changes.length > 0;

		if (hasChanges) {
			console.log("found changes")
			this.saveCallback()
		}
	}

	destroy() {
		debug('clearing timer & destroying local state');
		this.clearCheckChangesTimer();
	}

	start() {
		this.setupCheckChangesTimer();
	}

	pause() {
		debug('pausing');
		this.paused = true;
	}

	stop() {
		debug('stopping (pause)');
		this.paused = true;
	}

	resume() {
		debug('resuming');
		this.paused = false;
	}
}
