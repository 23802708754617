import {NgIf} from '@angular/common';
import {Component, Input, OnDestroy} from '@angular/core';
import {ViewerHinweisComponent} from '@form-viewer/hinweis/ViewerHinweisComponent';
import {AbschnittSperreService} from '@form-viewer/service/AbschnittSperreService';
import {Abschnitt} from '@shared/model/Abschnitt';
import {Subscription} from 'rxjs/internal/Subscription';
import {AbschnittStorageService} from "@form-viewer/service/AbschnittStorageService";

const debug = require('debug')('ViewerAbschnittSperreComponent');

@Component({
			   selector: 'app-viewer-abschnitt-sperre',
			   templateUrl: './ViewerAbschnittSperreComponent.html',
			   styleUrls: ['./ViewerAbschnittSperreComponent.less'],
			   standalone: true,
			   imports: [NgIf, ViewerHinweisComponent]
		   })
export class ViewerAbschnittSperreComponent implements OnDestroy {
	gesperrt: boolean;

	abgeloest: boolean;

	bearbeiterName: string;

	_abschnitt: Abschnitt;

	subscriptions = new Subscription();

	constructor(private abschnittSperreService: AbschnittSperreService, private abschnittStorageService: AbschnittStorageService) {
		this.resetDisplay();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	@Input() set abschnitt(abschnitt: Abschnitt) {
		this._abschnitt = abschnitt;
		console.log('abschnitt gewechselt, refreshStatus');
		this.refreshStatus();
	}

	refreshStatus() {
		this.resetDisplay();

		if (!this._abschnitt) {
			return;
		}

		let abschnittSperre = this.abschnittStorageService.getForId(this._abschnitt.fragebogen_id).getSperren(this._abschnitt.id);
		if (abschnittSperre) {
			console.log('Sperre gefunden!')
			this.bearbeiterName = abschnittSperre.bearbeiter_name;
			if (!this.abschnittSperreService.istAktuell(abschnittSperre)) {
				if (this.abschnittSperreService.istEigeneSperre(abschnittSperre)) {
					this.abgeloest = true;
				} else {
					this.gesperrt = true;
				}
			}
		}
	}

	private resetDisplay() {
		this.abgeloest = false;
		this.gesperrt = false;
		this.bearbeiterName = '';
	}
}
