import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AusfuellerVerwaltungFormConfiguration } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration';
import { AusfuellerAnlegenDto } from '@form-viewer/ausfueller-verwaltung/model/AusfuellerAnlegenDto';
import { AlertService } from '@shared/alert/service/AlertService';
import { AktuelleBerechtigungService } from '@shared/service/AktuelleBerechtigungService';

const debug = require('debug')('service:ajax:AusfuellerAnlegenService');

@Injectable({providedIn: 'root'})
export class AusfuellerAnlegenService {
	constructor(private http: HttpClient, private alertService: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	anlegen(configuration: AusfuellerVerwaltungFormConfiguration, dto: AusfuellerAnlegenDto, params?: any): Promise<void> {
		debug('Speichere Benutzer/Recht', dto.toString());
		return this.http.post<void>('/view/ausfueller/' + configuration.getWebserviceEndpoint(), dto, {
			params: params ? params : this.aktuelleBerechtigungService.addCommonParams({})
		})
			.toPromise()
			.catch(this.alertService.handleHttpError);
	}
}
