import {NgIf} from "@angular/common";
import {Component, Input} from '@angular/core';
import {IFrageViewComponent} from '@form-viewer/abschnitt/frage-liste/frage/view/IFrageViewComponent';
import {TabellenfragenAntwort} from '@shared/model/antwort/TabellenfragenAntwort';
import {TabellenFrage} from '@shared/model/frage/TabellenFrage';
import {TabelleErweiterbarViewComponent} from "./tabelle-erweiterbar-view/TabelleErweiterbarViewComponent";
import {TabelleFixViewComponent} from "./tabelle-fix-view/TabelleFixViewComponent";

@Component({
	selector: "app-viewer-abschnitt-frage-view-tabelle",
	templateUrl: "./TabelleFrageViewComponent.html",
	styleUrls: [
		"./TabelleFrageViewComponent.less",
		"./tabelle-view.shared.less",
	],
	standalone: true,
 imports: [TabelleErweiterbarViewComponent, TabelleFixViewComponent, NgIf],
})
export class TabelleFrageViewComponent implements IFrageViewComponent {
	@Input()
	frage: TabellenFrage;

	@Input()
	antwort: TabellenfragenAntwort;

	@Input()
	readonly: boolean;

	printView: boolean;

	setPrintView(printView: boolean): void {
		this.printView = printView;
	}

	isReadonly() {
		return this.readonly;
	}
}
