import { InputTypeEmailViewComponent } from "@form-viewer/abschnitt/frage-liste/frage/view/input/input-type-email-view/InputTypeEmailViewComponent";
import { InputTypeEmailEditComponent } from "@shared/components/input-type/email/InputTypeEmailEditComponent";
import { InputTypeEmailAntwort } from "@shared/model/antwort/input/InputTypeEmailAntwort";
import { InputType } from './InputType';

export class InputTypeEmail extends InputType {
	static override readonly ID = 'InputTypeEmail';
	static override readonly TYPE_DISPLAY_NAME = 'E-Mail';
	static override readonly ANTWORT = InputTypeEmailAntwort;

	// add to @NgModule.entryComponents in app.module.ts
	static override readonly VIEWER = InputTypeEmailViewComponent;
	static override readonly EDITOR = InputTypeEmailEditComponent;

	platzhalterText = '';

	constructor(type?: InputTypeEmail) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
		}
	}

}
