import { Component, Input, ViewChild } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { KartenFrage } from '@shared/model/frage/KartenFrage';
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';

@Component({
	selector: "app-edit-karten-titel",
	templateUrl: "./KartenFrageEditTitelComponent.html",
	styleUrls: ["./KartenFrageEditTitelComponent.less"],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule, ModalModule],
})
export class KartenFrageEditTitelComponent {
	@Input()
	frage: KartenFrage;

	@ViewChild("optionsModal", { static: true })
	optionsModal: ModalDirective;

	titelPlatzhalterText = "";
	titelBeschreibung = "";

	openDialog() {
		this.titelPlatzhalterText = this.frage.titelPlatzhalterText;
		this.titelBeschreibung = this.frage.titelBeschreibung;
		this.optionsModal.show();
	}

	closeDialog(event?: Event) {
		this.optionsModal.hide();
		if (event) {
			event.stopPropagation();
		}
	}

	commitSave() {
		this.optionsModal.hide();
		this.frage.titelPlatzhalterText = this.titelPlatzhalterText;
		this.frage.titelBeschreibung = this.titelBeschreibung;
	}
}
