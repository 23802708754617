import {NgClass, NgFor} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Institution} from '@shared/model/Institution';
import {InstitutionFragebogenStatus} from '@shared/model/InstitutionFragebogenStatus';
import {DateFormatterUtil} from '@shared/util/DateFormatterUtil';
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";

@Component({
	selector: "app-institutions-uebersicht",
	templateUrl: "./InstitutionsUebersichtComponent.html",
	styleUrls: ["./InstitutionsUebersichtComponent.less"],
	standalone: true,
	imports: [NgFor, NgClass, PreventDefaultDirective]
})
export class InstitutionsUebersichtComponent {
	private institutionenNachInitialien: {
		[key: string]: InstitutionFragebogenStatus[];
	} = {};
	private initialienInGroups: string[][];

	@Output()
	select = new EventEmitter<Institution>();

	@Input()
	set institutionenUndStatus(
		institutionenUndStatus: InstitutionFragebogenStatus[]
	) {
		institutionenUndStatus.forEach((institutionUndStatus) => {
			const initialie = this.initialieVonInstitution(
				institutionUndStatus.institution
			);

			if (!this.institutionenNachInitialien[initialie]) {
				this.institutionenNachInitialien[initialie] = [];
			}

			this.institutionenNachInitialien[initialie].push(
				institutionUndStatus
			);
		});

		this.sortInstitutionenByStatus();
		this.sortInitialienInGroups(3);
	}

	private initialieVonInstitution(institution: Institution): string {
		return institution.anzeigename.trim().toUpperCase()[0];
	}

	getInitialien(): string[] {
		return Object.keys(this.institutionenNachInitialien).sort();
	}

	getInitialienInGroups() {
		return this.initialienInGroups;
	}

	private sortInitialienInGroups(initialienPerGroup = 3) {
		const groups: string[][] = [];
		const initialien = this.getInitialien();

		for (let i = 0; i < initialien.length; i += initialienPerGroup) {
			groups.push(initialien.slice(i, i + initialienPerGroup));
		}

		this.initialienInGroups = groups;
		return this.initialienInGroups;
	}

	getInstitutionenFuerInitiale(
		initiale: string
	): InstitutionFragebogenStatus[] {
		return this.institutionenNachInitialien[initiale];
	}

	onSelect(institutionFragebogenStatus: InstitutionFragebogenStatus) {
		if (institutionFragebogenStatus.abgeschlossen) {
			this.select.emit(institutionFragebogenStatus.institution);
		}
	}

	institutionLinkTitle(
		institutionFragebogenStatus: InstitutionFragebogenStatus
	): string {
		if (institutionFragebogenStatus.abgeschlossen) {
			let msg = "Fragebogen übermittelt am ";
			if (institutionFragebogenStatus.abschlussdatum) {
				msg += DateFormatterUtil.transformToDateOnly(
					institutionFragebogenStatus.abschlussdatum
				);
			}
			return msg;
		} else {
			return "Fragebogen noch nicht übermittelt";
		}
	}

	private sortInstitutionenByStatus() {
		for (const initiale of Object.keys(this.institutionenNachInitialien)) {
			// Abgeschlossen zuerst, danach nach Name
			this.institutionenNachInitialien[initiale].sort(
				(statusA, statusB) => {
					if (statusA.abgeschlossen && !statusB.abgeschlossen) {
						return -1;
					} else if (
						!statusA.abgeschlossen &&
						statusB.abgeschlossen
					) {
						return 1;
					} else {
						return statusA.institution.anzeigename.localeCompare(
							statusB.institution.anzeigename
						);
					}
				}
			);
		}
	}
}
