import { NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IInputTypeEditComponent } from '@shared/components/input-type/IInputTypeEditComponent';
import { InputTypeMehrfachauswahl } from '@shared/model/frage/input/InputTypeMehrfachauswahl';

@Component({
	selector: "app-input-type-mehrfachauswahl-edit",
	templateUrl: "./InputTypeMehrfachauswahlEditComponent.html",
	styleUrls: ["./InputTypeMehrfachauswahlEditComponent.less"],
	standalone: true,
	imports: [FormsModule, NgFor],
})
export class InputTypeMehrfachauswahlEditComponent
	implements OnInit, IInputTypeEditComponent<InputTypeMehrfachauswahl>
{
	@Input()
	inputType: InputTypeMehrfachauswahl;

	options: string[];

	ngOnInit(): void {
		this.options = this.inputType.options.slice(0);
	}

	getEditorTitel(): string {
		return "Auswahloptionen";
	}

	// trackByFn used in the ngFor, because it is iterating over an array of strings, so the default tracking by identity will fail
	// if the strings change content
	trackByFn(index, item) {
		return index;
	}

	addOption() {
		this.options.push("");
	}

	removeOption(index: number) {
		if (index > -1) {
			this.options.splice(index, 1);
		}
	}

	commitSave() {
		this.inputType.options = this.options;
	}
}
