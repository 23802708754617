import * as _ from 'underscore';
import { ClientIdAware } from './ClientIdAware';

const debug = require('debug')('ClientIdUtil');
const randomUUID = require('uuid/v4');

export class ClientIdUtil {
	static generateClientIdIfMissing(object: ClientIdAware) {
		if (!object.clientId) {
			object.clientId = randomUUID();
		}
	}

	static applyIdsBasedOnClientId(fromList: ClientIdAware[], toList: ClientIdAware[]) {
		const clientId2ToMap: {
			[s: string]: any
		} = _.object(toList.map(to => [to.clientId, to]));

		fromList.forEach(from => {
			const found = clientId2ToMap[from.clientId];
			if (found) {
				found.applyIds(from);
				debug('mapping %o to %o based on clientId %s', from, found, found.clientId);
			}
		});
	}
}
