import { KartenFeld } from '@shared/model/frage/KartenFeld';
import { InputAntwortBuilderUtil } from '@shared/util/InputAntwortBuilderUtil';
import { UUID } from '@util/export-types';
import { InputTypeAntwort } from './input/InputTypeAntwort';

export class KartenFeldAntwort {
	id: UUID;
	feld_id: UUID;
	antwort: InputTypeAntwort<any>;

	static forKartenfeld(kartenFeld: KartenFeld): KartenFeldAntwort {
		const kartenFeldAntwort = new KartenFeldAntwort();

		kartenFeldAntwort.feld_id = kartenFeld.id;
		kartenFeldAntwort.antwort = InputAntwortBuilderUtil.buildForType(kartenFeld.type);

		return kartenFeldAntwort;
	}

	constructor(kartenFeldAntwort?: KartenFeldAntwort) {

		if (kartenFeldAntwort) {
			this.id = kartenFeldAntwort.id;
			this.feld_id = kartenFeldAntwort.feld_id;
			this.antwort = InputAntwortBuilderUtil.build(kartenFeldAntwort.antwort);
		}
	}

	applyIds(feld: KartenFeldAntwort) {
		this.id = feld.id;

		this.antwort.applyIds(feld.antwort);
	}

	istInhaltlichGleich(o: KartenFeldAntwort) {
		return this.antwort.getClass() == o.antwort.getClass() && this.antwort.istInhaltlichGleich(o.antwort);
	}

	isValid() {
		return this.antwort.isValid();
	}
}
