import { SafeHtml } from '@angular/platform-browser';
import { InputTypeAntwort } from "@shared/model/antwort/input/InputTypeAntwort";
import { InputTypeAuswahl } from "@shared/model/frage/input/InputTypeAuswahl";

export class InputTypeAuswahlAntwort extends InputTypeAntwort<InputTypeAuswahl> {
	wert = '';

	constructor(antwort?: InputTypeAuswahlAntwort) {
		super(antwort);

		if (antwort) {
			this.wert = antwort.wert;
		}
	}

	getType() {
		return InputTypeAuswahl.ID;
	}

	isEmpty(): boolean {
		return !this.wert || this.wert.length === 0;
	}

    getHtml(): SafeHtml {
		return this.wert;
	}

	istInhaltlichGleich(o: InputTypeAntwort<any>): boolean {
		return this.wert === (o as InputTypeAuswahlAntwort).wert;
	}
}
