import {Ausfueller} from "@form-viewer/model/Ausfueller";
import {UUID} from "@util/export-types";
import {Abschnitt} from "./Abschnitt";

export class AbschnittSperre {
	id: UUID;
	abschnitt_id: string;
	institution_id: string;
	angelegt: Date;
	letzte_erneuerung: Date;
	bearbeiter_id: UUID;
	bearbeiter_name: string;
	neu_angelegt: boolean

	constructor(sperre?: AbschnittSperre) {
		if (sperre) {
			this.id = sperre.id;
			this.abschnitt_id = sperre.abschnitt_id;
			this.institution_id = sperre.institution_id;
			this.angelegt = sperre.angelegt;
			this.letzte_erneuerung = sperre.letzte_erneuerung;
			this.neu_angelegt = sperre.neu_angelegt;
			this.bearbeiter_id = sperre.bearbeiter_id;
			this.bearbeiter_name = sperre.bearbeiter_name;
		}
	}

	gehoertZu(ausfueller: Ausfueller): boolean {
		return ausfueller != null && this.bearbeiter_id === ausfueller.id;
	}

	gehoertZuAbschnitt(abschnitt: Abschnitt): boolean {
		return this.abschnitt_id === abschnitt.id;
	}
}
