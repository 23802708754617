<!--<p-toast></p-toast>-->

<div id="benutzer_bearbeiten_div"
	 style="height: calc(100vh - 240px); overflow-y: auto; overflow-x: hidden;">
	<div id="benutzer_bearbeiten_inner_div">
		<form class="ui form"
			  name="myForm"
			  (ngSubmit)="updateAusfueller()"
			  #myForm="ngForm">

			<p-card>
				<div class="form-group p-fluid formgrid grid">
					<div class="field col-12 md:col-6">
						<label class="col-fixed"
							   style="width: 120px">Anrede*</label>
						<p-dropdown name="anrede" [options]="anreden"
									[disabled]="formFieldDisabled"
									[(ngModel)]="verwaltbarerAusfueller.anrede"
									optionLabel="anrede"
									optionValue="anrede"
									placeholder="&nbsp;"
									[required]="true">
							<ng-template pTemplate="selectedItem">
								<div *ngIf="verwaltbarerAusfueller.anrede">
									<div>{{verwaltbarerAusfueller.anrede}}</div>
								</div>
							</ng-template>
						</p-dropdown>
					</div>

					<div class="field col-12 md:col-6">
						<label class="col-fixed"
							   style="width: 120px">Name*</label>
						<input name="name"
							   pInputText
							   required
							   type="text"
							   [disabled]="formFieldDisabled"
							   [(ngModel)]="verwaltbarerAusfueller.anzeigename">
					</div>

					<div class="field col-12 md:col-6">
						<label class="col-fixed"
							   style="width: 120px">E-Mail*</label>
						<input name="email"
							   pInputText
							   required
							   type="email"
							   email="true"
							   title="Bsp.: max.mustermann@unternehmen.de"
							   triggers="focus"
							   [disabled]="formEmailFieldDisabled"
							   [(ngModel)]="verwaltbarerAusfueller.email">
					</div>

					<div class="field col-12 md:col-6">
						<label class="col-fixed" style="width: 120px">Unternehmen</label>
						<input [disabled]="true"
							   [value]="currentInstituteString"
							   pInputText
							   required
							   readonly="true"
							   type="text">
					</div>
				</div>

				<ng-template pTemplate="footer">
					<div *ngIf="formFieldDisabled" style="margin-top: 5px;">
						<div
							style="font-weight: bold; font-size: 1.2em; margin-top: -3px; display: inline-block; vertical-align: top;">
							<fa-icon [icon]="faExclamationTriangle"
									 style="color: orangered; margin: 10px 5px;"></fa-icon>
						</div>
						<div
							style="font-weight: bold; font-size: 1.2em; margin-top: -10px; display: inline-block; white-space: pre-wrap;">
							{{message}}
						</div>
					</div>
				</ng-template>
			</p-card>
		</form>
		<p style="margin-top: 10px; margin-bottom: 5px;">
			<span class="areaLabel">Recherchekomplexe</span>
			<span class="areaLabelAddon"> (Sie können nur die Recherchekomplexe sehen, auf die Sie Zugriff
			haben)</span>
		</p>
		<p-table *ngIf="initDone" #table [value]="verwaltbarerAusfueller.institutionenUndRechechekomplexe"
				 [(selection)]="selectedValue"
				 selectionMode="single"
				 sortField="recherchekomplexName"
				 [sortOrder]="1"
				 styleClass="p-datatable-striped">
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="recherchekomplexName">Bezeichnung
						<p-sortIcon field="recherchekomplexName"></p-sortIcon>
					</th>
					<th pSortableColumn="recherchekomplexLabel">Rolle
						<p-sortIcon field="recherchekomplexLabel"></p-sortIcon>
					</th>
					<th pSortableColumn="recherchekomplexFrageboegenGesamt">Fragebögen
						<p-sortIcon field="recherchekomplexFrageboegenGesamt"></p-sortIcon>
					</th>
					<th style="width: 5rem">
						<form #myForm="ngForm">
							<button *ngIf="!avaliableKomplexListEmpty && !selfEditing"
									(click)="openAddRecherchekomplexDialog()"
									class="wideIconButton table-text-button p-button-raised"
									style="margin-left: 5px; background-color: #079716; border: 1px solid #079716; color: white;"
									data-bs-placement="bottom"
									data-bs-toggle="tooltip" pButton
									label="Hinzufügen"
									title="Neuen Recherchekomplex hinzufügen">
								<fa-icon style="margin-right: 1px" [icon]="faPlus"></fa-icon>
							</button>
						</form>
					</th>
					<th style="width: 5rem"></th>
				</tr>
				<tr>
					<th>
					<span class="p-input-icon-left p-column-filter-row">
						<i class="pi pi-filter"></i>
						<input (input)="table.filter($any($event.target).value, 'recherchekomplexName','contains')"
							   class="p-column-filter-row"
							   pInputText
							   type="text">
					</span>
					</th>
					<th>
					<span class="p-input-icon-left p-column-filter-row">
						<i class="pi pi-filter"></i>
						<input (input)="table.filter($any($event.target).value, 'recherchekomplexLabel','contains')"
							   class="p-column-filter-row"
							   pInputText
							   type="text">
					</span>
					</th>
					<th>
					<span class="p-input-icon-left p-column-filter-row">
						<i class="pi pi-filter"></i>
						<input
							(input)="table.filter($any($event.target).value, 'recherchekomplexFrageboegenGesamt','contains')"
							class="p-column-filter-row"
							pInputText
							type="text">
					</span>
					</th>
					<th></th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-institutionUndRecherchekomplex>

				<tr (dblclick)="zugeordnetenRecherchekomplexBearbeiten(institutionUndRecherchekomplex, false)"
					class="selectableRow">
					<!--				<td>{{institutionUndRecherchekomplex.institutionName}}</td>-->
					<td>{{institutionUndRecherchekomplex.recherchekomplexName}}</td>
					<td data-bs-toggle="tooltip" data-bs-placement="bottom"
						title="{{institutionUndRecherchekomplex.recherchekomplexTitel}}">
						{{institutionUndRecherchekomplex.recherchekomplexLabel}}
					</td>
					<td>{{institutionUndRecherchekomplex.recherchekomplexFrageboegenBerechtigt}}
						/{{institutionUndRecherchekomplex.recherchekomplexFrageboegenGesamt}}</td>
					<td>
						<button *ngIf="!selfEditing && !institutionUndRecherchekomplex.isJuveAdminRight() && !institutionUndRecherchekomplex.isCoAdminRight()"
								(click)="zugeordnetenRecherchekomplexBearbeiten(institutionUndRecherchekomplex, false)"
								class="table-button wideIconButton table-text-button p-button-raised"
								label="Bearbeiten"
								pButton
								data-bs-toggle="tooltip" data-bs-placement="top"
								type="button"
								style="margin-left: 5px;"
								title="Rechte des Benutzers für diesen Recherchekomplex verwalten">
							<fa-icon [icon]="faPen"></fa-icon>
						</button>
						<button *ngIf="selfEditing || institutionUndRecherchekomplex.isJuveAdminRight() || institutionUndRecherchekomplex.isCoAdminRight()"
								class="table-button wideIconButton table-text-button p-button-raised"
								(click)="zugeordnetenRecherchekomplexBearbeiten(institutionUndRecherchekomplex, false)"
								label="Bearbeiten"
								pButton
								data-bs-toggle="tooltip" data-bs-placement="top"
								type="button"
								style="margin-left: 5px;"
								title="Diese Rechte können nicht bearbeitet werden">
							<fa-icon style="color:red;" [icon]="faBan"></fa-icon>
						</button>
					</td>
					<td>
						<button *ngIf="!selfEditing && !institutionUndRecherchekomplex.isJuveAdminRight()"
								(click)="recherchekomplexRechtZurueckziehenConfirm(institutionUndRecherchekomplex)"
								class="table-button wideIconButton table-text-button p-button-raised table-trash-button"
								label="Entfernen"
								pButton
								data-bs-toggle="tooltip" data-bs-placement="top"
								type="button"
								style="margin-left: 5px;"
								title="Rechte des Benutzers für diesen Recherchekomplex entfernen">
							<fa-icon [icon]="faTrashCan"></fa-icon>
						</button>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<p-confirmDialog [baseZIndex]="10000"
				 [style]="{width: '50vw'}"
				 rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<form (ngSubmit)="updateAusfueller()" #myForm="ngForm">
	<div *ngIf="initDone"
		 style="background-color: white; width: calc(100vw - 40px); max-width: 99%; position: absolute; bottom: 5px; left: 10px;">
		<div *ngIf="!einladung" class="pull-left font-bold" style="color: grey; padding-top: 10px;">
			Eingeladen: {{verwaltbarerAusfueller.eingeladen | date: 'dd.MM.YYYY'}} /
			Registriert: {{verwaltbarerAusfueller.registriert | date: 'dd.MM.YYYY'}}
		</div>
		<div *ngIf="einladung" class="pull-left font-bold" style="color: grey; padding-top: 10px;">
			Eingeladen: {{verwaltbarerAusfueller.eingeladen | date: 'dd.MM.YYYY'}}
		</div>

		<div class="pull-right">
			<button (click)="neuenBenutzercodeGenerieren()"
					*ngIf="!isAdminEditing() && einladung"
					class="mr-2 mb-2 p-button-raised p-button-text"
					icon="pi pi-sync"
					iconPos="left"
					label="Neuen Registrierungscode generieren und per E-Mail versenden"
					onclick="window.getSelection().removeAllRanges();"
					pButton
					pRipple
					style="width: auto !important; margin-top: 5px; font-weight: bold;"
					title="Generiert einen neuen Registrierungscode und sendet dem Benutzer eine Mail mit dem Code und JUVE den Bestätigungscode."
					type="button">
			</button>

			<button pButton
					pRipple
					type="button"
					label="Neues Passwort senden"
					title="Generiert ein neues Passwort für den Benutzer und korrigiert ggf Fehler in der Konfiguration der Logins"
					class="mr-2 mb-2 p-button-raised p-button-text"
					icon="pi pi-key"
					iconPos="left"
					(click)="sendTempPwd()"
					*ngIf="!selfEditing && verwaltbarerAusfueller.id !== null"
					style="width: auto !important; margin-top: 5px; font-weight: bold; margin-right: 60px !important;"
					onclick="window.getSelection().removeAllRanges();">
			</button>

			<button pButton
					pRipple
					type="button"
					label="Abbrechen"
					class="mr-2 mb-2 p-button-raised p-button-text"
					icon="pi pi-times"
					iconPos="left"
					(click)="closeDialog()"
					style="width: auto !important; margin-top: 5px; font-weight: bold; margin-right: 20px;"
					onclick="window.getSelection().removeAllRanges();">
			</button>

			<button pButton
					pRipple
					type="button"
					[label]="verwaltbarerAusfueller.id === null ? 'Einladen' : 'Speichern'"
					class="mr-2 mb-2 p-button-raised "
					icon="pi pi-check"
					iconPos="left"
					(click)="updateAusfueller()"
					style="width: auto !important; margin-top: 5px; font-weight: bold; margin-right: 20px;"
					onclick="window.getSelection().removeAllRanges();">
			</button>
		</div>
	</div>
</form>

<p-dialog [(visible)]="showAddKomplexDialog"
		  [closeOnEscape]="false"
		  header="Neuen Recherchekomplex zuordnen"
		  [modal]="true"
		  styleClass="overflowDialog width500Dialog">
	<form #addKomplexForm="ngForm" (ngSubmit)="addRecherchekomplex()">
		<div class="form-group p-fluid formgrid grid" style="margin-left: 10px; margin-right: 10px;">
			<div class="field col-12">
				<label class="col-fixed">Recherchekomplex *</label>
				<p-dropdown [(ngModel)]="newKomplex"
							[options]="avaliableKomplexList"
							[showClear]="false"
							name="newKomplex"
							optionLabel="name"
							placeholder="Recherchekomplex auswählen">
				</p-dropdown>
			</div>
			<div class="field col-12">
				<div style="float: left;">
					<p-inputSwitch [(ngModel)]="inviteAsCoAdmin" id="inviteAsCoAdmin"
								   name="inviteAsCoAdmin"></p-inputSwitch>
				</div>
				<label (click)="inviteAsCoAdmin = !inviteAsCoAdmin" class="col-fixed" for="inviteAsCoAdmin"
					   style="float: left;">Als Recherche-Koordinator einladen</label>
			</div>
			<div class="field col-12" *ngIf="inviteAsCoAdmin">
				<div style="float: left;">
					<p-inputSwitch [(ngModel)]="darfFragebogenAbschliessen"
								   id="darfFragebogenAbschliessen"
								   name="darfFragebogenAbschliessen"></p-inputSwitch>
				</div>
				<label
					(click)="darfFragebogenAbschliessen = !darfFragebogenAbschliessen"
					class="col-fixed" for="darfFragebogenAbschliessen"
					style="float: left;">Darf Fragebögen an JUVE übermitteln</label>
			</div>

		</div>
		<div class="pull-right" style="clear: both; float: right;">
			<button (click)="closeAddKomplexDialog()" class="p-mr-2 p-mb-2 p-button-text" label="Abbrechen"
					onclick="window.getSelection().removeAllRanges();"
					pButton
					pRipple
					type="button"
					iconPos="left"
					icon="pi pi-times">
			</button>
			<button (click)="addRecherchekomplex()" *ngIf="!selfEditing" class="p-mr-2 p-mb-2"
					label="Übernehmen"
					onclick="window.getSelection().removeAllRanges();"
					pButton
					pRipple
					type="button"
					icon="pi pi-check"
					iconPos="left">
			</button>
		</div>
	</form>
</p-dialog>

