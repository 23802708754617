import { UUID } from '@util/export-types';

export class LogEintrag {
	id: UUID;
	user: UUID;
	institution: UUID;
	recherchekomplex: UUID;
	currentUrl: String;
	errorUrl: String;
	nachricht: String;
	status: number;
	timestamp: Date;

	constructor(logEintrag?: LogEintrag) {
		if (logEintrag) {
			this.id = logEintrag.id;
			this.user = logEintrag.user;
			this.institution = logEintrag.institution;
			this.recherchekomplex = logEintrag.recherchekomplex;
			this.errorUrl = logEintrag.errorUrl;
			this.nachricht = logEintrag.nachricht;
			this.status = logEintrag.status;
			this.timestamp = logEintrag.timestamp;
		}
	}

}
