import { Component, Input } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { AusfuellerVerwaltungInnerFormComponent } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungInnerFormComponent';
/* eslint-disable-next-line max-len */
import { AbschnittsausfuellerVerwaltungFormConfiguration } from '@form-viewer/ausfueller-verwaltung/form/abschnittsausfueller/AbschnittsausfuellerVerwaltungFormConfiguration';
import { AbschnittsausfuellerAnlegenDto } from '@form-viewer/ausfueller-verwaltung/model/AbschnittsausfuellerAnlegenDto';
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: "app-abschnittsausfueller-verwaltung-form",
	templateUrl: "./AbschnittsausfuellerVerwaltungFormComponent.html",
	styleUrls: ["./AbschnittsausfuellerVerwaltungFormComponent.less"],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule],
})
export class AbschnittsausfuellerVerwaltungFormComponent
	implements AusfuellerVerwaltungInnerFormComponent
{
	@Input()
	dto: AbschnittsausfuellerAnlegenDto;

	@Input()
	disabled = false;

	@Input()
	configuration: AbschnittsausfuellerVerwaltungFormConfiguration;
}
