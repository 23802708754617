import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbschnittInfoService} from '@form-viewer/service/AbschnittInfoService';
import {FragebogenAbschnittsdaten} from '@form-viewer/service/AbschnittStorageService/FragebogenAbschnittsdaten';
import {PreviewService} from '@form-viewer/service/PreviewService';
import {ViewerAbschnittService} from '@form-viewer/service/ViewerAbschnittService';
import {AnzahlGepruefterFragenProAbschnittDto} from '@shared/model/AnzahlGepruefterFragenProAbschnittDto';
import {Fragebogen} from '@shared/model/Fragebogen';
import {ArrayUtil} from '@shared/util/ArrayUtil';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Abschnitt} from "@shared/model/Abschnitt";
import {AbschnittSperre} from "@shared/model/AbschnittSperre";
import {AbschnittSperreService} from "@form-viewer/service/AbschnittSperreService";
import {AlertService} from "@shared/alert/service/AlertService";

const debug = require('debug')('service:AbschnittStorageService');

@Injectable({providedIn: 'root'})
export class AbschnittStorageService {

	abschnittsdaten = new Map<string, FragebogenAbschnittsdaten>();

	constructor(private http: HttpClient,
				private abschnittService: ViewerAbschnittService,
				private abschnittInfoService: AbschnittInfoService,
				private previewService: PreviewService,
				private abschnittSperreService: AbschnittSperreService,
				private alerts: AlertService) {
	}

	@BlockUI() blockUI: NgBlockUI;

	ladeAbschnitte(fragebogen: Fragebogen): Promise<FragebogenAbschnittsdaten> {
		debug('ladeAbschnitte fuer:', fragebogen.name);

		return Promise.all([
			this.abschnittService.getAbschnitte(fragebogen),
			this.abschnittInfoService.getFragebogenAbschnitteInfo(fragebogen),
			this.getAnzahlGepruefterFragenProAbschnitt(fragebogen),
		]).then((results: any[]) => {
			debug('Abschnitte geladen fuer:', fragebogen.name);

			const fragebogenAbschnittsdaten = new FragebogenAbschnittsdaten(
				fragebogen,
				results[0],
				results[1].getSperren(),
				results[1].getStatus(),
				results[2]
			);

			this.saveInSession(fragebogen.id, fragebogenAbschnittsdaten);
			this.abschnittsdaten.set(fragebogen.id, fragebogenAbschnittsdaten);
			return Promise.resolve(fragebogenAbschnittsdaten);
		});

	}

	aktualisiereAbschnittsStatus(fragebogen: Fragebogen): Promise<FragebogenAbschnittsdaten> {
		debug('aktualisiereAbschnittsStatus fuer:', fragebogen.name);

		return Promise.all([
			this.abschnittInfoService.getFragebogenAbschnitteInfo(fragebogen),
			this.getAnzahlGepruefterFragenProAbschnitt(fragebogen),
		]).then(results => {
			debug('aktualisiereAbschnittsStatus erledigt fuer:', fragebogen.name);

			let fragebogenAbschnittsdaten = this.get(fragebogen);
			ArrayUtil.replaceContent(fragebogenAbschnittsdaten.abschnittSperren, results[0].getSperren());
			ArrayUtil.replaceContent(fragebogenAbschnittsdaten.abschnittStatus, results[0].getStatus());
			ArrayUtil.replaceContent(fragebogenAbschnittsdaten.anzahlGepruefterFragenProAbschnittDtos, results[1]);
			this.saveInSession(fragebogen.id, fragebogenAbschnittsdaten)
		}).catch(error =>
			debug('Beim Aktualisieren des Abschnittstatus fuer ', fragebogen, ' ist ein Fehler aufgetreten: ', error));
	}

	get(fragebogen: Fragebogen): FragebogenAbschnittsdaten {
		return this.getForId(fragebogen.id)
	}

	getForId(fragebogenId: string): FragebogenAbschnittsdaten {
		if (!this.abschnittsdaten || !this.abschnittsdaten.has(fragebogenId)) {
			this.abschnittsdaten.set(fragebogenId, this.loadFromSession(fragebogenId));
		}
		return this.abschnittsdaten.get(fragebogenId);
	}

	private getAnzahlGepruefterFragenProAbschnitt(fragebogen: Fragebogen): Promise<AnzahlGepruefterFragenProAbschnittDto[]> {
		if (this.previewService.isInPreview()) {
			return Promise.resolve([]);
		} else {
			return this.abschnittService.getAnzahlGepruefterFragenProAbschnitt(fragebogen);
		}
	}

	sperren(abschnitt: Abschnitt): Promise<AbschnittSperre> {
		return this.abschnittSperreService.sperren(abschnitt).then(sperre => {
			let abschnittsDaten = this.getForId(abschnitt.fragebogen_id);
			if (abschnittsDaten) {
				abschnittsDaten.setSperren(sperre);
				this.saveInSession(abschnitt.fragebogen_id, abschnittsDaten)
			}
			if (sperre.neu_angelegt) {
				return Promise.resolve(sperre)
			} else {
				this.alerts.showWarning('Sperre für Abschnitt ' + abschnitt.ueberschrift + ' wurde nicht erteilt');
				return Promise.resolve(null)
			}
		})
	}

	private saveInSession(fragebogenId: string, fragebogenAbschnittsdaten: FragebogenAbschnittsdaten) {
		sessionStorage.clear();
		sessionStorage.setItem(`f_${fragebogenId}`, this.encodeB64(JSON.stringify(fragebogenAbschnittsdaten)))
	}

	private loadFromSession(fragebogenId: string): FragebogenAbschnittsdaten {
		return JSON.parse(this.decodeB64(sessionStorage.getItem(`f_${fragebogenId}`)));
	}

	private encodeB64(str): string {
		return btoa(unescape(encodeURIComponent(str)));
	}

	private decodeB64(str): string {
		return decodeURIComponent(escape(atob(str)));
	}
}
