import { Antwort } from '@shared/model/Antwort';

export class TextfeldfragenAntwort extends Antwort {
	static override readonly FRAGEN_TYPE_ID = 'TextfeldFrage';

	textantwort = '';

	constructor(antwort?: TextfeldfragenAntwort) {
		super(antwort);
		if (antwort) {
			this.textantwort = antwort.textantwort || '';
		}
	}

	isEmpty() {
		return this.textantwort.length === 0;
	}

	istInhaltlichGleich(o: Antwort): boolean {
		return this.textantwort.trim() === (o as TextfeldfragenAntwort).textantwort.trim();
	}
}
