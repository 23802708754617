import { NgIf } from '@angular/common';
import { Component, NgZone } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from "@angular/platform-browser";
import { Router, RouterLink } from "@angular/router";
import { ContactComponent } from '@shared/components/contact/ContactComponent';
import { SpinnerComponent } from '@shared/components/spinner/SpinnerComponent';
import { MessageService } from "primeng/api";
import { ToastModule } from 'primeng/toast';
import { AusfuellerAbgabezeitpunktService } from "../../form-viewer/service/AusfuellerAbgabezeitpunktService";
import { AusfuellerBerechtigungsService } from "../../form-viewer/service/AusfuellerBerechtigungsService";
import { UserService } from "../../form-viewer/service/UserService";
import { PasswortZuruecksetzenService } from "./PasswortZuruecksetzenService";

const debug = require('debug')('PasswortZuruecksetzenComponent');

@Component({
	templateUrl: "./PasswortZuruecksetzenComponent.html",
	standalone: true,
	imports: [SpinnerComponent,ToastModule, FormsModule, RouterLink, ContactComponent, NgIf],
})
export class PasswortZuruecksetzenComponent {
	credentials = { username: "" };
	error: boolean;
	loaded: boolean;

	constructor(
		private messageService: MessageService,
		private passwortzuruecksetzenService: PasswortZuruecksetzenService,
		private router: Router,
		private ngZone: NgZone,
		private userService: UserService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
		private ausfuellerAbgabezeitpunktService: AusfuellerAbgabezeitpunktService,
		private title: Title
	) {
		title.setTitle("Login - Juve Recherche");
		this.loaded = true;
		userService.clearData();
		userService.clearStorage();
		this.ausfuellerBerechtigungsService.clearCache();
		this.ausfuellerAbgabezeitpunktService.clearCache();
		this.credentials = { username: "" };
	}

	async passwortZuruecksetzen() {
		this.loaded = false;

		this.messageService.clear();
		this.messageService.add({
			sticky: false,
			severity: "info",
			key: "PW_zurueck_start_msg",
			summary:
				'Das Passwort wird für "' +
				this.credentials.username +
				'" zurückgesetzt',
			detail: "Das kann einen Moment dauern!",
		});

		this.credentials.username = this.credentials.username.replace(
			/\s/g,
			""
		);

		this.passwortzuruecksetzenService
			.sendePasswortZuruecksetzenMail(this.credentials.username)
			.then((response) => {
				console.log("Erfolgreich");
				this.messageService.clear();
				this.messageService.add({
					sticky: true,
					key: undefined,
					severity: "info",
					summary: "E-Mail erfolgreich verschickt",
					detail:
						"An die von Ihnen eingegebene Adresse " +
						this.credentials.username +
						" haben wir eine E-Mail mit Informationen zum weiteren Vorgehen " +
						"geschickt. Bitte kontrollieren Sie auch Ihren Spam-Ordner.",
				});
				this.loaded = true;
				//this.router.navigate(['/']);
			})
			.catch((response) => {
				console.log(
					"Passwort zurücksetzen wirft einen Fehler " +
						response.error.message
				);

				this.messageService.clear();
				this.messageService.add({
					severity: "error",
					summary:
						'Passwort zurücksetzen nicht möglich für "' +
						this.credentials.username +
						'"! ',
					detail:
						response.error.message +
						"\n(" +
						new Date().toLocaleString("de") +
						")",
					life: 8000,
				});
				this.error = true;
				this.loaded = true;
			});
	}
}
