import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FrageGeprueftMarker } from '@form-viewer/model/FrageGeprueftMarker';
import { AlertService } from '@shared/alert/service/AlertService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { Frage } from '@shared/model/Frage';
import { AktuelleBerechtigungService } from '@shared/service/AktuelleBerechtigungService';

const debug = require('debug')('FrageGeprueftMarkerService');

@Injectable({providedIn: 'root'})
export class FrageGeprueftMarkerService {

	constructor(private http: HttpClient, private alertService: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	getGeprueftMarkerFuerAbschnitt(abschnitt: Abschnitt): Promise<FrageGeprueftMarker[]> {
		debug('Lade Fertig-Marker für Abschnitt', abschnitt);
		return this.http.get<FrageGeprueftMarker[]>('/view/frage/geprueft-marker', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				abschnitt: abschnitt.id,
			})
		})
			.toPromise()
			.then(response => FrageGeprueftMarker.buildList(response))
			.catch(this.alertService.handleHttpError);
	}

	alsGeprueftMarkieren(frage: Frage): Promise<void> {
		return this.http.post('/view/frage/geprueft-marker', null, {
			params: this.aktuelleBerechtigungService.addCommonParams({
				frage: frage.id,
			})
		})
			.toPromise()
			.then(() => {
			})
			.catch(this.alertService.handleHttpError);
	}

	alsUngeprueftMarkieren(frage: Frage): Promise<void> {
		return this.http.delete('/view/frage/geprueft-marker', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				frage: frage.id,
			})
		})
			.toPromise()
			.then(() => {
			})
			.catch(this.alertService.handleHttpError);
	}
}
