import {Abschnitt} from '@shared/model/Abschnitt';
import {AbschnittSperre} from '@shared/model/AbschnittSperre';
import {AbschnittStatus} from '@shared/model/AbschnittStatus';
import {AnzahlGepruefterFragenProAbschnittDto} from '@shared/model/AnzahlGepruefterFragenProAbschnittDto';
import {Fragebogen} from '@shared/model/Fragebogen';

export class FragebogenAbschnittsdaten {

	private readonly fKannNochAusgefuelltWerden: boolean
	public abschnitte: Abschnitt[];
	public abschnittSperren: AbschnittSperre[];
	public abschnittStatus: AbschnittStatus[];
	public anzahlGepruefterFragenProAbschnittDtos: AnzahlGepruefterFragenProAbschnittDto[];

	constructor(
		fragebogen: Fragebogen,
		abschnitte: Abschnitt[],
		abschnittSperren: AbschnittSperre[],
		abschnittStatus: AbschnittStatus[],
		anzahlGepruefterFragenProAbschnittDtos: AnzahlGepruefterFragenProAbschnittDto[]
	) {
		this.fKannNochAusgefuelltWerden = fragebogen.kannRkNochAusgefuelltWerden()
		this.anzahlGepruefterFragenProAbschnittDtos = anzahlGepruefterFragenProAbschnittDtos;
		this.abschnittStatus = abschnittStatus;
		this.abschnittSperren = abschnittSperren;
		this.abschnitte = abschnitte;
	}

	get length(): number {
		return this.abschnitte.length;
	}

	hatAbschnitte(): boolean {
		return !!this.abschnitte && this.abschnitte.length > 0;
	}

	getAnzahlGepruefterFragen(abschnitt: Abschnitt): number {
		const abschnittAntwortMetaInfoDto = this.getAbschnittAntwortMetaInfoDto(abschnitt);
		if (abschnittAntwortMetaInfoDto) {
			return abschnittAntwortMetaInfoDto.getAnzahlGepruefterAntworten();
		} else {
			return 0;
		}
	}

	private getAbschnittAntwortMetaInfoDto(abschnitt: Abschnitt): AnzahlGepruefterFragenProAbschnittDto {
		return this.anzahlGepruefterFragenProAbschnittDtos.find(
			metaInfoDto => metaInfoDto.abschnitt_id === abschnitt.id);
	}

	sindAlleFragenGeprueft(abschnitt: Abschnitt): boolean {
		return abschnitt.fragen.length > 0 &&
			this.getAnzahlGepruefterFragen(abschnitt) === abschnitt.fragen.length;
	}

	getStatus(abschnitt: Abschnitt): AbschnittStatus {
		return this.abschnittStatus.find(innerStatus => {
			return innerStatus.abschnitt_id === abschnitt.id;
		});
	}

	hatStatus(abschnitt: Abschnitt): boolean {
		return this.abschnittStatus.some(innerStatus => {
			return innerStatus.abschnitt_id === abschnitt.id;
		});
	}

	getSperren(abschnittId: string): AbschnittSperre {
		return this.abschnittSperren.find(innerSperre => {
			return innerSperre.abschnitt_id === abschnittId;
		});
	}

	setSperren(sperre: AbschnittSperre) {
		let index = this.abschnittSperren.findIndex(innerSperre => innerSperre.abschnitt_id === sperre.abschnitt_id);
		if (index >= 0) {
			this.abschnittSperren[index] = sperre;
		} else {
			this.abschnittSperren.push(sperre)
		}
	}

	hatSperren(abschnitt: Abschnitt): boolean {
		return this.abschnittSperren.some(innerSperre => {
			return innerSperre.abschnitt_id === abschnitt.id;
		});
	}

	kannNochAusgefuelltWerden(): boolean {
		return this.fKannNochAusgefuelltWerden;
	}
}
