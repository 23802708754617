import { FrageBuilderUtil } from "@shared/util/FrageBuilderUtil";
import { UUID } from '@util/export-types';
import { removeElement } from '@util/removeElement';
import { Frage } from './Frage';

export class Abschnitt {
	id: UUID;
	ueberschrift = '';
	beschreibung = '';
	fragebogen_id: UUID;
	sort_index: 0;
	fragen: Frage[];
	beantwortet: boolean;
	hatRechte: boolean;

	constructor(abschnitt?: Abschnitt) {
		if (abschnitt) {
			this.constructFromAbschnitt(abschnitt);
			this.hatRechte = abschnitt.hatRechte;
		} else {
			this.constructEmpty();
		}
	}

	constructEmpty() {
		this.fragen = [];
	}

	constructFromAbschnitt(abschnitt: Abschnitt) {
		this.id = abschnitt.id;
		this.ueberschrift = abschnitt.ueberschrift;
		this.beschreibung = abschnitt.beschreibung;
		this.fragebogen_id = abschnitt.fragebogen_id;
		this.sort_index = abschnitt.sort_index;
		this.fragen = [];
		this.beantwortet = abschnitt.beantwortet;
		if (abschnitt.fragen) {
			this.fragen = abschnitt.fragen.map(frage => FrageBuilderUtil.build(frage));
		}
	}

	setId(id: UUID): Abschnitt {
		this.id = id;
		return this;
	}

	addFrage(frage?: Frage) {
		if (!frage) {
			frage = new Frage();
		}

		this.fragen = [...this.fragen, frage]
	}

	removeFrage(frage: Frage) {
		removeElement(this.fragen, frage);
	}

	updateFrage(oldFrage: Frage, newFrage: Frage) {
		const index = this.fragen.indexOf(oldFrage);
		if (index !== -1) {
			this.fragen[index] = newFrage;
		}
	}

	toString() {
		return 'Abschnitt{' + this.ueberschrift + '}';
	}

	hasId() {
		return !!this.id;
	}
}
