import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@shared/alert/service/AlertService';
import { Fragebogen } from '@shared/model/Fragebogen';

@Injectable({ providedIn: "root" })
export class FragebogenDuplizierenService {
	private static getBody(fragebogen: Fragebogen) {
		return {
			neuerName: fragebogen.name,
			neueBeschreibungKurz: fragebogen.beschreibungKurz,
			neueBeschreibungLang: fragebogen.beschreibungLang,
			neuerRecherchekomplex: fragebogen.recherchekomplex.id,
		};
	}

	constructor(private http: HttpClient, private alertService: AlertService) {}

	dupliziereFragebogen(
		fragebogen: Fragebogen,
		ref: boolean
	): Promise<Fragebogen> {
		const endpoint = ref
			? "/duplizieren-mit-vorjahresfragebogen"
			: "/duplizieren";
		return this.http
			.post<Fragebogen>(
				"/api/editor/fragebogen/" + fragebogen.id + endpoint,
				FragebogenDuplizierenService.getBody(fragebogen)
			)
			.toPromise()
			.catch(this.alertService.handleHttpError);
	}
}
