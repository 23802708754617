import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ViewerHinweisComponent } from '@form-viewer/hinweis/ViewerHinweisComponent';
import { AbschnittSperreService } from '@form-viewer/service/AbschnittSperreService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { AnredeUtil } from '@util/AnredeUtil';
import { Subscription } from 'rxjs/internal/Subscription';

const debug = require('debug')('ViewerAbschnittSperreComponent');

@Component({
	selector: "app-viewer-abschnitt-sperre",
	templateUrl: "./ViewerAbschnittSperreComponent.html",
	styleUrls: ["./ViewerAbschnittSperreComponent.less"],
	standalone: true,
	imports: [NgIf, ViewerHinweisComponent]
})
export class ViewerAbschnittSperreComponent implements OnInit, OnDestroy {
	gesperrt: boolean;

	abgeloest: boolean;

	bearbeiterName: string;

	_abschnitt: Abschnitt;

	subscriptions = new Subscription();

	constructor(private abschnittSperreService: AbschnittSperreService) {
		this.resetDisplay();
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.abschnittSperreService.sperrenAktualisiert.subscribe(() => {
				debug("sperren wurden gaendert, refreshStatus");
				this.refreshStatus();
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	@Input()
	set abschnitt(abschnitt: Abschnitt) {
		this._abschnitt = abschnitt;
		debug("abschnitt gewechselt, refreshStatus");
		this.refreshStatus();
	}

	refreshStatus() {
		this.resetDisplay();

		if (!this._abschnitt) {
			return;
		}

		this.abschnittSperreService
			.get(this._abschnitt)
			.then((abschnittSperre) => {
				this.bearbeiterName =
					AnredeUtil.dekliniere(abschnittSperre.getAnrede()) +
					" " +
					abschnittSperre.getAnzeigename();
				if (!this.abschnittSperreService.istAktuell(abschnittSperre)) {
					if (
						this.abschnittSperreService.istEigeneSperre(
							abschnittSperre
						)
					) {
						this.abgeloest = true;
					} else {
						this.gesperrt = true;
					}
				}
				// @formatter:off
			})
			.catch(() => {
				// nop - ein 404-Fehler hier ist ein erwartetes Verhalten
			});
		// @formatter:on
	}

	private resetDisplay() {
		this.abgeloest = false;
		this.gesperrt = false;
		this.bearbeiterName = "";
	}
}
