import { KartenAntwort } from '@shared/model/antwort/KartenAntwort';
export class ViewKarte {
	antwort: KartenAntwort;
	isOpen = false;

	constructor(antwort: KartenAntwort, open = false) {
		this.antwort = antwort;
		this.isOpen = open;
	}

	close() {
		this.isOpen = false;
	}

	open() {
		this.isOpen = true;
	}

	toggleOpen() {
		this.isOpen = !this.isOpen;
	}
}
