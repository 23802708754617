import { Injectable } from '@angular/core';
import { removeElement } from './removeElement';

const debug = require('debug')('UnloadService');

declare type BeforeUnloadCallback = () => string;
declare type UnloadCallback = () => void;
declare type RemoveCallback = () => void;

/**
 * Dieser Service versucht die Unregelmäßigkeiten in den Browser-Implementierungen von onUnload und onBeforeUnload möglichst auszugleichen.
 * Dazu registriert er sich sowohl auf unload als auch auf beforeunload, verwendet das jeweils zuerst feuernde Event und implementiert darin
 * seine eigene beforeUnload/unload Logik.
 */
@Injectable({providedIn: 'root'})
export class UnloadService {
	/**
	 * Flag welches vom onBeforeunload-Handler auf tru gesetzt wird und verhindert, dass ein darauffolgender Aufruf des onUnload-Handlers
	 * einen weiteren Callback triggert
	 */
	private beforeHandlerFired = false;

	private unloadHandler = (() => {
		if (this.beforeHandlerFired) {
			debug('unload-callback called but beforeunload has already been called, ignoring (but resetting beforeunload-state)');
			this.beforeHandlerFired = false;
			return;
		}

		debug('unload-callback called');
		this._handle();
	});

	private beforeUnloadHandler = ((event: any) => {
		this.beforeHandlerFired = true;

		debug('beforeunload-callback called');
		const returnValue = this._handle();
		debug('beforeunload-handling returned', returnValue);

		if (returnValue) {
			event.returnValue = returnValue;
			return returnValue;
		}
		return null;
	});

	private beforeUnloadCallbacks: BeforeUnloadCallback[] = [];
	private unloadCallbacks: UnloadCallback[] = [];

	constructor() {
		this._registerHandler();
	}

	_registerHandler() {
		debug('registerting unload & beforeunload callback');
		window.addEventListener('unload', this.unloadHandler, false);
		window.addEventListener('beforeunload', this.beforeUnloadHandler, false);
	}

	/**
	 * UnloadCallbacks werden nach allen BeforeUnloadCallbacks ausgeführt, falls keiner davon eine Unterbrechung des unloadens eingeleitet
	 * hat.
	 *
	 * Es gibt keine Zusicherung, dass UnloadCallbacks beim Schließen der Seite ausgeführt werden. Neben den offensichlichen Gründen
	 * (Browser-Prozess killen, Strom ausschalten) gibt es auch noch weniger offensichtliche Gründe: Hat zuvor ein
	 * BeforeUnloadCallback versucht das Schließen zu verhindern, dies wurde vom Benutzer aber abgelehnt, feuern viele Browser *gar kein*
	 * Event mehr sobald die Seite dann endgültig verlassen wurde. In diesem Fall werden die UnloadCallbacks nicht mehr gerufen.
	 *
	 * Die Anwendung muss in jedem Fall damit klarkommen, dass UnloadCallback nicht gerufen werden.
	 *
	 * @param {UnloadCallback} callback
	 * @returns {number} Id des registrierten Callbacks zur Verwendung mit unregisterCallback
	 */
	registerUnloadCallback(callback: UnloadCallback): RemoveCallback {
		this.unloadCallbacks.push(callback);
		debug('registered unload-handler', callback);
		return () => {
			removeElement(this.unloadCallbacks, callback);
		};
	}

	/**
	 * BeforeUnloadCallbacks werden vor allen UnloadCallback gerufen. Sie können eine Meldung (string) zurückgeben, in diesem Fall wird
	 * versucht das unloaden der Seite unter Angabe dieser Meldung zu verhindern. Manche Browser zeigen diese Meldung an, manche nicht,
	 * manche erlauben auch gar nicht das unterbrechen des unloadens.
	 *
	 * Geben mehrere BeforeUnloadCallbacks eine Meldung zurück, wird die des zuletzt hinzugefügten BeforeUnloadCallbacks verwendet.
	 *
	 * Es gibt keine Zusicherung, dass BeforeUnloadCallback beim Schließen der Seite ausgeführt werden.
	 *
	 * Zugesichert ist nur, dass falls ein BeforeUnloadCallback eine Meldung zurückgibt, keiner der UnloadCallback gerufen wird.
	 *
	 * @param {BeforeUnloadCallback} callback Callback, der eine Meldung zurückgeben kann
	 * @returns {number} Id des registrierten Callbacks zur Verwendung mit unregisterCallback
	 */
	registerBeforeUnloadCallback(callback: BeforeUnloadCallback) {
		this.beforeUnloadCallbacks.push(callback);
		debug('registered beforeunload-handler', callback);
		return () => {
			removeElement(this.beforeUnloadCallbacks, callback);
		};
	}

	_handle(): string | null {
		let returnValue: string | null = null;

		debug('calling beforeunload-handler');
		this.beforeUnloadCallbacks.forEach(callback => {
			const localReturnValue = callback();
			if (localReturnValue !== undefined && localReturnValue !== null) {
				returnValue = localReturnValue;
			}
		});

		if (returnValue !== null && returnValue !== undefined) {
			debug('beforeunload-handler returned', returnValue, 'skipping unload-handler');
			return returnValue;
		}

		debug('calling unload-handler');
		this.unloadCallbacks.forEach(callback => {
			callback();
		});

		return null;
	}
}
