import { FrageAntwortBuilderUtil } from '@shared/util/FrageAntwortBuilderUtil';
import { UUID } from '@util/export-types';
import { Abschnitt } from './Abschnitt';
import { Antwort } from './Antwort';
import { Frage } from './Frage';

export class AbschnittAntwortDto {
	abschnitt_id: UUID;
	institution_id: UUID;
	antworten: Antwort[] = [];

	static newForAbschnitt(abschnitt: Abschnitt): AbschnittAntwortDto {
		const antwort = new AbschnittAntwortDto();

		antwort.abschnitt_id = abschnitt.id;

		return antwort;
	}

	constructor(abschnitt_antwort?: AbschnittAntwortDto) {
		if (abschnitt_antwort) {
			this.abschnitt_id = abschnitt_antwort.abschnitt_id;
			this.antworten = abschnitt_antwort.antworten.map(antwort => FrageAntwortBuilderUtil.build(antwort));
		}
	}

	findOrCreateAntwortForFrage(frage: Frage): Antwort {
		let antwort = this.findAntwortForFrage(frage);

		if (!antwort) {
			antwort = FrageAntwortBuilderUtil.buildForFrage(frage);
			this.antworten.push(antwort);
		}

		return antwort;
	}

	private findAntwortForFrage(frage: Frage): Antwort {
		return this.antworten.filter(antwort => antwort.frage_id === frage.id)[0];
	}

	applyIds(antwortDto: AbschnittAntwortDto) {
		antwortDto.antworten.forEach((antwort, idx) => this.antworten[idx].applyIds(antwort));
	}
}
