import {NgIf} from '@angular/common';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {RouterLink} from "@angular/router";
import {AusfuellerVerwaltungDialogComponent} from '@form-viewer/ausfueller-verwaltung/dialog/AusfuellerVerwaltungDialogComponent';
/* eslint-disable-next-line max-len */
import {
	AbschnittsausfuellerVerwaltungFormConfiguration
} from '@form-viewer/ausfueller-verwaltung/form/abschnittsausfueller/AbschnittsausfuellerVerwaltungFormConfiguration';
import {AusfuellerVerwaltungFormConfiguration} from '@form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration';
import {CoAdminVerwaltungFormConfiguration} from '@form-viewer/ausfueller-verwaltung/form/co-admin/CoAdminVerwaltungFormConfiguration';
/* eslint-disable-next-line max-len */
import {
	FragebogenausfuellerVerwaltungFormConfiguration
} from '@form-viewer/ausfueller-verwaltung/form/fragebogenausfueller/FragebogenausfuellerVerwaltungFormConfiguration';
import {Abschnitt} from '@shared/model/Abschnitt';
import {Fragebogen} from '@shared/model/Fragebogen';

@Component({
	selector: "app-ausfueller-verwaltung-dialog-button",
	templateUrl: "./AusfuellerVerwaltungDialogButtonComponent.html",
	styleUrls: ["./AusfuellerVerwaltungDialogButtonComponent.less"],
	standalone: true,
	imports: [AusfuellerVerwaltungDialogComponent, NgIf, RouterLink],
})
export class AusfuellerVerwaltungDialogButtonComponent implements OnInit {
	@ViewChild("dialog", { static: true })
	dialog: AusfuellerVerwaltungDialogComponent;

	@Input()
	type: string;

	@Input()
	recherchekomplexId: string;

	@Input()
	fragebogen: Fragebogen;

	@Input()
	abschnitt: Abschnitt;

	configuration: AusfuellerVerwaltungFormConfiguration;

	constructor(private _elementRef: ElementRef) {
		this.type = this.getAttributeValueFromNativeElement(
			this._elementRef.nativeElement,
			"type"
		);
	}

	ngOnInit(): void {
		if (this.type === "CoAdmin") {
			this.configuration = new CoAdminVerwaltungFormConfiguration(
				this.recherchekomplexId
			);
		} else if (this.type === "Abschnittsausfueller") {
			this.configuration =
				new AbschnittsausfuellerVerwaltungFormConfiguration(
					this.abschnitt,
					this.fragebogen
				);
		} else {
			this.configuration =
				new FragebogenausfuellerVerwaltungFormConfiguration(
					this.fragebogen
				);
		}
	}

	onClick() {//TODO: JUREC-472/481: Dialog ganz ausbauen oder drin lassen für ausführlichere Variante
		this.dialog.show();
	}

	private getAttributeValueFromNativeElement(
		native: any,
		name: string
	): string {
		return native.getAttribute(name);
	}

	getTooltipHtml() {
		return this.configuration
			? this.configuration.getEinladenButtonToolTipHtml()
			: "";
	}
}
