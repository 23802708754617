import { InputTypeMehrfachauswahlViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-type-mehrfachauswahl-view/InputTypeMehrfachauswahlViewComponent';
import { InputTypeMehrfachauswahlEditComponent } from "@shared/components/input-type/mehrfachauswahl/InputTypeMehrfachauswahlEditComponent";
import { InputTypeMehrfachauswahlAntwort } from "@shared/model/antwort/input/InputTypeMehrfachauswahlAntwort";
import { InputType } from "@shared/model/frage/input/InputType";
import { InputTypeAuswahl } from "@shared/model/frage/input/InputTypeAuswahl";

export class InputTypeMehrfachauswahl extends InputType {
	static override readonly ID = 'InputTypeMehrfachauswahl';

	static override readonly TYPE_DISPLAY_NAME = 'Mehrfachauswahl';
	static override readonly ANTWORT = InputTypeMehrfachauswahlAntwort;

	static override readonly VIEWER = InputTypeMehrfachauswahlViewComponent;
	static override readonly EDITOR = InputTypeMehrfachauswahlEditComponent;

	options: string[] = [];

	constructor(type?: InputTypeAuswahl) {
		super(type);
		if (type) {
			this.options = type.options;
		}
	}
}
