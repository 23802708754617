import {NgFor, NgIf, NgTemplateOutlet} from "@angular/common";
import {Component, Input} from "@angular/core";
import {FormsModule} from '@angular/forms';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {SelectComponent} from "../../select/basic/SelectComponent";

/**
 * Select-Komponente mit anpassbaren Option-Templates
 *
 * <app-styled-select
 *   [(wert)]="wertVariableOderSetter"
 *   [options]="getOptions()"
 *   id="some-id"
 *   placeholder="Foobar"
 * ></app-select>
 *
 * um ein eigenes Template für den Inhalt anzugeben muss folgende Syntax verwendet werden:
 *
 * <app-styled-select …>
 *   <ng-template let-option #option>
 *     <em *ngIf="!option">nichts gewählt</em>
 *     <ng-container *ngIf="option">
 *       <strong>{{ option?.title }}</strong>
 *        <div class="badge">{{ option?.count }}</div>
 *     </ng-container>
 *   </ng-template>
 * </app-select>
 */
@Component({
	selector: "app-styled-select",
	templateUrl: "./StyledSelectComponent.html",
	styleUrls: ["./StyledSelectComponent.less"],
	standalone: true,
	imports: [FormsModule, NgFor, ScrollToFocusedElementDirective, NgIf, NgTemplateOutlet],
})
export class StyledSelectComponent extends SelectComponent {
	isOpen = false;

	@Input()
	placeholder = "";

	onWertSelected(wert: any) {
		this.wert = wert;
		this.close();
	}

	isWertSelected(wert: any) {
		return wert === this.wert;
	}

	showPlaceholder(): boolean {
		return !this.wert;
	}

	open() {
		if (this.disabled) {
			return;
		}

		if (!this.isTouch) {
			this.isOpen = true;
		}
	}

	close() {
		if (this.disabled) {
			return;
		}

		if (!this.isTouch) {
			this.isOpen = false;
		}
	}

	toggle() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	get isTouch(): boolean {
		return "ontouchstart" in window;
	}

	onKeyUp(event: KeyboardEvent) {
		switch (this.getKey(event)) {
			case "Space":
			case "Enter":
			case "NumpadEnter":
				this.toggle();
				event.stopPropagation();
				break;
			case "ArrowDown":
				if (!this.isOpen) {
					this.open();
				} else {
					this.selectNextValue();
				}
				event.stopPropagation();
				break;
			case "ArrowUp":
				if (!this.isOpen) {
					this.open();
				} else {
					this.selectPreviousValue();
				}
				event.stopPropagation();
				break;
		}
	}

	onKeyDown(event: KeyboardEvent) {
		switch (this.getKey(event)) {
			case "Space":
			case "ArrowUp":
			case "ArrowDown":
				event.preventDefault();
				event.stopPropagation();
				break;
		}
	}

	get selectedWert(): string {
		return this.wert;
	}

	protected selectNextValue(): void {
		const currentIndex = this.indexOfWert(this.selectedWert);
		this.selectWertViaKeyboard(
			this.getSelectedWertForIndex(currentIndex + 1)
		);
	}

	protected selectPreviousValue(): void {
		const currentIndex = this.indexOfWert(this.selectedWert);
		this.selectWertViaKeyboard(
			this.getSelectedWertForIndex(currentIndex - 1)
		);
	}

	protected indexOfWert(wert: string): number {
		return this.options.indexOf(wert);
	}

	protected getSelectedWertForIndex(index: number): string {
		return this.options[this.limitOptionIndex(index)];
	}

	protected limitOptionIndex(optionIndex: number): number {
		if (optionIndex >= this.numberOfSelectOptions()) {
			return this.numberOfSelectOptions() - 1;
		} else if (optionIndex < 0) {
			return 0;
		} else {
			return optionIndex;
		}
	}

	protected numberOfSelectOptions(): number {
		return this.options.length;
	}

	protected selectWertViaKeyboard(wert: string): void {
		this.wert = wert;
	}

	protected getKey(event: KeyboardEvent) {
		if (event.code) {
			// ff/chrome
			return event.code;
		}

		switch (event.key) {
			case "Spacebar": // ie
			case " ": // edge
				return "Space";
			case "Down": // ie/edge
				return "ArrowDown";
			case "Up": // ie/edge
				return "ArrowUp";
			default:
				return event.key;
		}
	}
}
