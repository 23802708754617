import { Abschnitt } from '@shared/model/Abschnitt';
import { AbschnittSperre } from '@shared/model/AbschnittSperre';
import { AbschnittStatus } from '@shared/model/AbschnittStatus';
import { AnzahlGepruefterFragenProAbschnittDto } from '@shared/model/AnzahlGepruefterFragenProAbschnittDto';
import { Fragebogen } from '@shared/model/Fragebogen';

export class FragebogenAbschnittsdaten {

	constructor(
		public fragebogen: Fragebogen,
		public abschnitte: Abschnitt[],
		public abschnittSperren: AbschnittSperre[],
		public abschnittStatus: AbschnittStatus[],
		public anzahlGepruefterFragenProAbschnittDtos: AnzahlGepruefterFragenProAbschnittDto[]
	) {
	}

	get length(): number {
		return this.abschnitte.length;
	}

	hatAbschnitte(): boolean {
		return !!this.abschnitte && this.abschnitte.length > 0;
	}

	getAnzahlGepruefterFragen(abschnitt: Abschnitt): number {
		const abschnittAntwortMetaInfoDto = this.getAbschnittAntwortMetaInfoDto(abschnitt);
		if (abschnittAntwortMetaInfoDto) {
			return abschnittAntwortMetaInfoDto.getAnzahlGepruefterAntworten();
		} else {
			return 0;
		}
	}

	private getAbschnittAntwortMetaInfoDto(abschnitt: Abschnitt): AnzahlGepruefterFragenProAbschnittDto {
		return this.anzahlGepruefterFragenProAbschnittDtos.find(
			metaInfoDto => metaInfoDto.abschnitt_id === abschnitt.id);
	}

	sindAlleFragenGeprueft(abschnitt: Abschnitt): boolean {
		return abschnitt.fragen.length > 0 &&
			this.getAnzahlGepruefterFragen(abschnitt) === abschnitt.fragen.length;
	}

	getStatus(abschnitt: Abschnitt): AbschnittStatus {
		return this.abschnittStatus.find(innerStatus => {
			return innerStatus.abschnitt_id === abschnitt.id;
		});
	}

	hatStatus(abschnitt: Abschnitt): boolean {
		return this.abschnittStatus.some(innerStatus => {
			return innerStatus.abschnitt_id === abschnitt.id;
		});
	}

	getSperren(abschnitt: Abschnitt): AbschnittSperre {
		return this.abschnittSperren.find(innerSperre => {
			return innerSperre.abschnitt_id === abschnitt.id;
		});
	}

	hatSperren(abschnitt: Abschnitt): boolean {
		return this.abschnittSperren.some(innerSperre => {
			return innerSperre.abschnitt_id === abschnitt.id;
		});
	}

	kannNochAusgefuelltWerden(): boolean {
		return this.fragebogen.kannRkNochAusgefuelltWerden();
	}
}
