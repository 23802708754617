import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { RecherchekomplexUebersichtDto } from "@benutzerverwaltung/model/RecherchekomplexUebersichtDto";
import { Fragebogen } from '@shared/model/Fragebogen';
import { AktuelleBerechtigungService } from '@shared/service/AktuelleBerechtigungService';

@Injectable({providedIn: 'root'})
export class NavigationService {

	private static readonly DEFAULT_RELOAD_TIMEOUT_MS = 1000;

	constructor(private location: Location,
				private aktuelleBerechtigungService: AktuelleBerechtigungService,
				private router: Router) {
	}

	// aka Portal
	public goToUebersicht(): void {
		this.router.navigate(['/']);
	}

	public goToEditorFragebogen(fragebogen: Fragebogen): void {
		this.router.navigate(['/editor/fragebogen/' + fragebogen.id]);
	}

	public goToEditorFragebogenAntworten(fragebogen: Fragebogen): void {
		this.router.navigate(['/editor/fragebogen/' + fragebogen.id + '/antworten']);
	}

	public goToRecherkomplex(recherchekomplex: RecherchekomplexUebersichtDto): void {
		this.router.navigate(['/benutzerverwaltung/recherchekomplexe/' + recherchekomplex.id],
			{queryParams: {recherchekomplexName: recherchekomplex.name}});
	}

	public goToFragebogenuebersicht(): void {
		this.router.navigate(['/portal'])
	}
	public goToPortalAllgemein(): void {
		this.router.navigate(['/portal-allgemein'])
	}

	public goToViewerFragebogen(fragebogen: Fragebogen) {
		this.router.navigate(['/fragebogen/' + fragebogen.id])
	}

	public goToAnchor(anchor: string) {
		window.location.hash = anchor;
	}

	public goToLoggedOutPage(): void {
		this.router.navigate(['/error/logged-out']);
	}

	public goToUnbekannterCode(): void {
		this.router.navigate(['/unbekannter-code']);
	}

}
