import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Ausfueller } from "@form-viewer/model/Ausfueller";
import { AlertService } from "@shared/alert/service/AlertService";
import { RegistrierenAntwortDto } from "../model/RegistrierenAntwortDto";

@Injectable({providedIn: 'root'})
export class RegistrierungService {

  constructor(private http: HttpClient,
			  private alertService: AlertService) { }

	getAusfuellerZuCode(code: string): Promise<Ausfueller> {
	  return this.http.get<Ausfueller>(`admin/registrieren?code=${code}`)
		  .toPromise()
		  .then(response => new Ausfueller(response))
		  .catch(this.alertService.handleHttpError);
	}

	registrieren(code: string, zweiFaktorCode: string): Promise<RegistrierenAntwortDto> {
		return this.http.post<RegistrierenAntwortDto>('admin/registrieren',  {code: code, zweiFaktorCode: zweiFaktorCode})
				   .toPromise()
				   .then(response => {return response})
				   .catch(this.alertService.handleHttpError)
	}
}
