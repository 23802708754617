import { Abschnitt } from '@shared/model/Abschnitt';
import { Frage } from '@shared/model/Frage';

export class AbschnittReihenfolgeSpeicher {

	private abschnitte_before_drag: Abschnitt[] = [];

	private fragen_before_drag: { [abschnitt_id: string]: Frage[]; } = {};

	public erstelleDragSnapshot(abschnitte: Abschnitt[]): void {
		this.abschnitte_before_drag = abschnitte.slice();
		this.abschnitte_before_drag.forEach((abschnitt: Abschnitt) => {
			this.fragen_before_drag[abschnitt.id] = abschnitt.fragen.slice();
		});
	}

	public stelleDragSnapshotWiederHer(abschnitte: Abschnitt[]): void {
		abschnitte.splice(0);
		Array.prototype.push.apply(abschnitte, this.abschnitte_before_drag);
		abschnitte.forEach((abschnitt) => abschnitt.fragen = this.fragen_before_drag[abschnitt.id]);
	}

	public vergissDragSnapshot(): void {
		this.abschnitte_before_drag = [];
		this.fragen_before_drag = {};
	}
}
