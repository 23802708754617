import { NgIf } from '@angular/common';
import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { IFrageViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/IFrageViewComponent';
import { Abschnitt } from "@shared/model/Abschnitt";
import { Antwort } from '@shared/model/Antwort';
import { Frage } from '@shared/model/Frage';
import { FrageTypes } from '@shared/model/FrageTypes';
import { ViewerExportTriggerComponent } from "./export-trigger/ViewerExportTriggerComponent";
import { ViewerImportTriggerComponent } from "./import-trigger/ViewerImportTriggerComponent";

const debug = require('debug')('ViewerFrageViewComponent');

@Component({
	selector: 'app-viewer-abschnitt-frage-view',
	templateUrl: './ViewerFrageViewComponent.html',
	styleUrls: ['./ViewerFrageViewComponent.less'],
	standalone: true,
 imports: [ViewerExportTriggerComponent, ViewerImportTriggerComponent, NgIf],
})
export class ViewerFrageViewComponent implements OnInit {
	@Input()
	frage: Frage;
	@Input()
	abschnitt: Abschnitt;

	private _antwort: Antwort;

	private _readonly: boolean;

	@ViewChild('frageViewer', { read: ViewContainerRef, static: true })
	frageViewer: ViewContainerRef;

	types: typeof Frage[];

	private component: ComponentRef<IFrageViewComponent>;

	private _printView = false;

	@Input()
	set printView(value: boolean) {
		this._printView = value;
	}

	get printView() {
		return this._printView;
	}

	private _fertig: boolean;
	get fertig() {
		return this._fertig;
	}

	private _geprueft: boolean;
	get geprueft() {
		return this._geprueft;
	}

	@Output()
	entered = new EventEmitter<any>();

	@Output()
	imported = new EventEmitter<Antwort>();

	@Output()
	fileuploadWorking = new EventEmitter<boolean>();

	constructor(private componentFactoryResolver: ComponentFactoryResolver) {
	}

	onImported(antwort: Antwort) {
		this._antwort = antwort;
		this.imported.emit(antwort);
		this.updateViewer();
	}

	enter() {
		this.entered.emit();
	}

	ngOnInit(): void {
		this.types = FrageTypes.TYPES;
		this.updateViewer();
	}

	isReadonly() {
		return this._readonly || this._fertig || this._geprueft;
	}

	@Input()
	set readonly(readonly: boolean) {
		this._readonly = readonly;
		this.updateViewerReadonlyStatus();
	}

	@Input()
	set fertig(value: boolean) {
		this._fertig = value;
		this.updateViewerReadonlyStatus();
	}

	@Input()
	set geprueft(value: boolean) {
		this._geprueft = value;
		this.updateViewerReadonlyStatus();
	}

	@Input()
	set antwort(antwort: Antwort) {
		this._antwort = antwort;
		if (this.component) {
			this.component.instance.antwort = this._antwort;
		}
	}

	onFileuploadWorking(working: boolean) {
		this.fileuploadWorking.emit(working);
	}

	get antwort(): Antwort {
		return this._antwort;
	}

	private updateViewer() {
		this.frageViewer.clear();
		if (this.frage.type) {
			const frageType: typeof Frage = FrageTypes.typeForID(this.frage.type);

			const factory = this.componentFactoryResolver.resolveComponentFactory(frageType.VIEWER);
			this.component = this.frageViewer.createComponent(factory);
			this.component.instance.frage = this.frage;
			this.component.instance.antwort = this._antwort;
			this.component.instance.readonly = this.isReadonly();
			this.component.instance.setPrintView(this._printView);
		}
	}

	private updateViewerReadonlyStatus() {
		if (this.component) {
			this.component.instance.readonly = this.isReadonly();
		}
	}
}
