import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IInputTypeEditComponent } from '@shared/components/input-type/IInputTypeEditComponent';
import { InputTypeText } from '@shared/model/frage/input/InputTypeText';
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: "app-input-type-text-edit",
	templateUrl: "./InputTypeTextEditComponent.html",
	styleUrls: ["./InputTypeTextEditComponent.less"],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule],
})
export class InputTypeTextEditComponent
	implements OnInit, IInputTypeEditComponent<InputTypeText>
{
	@Input()
	inputType: InputTypeText;

	platzhalterText = "";

	getEditorTitel(): string {
		return "Optionen";
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
	}
}
