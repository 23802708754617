import { AntwortZelle } from "@shared/model/antwort/tabellenfrage/AntwortZelle";
import { TabellenSpalte } from "@shared/model/frage/TabellenSpalte";
import { ClientIdAware } from "@shared/util/ClientIdAware";
import { ClientIdUtil } from "@shared/util/ClientIdUtil";
import { UUID } from '@util/export-types';

export class AntwortZeile implements ClientIdAware {
	id: UUID;
	clientId: UUID;
	zellen: AntwortZelle[] = [];
	index: number;

	static erzeugeLeereAntwortZellen(spalten: TabellenSpalte[]): AntwortZelle[] {
		const neueZellen: AntwortZelle[] = [];

		for (const spalte of spalten) {
			neueZellen.push(AntwortZelle.erzeugeLeereAntwortZelle(spalte.type));
		}

		return neueZellen;
	}

	constructor(zeile?: AntwortZeile) {
		if (zeile) {
			this.constructFromZeile(zeile);
		}

		ClientIdUtil.generateClientIdIfMissing(this);
	}

	private constructFromZeile(antwort: AntwortZeile) {
		this.id = antwort.id;
		this.clientId = antwort.clientId;

		this.zellen = [];
		if (antwort.zellen) {
			this.zellen = antwort.zellen.map(zelle => new AntwortZelle(zelle));
		}
	}

	isEmpty(): boolean {
		return this.zellen.filter(zelle => !zelle.antwort.isEmpty()).length === 0;
	}

	addZelle(): AntwortZelle {
		const antwortZelle = new AntwortZelle();
		this.zellen.push(antwortZelle);
		return antwortZelle;
	}

	applyIds(zeile: AntwortZeile) {
		this.id = zeile.id;

		zeile.zellen.forEach((zelle, idx) =>
			this.zellen[idx].applyIds(zelle));
	}

	istInhaltlichGleich(o: AntwortZeile) {
		if (!o || this.zellen.length !== o.zellen.length) {
			return false;
		}

		for (let i = 0; i < this.zellen.length; i++) {
			if (!this.zellen[i].istInhaltlichGleich(o.zellen[i])) {
				return false;
			}
		}

		return true;
	}

	isValid() {
		return this.zellen.every(z => z.isValid());
	}
}
