import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: "app-contact",
	templateUrl: "./ContactComponent.html",
	styleUrls: ["./contacts.less"],
	standalone: true,
	imports: [NgIf]
})
export class ContactComponent implements OnInit {
	@Input() bluePanel: boolean;
	@Input() person: string;
	fullName: string;
	jobtitle: string;
	zustaendigkeit: string;
	imgName: string;
	phoneNumber: string;
	emailAddress: string;

	ngOnInit(): void {
		switch (this.person) {
			case "scherer":
				this.fullName = "Claudia Scherer";
				this.jobtitle = "Koordinatorin Kanzleiinformationen";
				this.zustaendigkeit =
					"JUVE Handbuch Wirtschaftskanzleien und Kennzahlen";
				this.imgName = "contact-scherer.jpg";
				this.phoneNumber = "+49 / (0)221 / 913 880-10";
				this.emailAddress = "Claudia.Scherer@juve.de";
				break;
			case "mecke":
				this.fullName = "Dr. Bettina-Dorothee Mecke";
				this.jobtitle = "Koordinatorin Kanzleiinformationen";
				this.zustaendigkeit =
					"JUVE Steuermarkt und Top-Arbeitgeber im Steuermarkt";
				this.imgName = "contact-mecke.jpg";
				this.phoneNumber = "+49 / (0)221 / 913 880-711";
				this.emailAddress = "Bettina-Dorothee.Mecke@juve.de";
				break;
			case "thum":
				this.fullName = "Nicola Thum";
				this.jobtitle = "Koordinatorin Arbeitgeberrecherche azur100";
				this.imgName = "contact-thum.jpg";
				this.phoneNumber = "+49 / (0)221 / 913 880-534";
				this.emailAddress = "Nicola.Thum@juve.de";
				break;
			case "ossen":
				this.fullName = "Julia Ossen";
				this.jobtitle = "Koordinatorin Karriereinformationen azur";
				this.imgName = "contact-ossen.jpg";
				this.phoneNumber = "+49 / (0)221 / 913 880-49";
				this.emailAddress = "Julia.Ossen@juve.de";
				break;
			default:
				this.fullName = "JUVE Recherche Team";
				this.jobtitle = "Allgemeiner Kundensupport";
				this.imgName = "contact-juve.jpg";
				this.phoneNumber = "+49 / (0)221 / 913 880-0";
				this.emailAddress = "recherche@juve.de";
				break;
		}
	}
}
