import { SafeHtml } from "@angular/platform-browser";
import { InputTypeAntwort } from "@shared/model/antwort/input/InputTypeAntwort";
import { InputTypeText } from "@shared/model/frage/input/InputTypeText";

export class InputTypeTextAntwort extends InputTypeAntwort<InputTypeText> {
	wert = '';

	constructor(antwort?: InputTypeTextAntwort) {
		super(antwort);

		if (antwort) {
			this.wert = antwort.wert;
		}
	}

	getType() {
		return InputTypeText.ID;
	}

	isEmpty(): boolean {
		return !this.wert || this.wert.length === 0;
	}

	getHtml(): SafeHtml {
		return this.wert.trim();
	}

	istInhaltlichGleich(o: InputTypeAntwort<any>): boolean {
		return this.wert.trim() === (o as InputTypeTextAntwort).wert.trim();
	}

}
