import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SichtbarerRecherchekomplexDto } from '@portal-selector/model/SichtbarerRecherchekomplexDto';
import { PortalRecherchekomplexEintragComponent } from '@portal-selector/portal-recherchekomplex-eintrag/PortalRecherchekomplexEintragComponent';
import { Institution } from '@shared/model/Institution';
import { ToastModule } from 'primeng/toast';

@Component({
	selector: "app-portal-recherchekomplex-selector",
	templateUrl: "./PortalRecherchekomplexSelectorComponent.html",
	styleUrls: ["./PortalRecherchekomplexSelectorComponent.less"],
	standalone: true,
	imports: [
		ToastModule,
		NgIf,
		PortalRecherchekomplexEintragComponent,
		NgFor
	]
})
export class PortalRecherchekomplexSelectorComponent {
	@Input()
	institution: Institution;

	@Input()
	recherchekomplexDtos: Array<SichtbarerRecherchekomplexDto> = [];
}
