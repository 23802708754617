import { EventEmitter, Injectable } from '@angular/core';
const debug = require('debug')('service:EditLockService');

@Injectable({providedIn: 'root'})
export class EditLockService {
	locked = new EventEmitter();
	unlocked = new EventEmitter();
	changed = new EventEmitter();

	private lockedForComponent: any = null;

	isLocked(): boolean {
		return this.lockedForComponent !== null;
	}

	isLockedFor(component: any): boolean {
		return this.lockedForComponent === component;
	}

	isLockedButNotFor(component: any): boolean {
		return this.isLocked() && !this.isLockedFor(component);
	}

	lockFor(component: any): boolean {
		if (this.isLocked()) {
			debug('rejecting lock for', component, 'because already locked for', this.lockedForComponent);
			return false;
		}

		debug('granting lock for', component);
		this.lockedForComponent = component;
		this.locked.emit();
		this.changed.emit();
		return true;
	}

	unlock() {
		debug('unlocking from', this.lockedForComponent);
		this.lockedForComponent = null;
		this.unlocked.emit();
		this.changed.emit();
	}

	unlockIfLockedFor(component: any) {
		if (this.isLockedFor(component)) {
			this.unlock();
		}
	}
}
