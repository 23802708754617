import { DatePipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FrageGeprueftMarker } from '@form-viewer/model/FrageGeprueftMarker';
// Import BlockUI decorator & optional NgBlockUI type
import { AbschnittSaveEventServiceService } from "@form-viewer/service/AbschnittSaveEventServiceService";
import { AlertService } from "@shared/alert/service/AlertService";
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
	selector: "app-viewer-antwort-als-geprueft-markieren",
	templateUrl: "./ViewerAntwortAlsGeprueftMarkierenComponent.html",
	styleUrls: ["./ViewerAntwortAlsGeprueftMarkierenComponent.less"],
	standalone: true,
	imports:[NgIf, DatePipe]
})
export class ViewerAntwortAlsGeprueftMarkierenComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;
	@Input()
	frageGeprueftMarker: FrageGeprueftMarker;

	@Output()
	alsGeprueftMarkiert = new EventEmitter<void>();

	@Output()
	alsUngeprueftMarkiert = new EventEmitter<void>();

	@Input()
	readonly = false;

	private saving: boolean;

	constructor(
		private alertService: AlertService,
		private abschnSaveSvc: AbschnittSaveEventServiceService
	) {}

	ngOnInit() {
		this.abschnSaveSvc.savingEventListner().subscribe((info) => {
			// console.log(info); // here you get the message from Child component
			this.saving = JSON.parse(String(info));
		});
	}

	isSaving() {
		console.log("isSaving ... " + this.saving);
		return this.saving;
	}

	istAlsGeprueftMarkiert() {
		return this.frageGeprueftMarker != null;
	}

	alsGeprueftMarkieren() {
		this.alsGeprueftMarkiert.emit();
	}

	alsUngeprueftMarkieren() {
		this.alsUngeprueftMarkiert.emit();
	}

	toggleGeprueft() {
		// console.log("toggleGeprueft");
		this.blockUI.start("Daten werden verarbeitet...");
		if (this.isSaving()) {
			this.blockUI.stop();
			this.alertService.clearAlerts();
			this.alertService.showDanger(
				"Fehler beim Ausführen der Aktion! \nWährend dem Speichern kann der Status nicht verändert werden. \nBitte versuchen Sie es erneut!"
			);
		} else {
			try {
				if (this.istAlsGeprueftMarkiert()) {
					this.alsUngeprueftMarkieren();
				} else {
					this.alsGeprueftMarkieren();
				}
			} finally {
				// setTimeout(() => {this.blockUI.stop();}, 20000);
				this.blockUI.stop();
			}
		}
	}

	istVersteckt() {
		return this.readonly && !this.istAlsGeprueftMarkiert();
	}
}
