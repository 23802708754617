import {NgFor, NgTemplateOutlet} from '@angular/common';
import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ScrollToFocusedElementDirective} from '@form-viewer/util/ScrollToFocusedElementDirective';
import {GlobalId} from '@util/GlobalId';

/**
 * Select-Komponente mit anpassbaren Option-Templates
 *
 * <app-select
 *   [(wert)]="wertVariableOderSetter"
 *   [options]="getOptions()"
 *   id="some-id"
 * ></app-select>
 *
 * um ein eigenes Template für den Inhalt anzugeben muss folgende Syntax verwendet werden:
 *
 * <app-select …>
 *   <ng-template let-option #option>
 *     Spezial: {{ option?.foobar }}!!
 *   </ng-template>
 * </app-select>
 */
@Component({
	selector: "app-select",
	templateUrl: "./SelectComponent.html",
	standalone: true,
	imports: [FormsModule, NgFor, ScrollToFocusedElementDirective, NgTemplateOutlet],
})
export class SelectComponent {
	@Input()
	public options: any[] = [];

	@Output()
	wertChange = new EventEmitter<any>();

	@Input()
	disabled = false;

	@Input()
	id: string = "select-" + GlobalId.next();

	@ContentChild("option")
	customOptionTemplate: TemplateRef<any>;

	@ViewChild("defaultOptionTemplate", { static: true })
	defaultOptionTemplate: TemplateRef<any>;

	protected _wert: any = null;

	get wert(): any {
		return this._wert;
	}

	@Input()
	set wert(wert: any) {
		if (this._wert !== wert) {
			this._wert = wert;
			this.wertChanged();
		}
	}

	get optionTemplate(): TemplateRef<any> {
		return this.customOptionTemplate || this.defaultOptionTemplate;
	}

	wertChanged() {
		this.wertChange.emit(this.wert);
	}
}
