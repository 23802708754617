import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ausfueller } from '@form-viewer/model/Ausfueller';

import { NgFor, NgIf } from '@angular/common';
import { FrageFertigMarker } from '@form-viewer/model/FrageFertigMarker';
import { FrageGeprueftMarker } from '@form-viewer/model/FrageGeprueftMarker';
import { AusfuellerBerechtigungsService } from '@form-viewer/service/AusfuellerBerechtigungsService';
import { PreviewService } from '@form-viewer/service/PreviewService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { AbschnittAntwortDto } from '@shared/model/AbschnittAntwortDto';
import { Frage } from '@shared/model/Frage';
import * as _ from 'underscore';
import { AbschnittSaveEventServiceService } from "../../service/AbschnittSaveEventServiceService";
import { ViewerAntwortAlsFertigMarkierenComponent } from "./antwort-als-fertig-markieren/ViewerAntwortAlsFertigMarkierenComponent";
import { ViewerAntwortAlsFertigMarkiertListeComponent } from "./antwort-als-fertig-markiert-liste/ViewerAntwortAlsFertigMarkiertListeComponent";
import { ViewerAntwortAlsGeprueftMarkierenComponent } from "./antwort-als-geprueft-markieren/ViewerAntwortAlsGeprueftMarkierenComponent";
import { ViewerFrageComponent } from "./frage/ViewerFrageComponent";

@Component({
	selector: "app-viewer-abschnitt-frage-liste",
	templateUrl: "./ViewerFrageListeComponent.html",
	styleUrls: ["./ViewerFrageListeComponent.less"],
	standalone: true,
 imports: [NgIf, NgFor,ViewerFrageComponent, ViewerAntwortAlsFertigMarkiertListeComponent, ViewerAntwortAlsGeprueftMarkierenComponent, ViewerAntwortAlsFertigMarkierenComponent],
})
export class ViewerFrageListeComponent implements OnInit {
	@Input()
	abschnitt: Abschnitt;

	@Input()
	frageFertigMarker: FrageFertigMarker[];

	@Input()
	frageGeprueftMarker: FrageGeprueftMarker[];

	@Input()
	meineFragenFertigMarker: FrageFertigMarker[];

	@Input()
	ausfueller: Ausfueller[];

	@Input()
	antwort: AbschnittAntwortDto;

	@Input()
	readonly: boolean;

	@Output()
	frageEntered = new EventEmitter<Frage>();

	@Output()
	alsFertigMarkiert = new EventEmitter<Frage>();

	@Output()
	alsUnfertigMarkiert = new EventEmitter<Frage>();

	@Output()
	alsUngeprueftMarkiert = new EventEmitter<Frage>();

	@Output()
	alsGeprueftMarkiert = new EventEmitter<Frage>();

	@Output()
	imported = new EventEmitter<any>();

	@Output()
	fileuploadWorking = new EventEmitter<boolean>();

	_printView = false;

	@Input()
	set printView(value: boolean) {
		this._printView = value;
	}

	get printView() {
		return this._printView;
	}

	varIsReadonly;
	varIsPrintView;
	varDarfAlsGeprueftMarkieren;
	varDarfFrageFertigMarkerSehen;
	varDarfFrageAlsFertigMarkieren;

	varIsFertig = {};
	varIsGeprueft = {};
	varAntwortForFrage = {};
	varFilterFertigMarkerForFrage = {};
	varFilterUnfertigeAusfuellerForFrage = {};
	varFilterGeprueftMarkerForFrage = {};
	varMeinFertigMarkerForFrage = {};

	private saving: boolean;

	constructor(
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
		private previewService: PreviewService,
		private abschnSaveSvc: AbschnittSaveEventServiceService
	) {}

	ngOnInit() {
		this.varIsReadonly = this.isReadonly();
		this.varIsPrintView = this.isPrintView();
		this.varDarfAlsGeprueftMarkieren = this.darfAlsGeprueftMarkieren();
		this.varDarfFrageFertigMarkerSehen = this.darfFrageFertigMarkerSehen();
		this.varDarfFrageAlsFertigMarkieren =
			this.darfFrageAlsFertigMarkieren();

		for (const frage of this.abschnitt.fragen) {
			this.varIsFertig[frage.id] = this.isFertig(frage);
			this.varIsGeprueft[frage.id] = this.isGeprueft(frage);
			this.varAntwortForFrage[frage.id] = this.antwortForFrage(frage);
			this.varFilterFertigMarkerForFrage[frage.id] =
				this.filterFertigMarkerForFrage(frage);
			this.varFilterUnfertigeAusfuellerForFrage[frage.id] =
				this.filterUnfertigeAusfuellerForFrage(frage);
			this.varFilterGeprueftMarkerForFrage[frage.id] =
				this.filterGeprueftMarkerForFrage(frage);
			this.varMeinFertigMarkerForFrage[frage.id] =
				this.meinFertigMarkerForFrage(frage);
		}

		this.abschnSaveSvc.savingEventListner().subscribe((info) => {
			// console.log(info); // here you get the message from Child component
			this.saving = JSON.parse(String(info));
		});
	}

	darfFrageAlsFertigMarkieren() {
		return (
			this.previewService.isNotInPreview() &&
			this.ausfuellerBerechtigungsService.darfFrageAlsFertigMarkierenFuerAbschnitt(
				this.abschnitt
			)
		);
	}

	isSaving() {
		console.log("isSaving ... " + this.saving);
		return this.saving;
	}

	darfFrageFertigMarkerSehen() {
		return (
			this.previewService.isNotInPreview() &&
			this.ausfuellerBerechtigungsService.darfFrageFertigMarkerSehen()
		);
	}

	isReadonly() {
		return this.readonly;
	}

	isPrintView() {
		return this._printView;
	}

	antwortForFrage(frage: Frage) {
		return this.antwort.findOrCreateAntwortForFrage(frage);
	}

	filterFertigMarkerForFrage(frage: Frage): FrageFertigMarker[] {
		return this.frageFertigMarker.filter(
			(marker) => marker.frage_id === frage.id
		);
	}

	meinFertigMarkerForFrage(frage: Frage): FrageFertigMarker {
		return this.meineFragenFertigMarker.find(
			(marker) => marker.frage_id === frage.id
		);
	}

	filterUnfertigeAusfuellerForFrage(frage: Frage): Ausfueller[] {
		const fertigeAusfuellerIds = this.filterFertigMarkerForFrage(frage).map(
			(fertigMarker) => fertigMarker.ausfueller.id
		);

		return this.ausfueller.filter(
			(ausfueller) => !_.includes(fertigeAusfuellerIds, ausfueller.id)
		);
	}

	onFrageEntered(frage: Frage) {
		this.frageEntered.emit(frage);
	}

	onImported() {
		this.imported.emit();
	}

	onAlsFertigMarkiert(frage: Frage) {
		this.alsFertigMarkiert.emit(frage);
	}

	onAlsUnfertigMarkiert(frage: Frage) {
		this.alsUnfertigMarkiert.emit(frage);
	}

	isFertig(frage: Frage): boolean {
		return this.meineFragenFertigMarker.some(
			(marker) => marker.frage_id === frage.id
		);
	}

	isGeprueft(frage: Frage): boolean {
		return this.frageGeprueftMarker.some(
			(marker) => marker.frage_id === frage.id
		);
	}

	darfAlsGeprueftMarkieren() {
		return (
			this.previewService.isNotInPreview() &&
			this.ausfuellerBerechtigungsService.darfAlsGeprueftMarkieren()
		);
	}

	filterGeprueftMarkerForFrage(frage: Frage): FrageGeprueftMarker {
		return this.frageGeprueftMarker.find(
			(marker) => marker.frage_id === frage.id
		);
	}

	onAlsGeprueftMarkiert(frage: Frage) {
		this.alsGeprueftMarkiert.emit(frage);
	}

	onAlsUngeprueftMarkiert(frage: Frage) {
		this.alsUngeprueftMarkiert.emit(frage);
	}

	onFileuploadWorking(working: boolean) {
		this.fileuploadWorking.emit(working);
	}
}
