import { TextfeldFrageEditComponent } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/textfeld/TextfeldFrageEditComponent';
import { TextfeldFrageViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/textfeld/TextfeldFrageViewComponent';
import { TextfeldfragenAntwort } from "@shared/model/antwort/TextfeldfragenAntwort";
import { Frage } from '../Frage';

export class TextfeldFrage extends Frage {
	static readonly TYPE_DISPLAY_NAME = 'Text';
	static readonly TYPE_ICON_NAME = 'text-t';
	static override readonly ID = 'TextfeldFrage';

	static override readonly EDITOR = TextfeldFrageEditComponent;
	static override readonly VIEWER = TextfeldFrageViewComponent;

	platzhalterText = '';
	grossesTextfeldAktiviert = false;

	constructor(frage?: TextfeldFrage) {
		super(frage);

		if (frage) {
			this.constructFromTextfeldFrage(frage);
		}
	}

	private constructFromTextfeldFrage(frage: TextfeldFrage) {
		this.platzhalterText = frage.platzhalterText;
		this.grossesTextfeldAktiviert = frage.grossesTextfeldAktiviert;

		this.aeltesteAntwort = frage.aeltesteAntwort ? new TextfeldfragenAntwort(frage.aeltesteAntwort as TextfeldfragenAntwort) : null;
		this.neuesteAntwort = frage.neuesteAntwort ? new TextfeldfragenAntwort(frage.neuesteAntwort as TextfeldfragenAntwort) : null;
	}

	override typeIconName() {
		return TextfeldFrage.TYPE_ICON_NAME;
	}

	override typeDisplayName() {
		return TextfeldFrage.TYPE_DISPLAY_NAME;
	}

	override isExportableAsCsv() {
		return false;
	}
}
