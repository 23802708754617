import {NgIf} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Abschnitt} from '@shared/model/Abschnitt';
import {LastIdPipe} from "@util/LastIdPipe";
import {NewIdPipe} from "@util/NewIdPipe";
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";
import {PreventSubmitOnEnterDirective} from "@util/PreventSubmitOnEnterDirective";

@Component({
	selector: "app-editor-abschnitt-edit",
	templateUrl: "./AbschnittEditComponent.html",
	styleUrls: ["./AbschnittEditComponent.less"],
	standalone: true,
	imports: [NgIf, NewIdPipe, LastIdPipe, FormsModule, PreventSubmitOnEnterDirective, PreventDefaultDirective]
})
export class AbschnittEditComponent {
	@Input()
	abschnitt: Abschnitt;

	@Output()
	cancel = new EventEmitter();

	@Output()
	commit = new EventEmitter();

	@Output()
	remove = new EventEmitter();

	constructor() {}

	isNew() {
		return !this.abschnitt.id;
	}

	commitEdit() {
		this.commit.emit();
	}

	cancelEdit() {
		this.cancel.emit();
	}

	commitRemove() {
		this.remove.emit();
	}
}
