import { InputTypeTextareaViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-type-textarea-view/InputTypeTextareaViewComponent';
import { InputTypeTextareaEditComponent } from "@shared/components/input-type/textarea/InputTypeTextareaEditComponent";
import { InputTypeTextareaAntwort } from "@shared/model/antwort/input/InputTypeTextareaAntwort";
import { InputType } from "@shared/model/frage/input/InputType";

export class InputTypeTextarea extends InputType {
	static override readonly ID = 'InputTypeTextarea';
	static override readonly TYPE_DISPLAY_NAME = 'Großes Text'; // …-Feld
	static override readonly ANTWORT = InputTypeTextareaAntwort;

	static override readonly VIEWER = InputTypeTextareaViewComponent;
	static override readonly EDITOR = InputTypeTextareaEditComponent;

	platzhalterText = '';

	constructor(type?: InputTypeTextarea) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
		}
	}
}
