import { InputTypeKommazahlViewComponent } from "@form-viewer/abschnitt/frage-liste/frage/view/input/input-type-kommazahl-view/InputTypeKommazahlViewComponent";
import { InputTypeKommazahlEditComponent } from "@shared/components/input-type/kommazahl/InputTypeKommazahlEditComponent";
import { InputTypeKommazahlAntwort } from "@shared/model/antwort/input/InputTypeKommazahlAntwort";
import { InputType } from "@shared/model/frage/input/InputType";

export class InputTypeKommazahl extends InputType {
	static override readonly ID = 'InputTypeKommazahl';
	static override readonly TYPE_DISPLAY_NAME = 'Kommazahl';
	static override readonly ANTWORT = InputTypeKommazahlAntwort;

	// add to @NgModule.entryComponents in app.module.ts
	static override readonly VIEWER = InputTypeKommazahlViewComponent;
	static override readonly EDITOR = InputTypeKommazahlEditComponent;

	platzhalterText = '';
	vorkommastellen = 0;
	nachkommastellen = 2;

	constructor(type?: InputTypeKommazahl) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
			this.vorkommastellen = type.vorkommastellen;
			this.nachkommastellen = type.nachkommastellen;
		}
	}

}
