import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IFrageEditComponent } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';
import { EntscheidungsFrage } from '@shared/model/frage/EntscheidungsFrage';
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: "app-editor-abschnitt-frage-edit-entscheidung",
	templateUrl: "./EntscheidungsFrageEditComponent.html",
	styleUrls: ["./EntscheidungsFrageEditComponent.less"],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule, NgIf],
})
export class EntscheidungsFrageEditComponent
	implements IFrageEditComponent<EntscheidungsFrage>
{
	@Input()
	frage: EntscheidungsFrage;
}
