import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: "app-editor-fragebogen-metadata-form",
	templateUrl: "./FragebogenMetadataFormComponent.html",
	styleUrls: ["./FragebogenMetadataFormComponent.less"],
	standalone: true,
 	imports: [NewIdPipe, LastIdPipe, FormsModule, NgFor],
})
export class FragebogenMetadataFormComponent {
	@Input()
	submitButtonNames: string[];

	@Input()
	disabled: boolean = false; //TODO CHECK, Type von False auf Boolean geändert

	@Output()
	nameChange = new EventEmitter<string>();

	@Output()
	beschreibungKurzChange = new EventEmitter<string>();

	@Output()
	beschreibungLangChange = new EventEmitter<string>();

	@Output()
	commitSave = new EventEmitter<number>();

	@Output()
	abbrechen = new EventEmitter<void>();

	_name: string;

	get name() {
		return this._name;
	}

	@Input()
	set name(name: string) {
		this._name = name;
		this.nameChange.emit(name);
	}

	_beschreibungKurz: string;

	get beschreibungKurz(): string {
		return this._beschreibungKurz;
	}

	@Input()
	set beschreibungKurz(beschreibungKurz: string) {
		this._beschreibungKurz = beschreibungKurz;
		this.beschreibungKurzChange.emit(beschreibungKurz);
	}

	_beschreibungLang: string;

	get beschreibungLang(): string {
		return this._beschreibungLang;
	}

	@Input()
	set beschreibungLang(beschreibungLang: string) {
		this._beschreibungLang = beschreibungLang;
		this.beschreibungLangChange.emit(beschreibungLang);
	}
}
