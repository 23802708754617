import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from '@form-viewer/service/UserService';
import { PortalSelectorComponent } from '@portal-selector/PortalSelectorComponent';
import { ContactComponent } from '@shared/components/contact/ContactComponent';
import { FooterComponent } from '@shared/components/footer/FooterComponent';
import { HeaderComponent } from '@shared/components/header/HeaderComponent';
import { SpinnerComponent } from '@shared/components/spinner/SpinnerComponent';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';

@Component({
	selector: "app-archiv",
	templateUrl: "./ArchivComponent.html",
	styleUrls: [
		"../content-page.less",
		"../../../website-styles/content-page/_portal-meta.less",
	],
	standalone: true,
	imports: [HeaderComponent, PortalSelectorComponent, SpinnerComponent, ContactComponent, FooterComponent]
})
export class ArchivComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	constructor(
		private title: Title,
		private messageService: MessageService,
		private userService: UserService
	) {
		title.setTitle("Archiv - Juve Recherche");
	}

	ngOnInit(): void {
		try {
			this.messageService.clear();
			this.userService.isAlive();
		} finally {
			setTimeout(() => {
				try {
					this.blockUI.resetGlobal();
				} catch (exception) {}
			}, 1000);
		}
	}
}
