import { KartenFrageEditComponent } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/KartenFrageEditComponent';
import { KartenFrageViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/karte/KartenFrageViewComponent';
import { KartenfragenAntwort } from "@shared/model/antwort/KartenfragenAntwort";
import { Frage } from "@shared/model/Frage";
import { KartenFeld } from "@shared/model/frage/KartenFeld";
import { ArrayUtil } from '@shared/util/ArrayUtil';
import { removeElement } from '@util/removeElement';

const debug = require('debug')('KartenFrage');

export class KartenFrage extends Frage {
	static readonly TYPE_DISPLAY_NAME = 'Karten';
	static readonly TYPE_ICON_NAME = 'card';
	static override readonly ID = 'KartenFrage';

	static override readonly EDITOR = KartenFrageEditComponent;
	static override readonly VIEWER = KartenFrageViewComponent;

	felderLinks: KartenFeld[] = [];
	felderRechts: KartenFeld[] = [];

	titel = '';
	titelPlatzhalterText = '';
	titelBeschreibung = '';

	constructor(frage?: KartenFrage) {
		super(frage);

		if (frage) {
			this.constructFromKartenFrage(frage);
		} else {
			this.constructEmpty();
		}
	}

	private constructFromKartenFrage(frage: KartenFrage) {
		this.felderLinks = [];
		if (frage.felderLinks) {
			this.felderLinks = frage.felderLinks.map(feld => new KartenFeld(feld));
		}

		this.felderRechts = [];
		if (frage.felderRechts) {
			this.felderRechts = frage.felderRechts.map(feld => new KartenFeld(feld));
		}

		this.titel = frage.titel;
		this.titelPlatzhalterText = frage.titelPlatzhalterText;
		this.titelBeschreibung = frage.titelBeschreibung;

		this.aeltesteAntwort = frage.aeltesteAntwort ? new KartenfragenAntwort(frage.aeltesteAntwort as KartenfragenAntwort) : null;
		this.neuesteAntwort = frage.neuesteAntwort ? new KartenfragenAntwort(frage.neuesteAntwort as KartenfragenAntwort) : null;
	}

	private constructEmpty() {
		this.felderLinks = [];
		this.felderRechts = [];
	}

	override clearIds() {
		super.clearIds();
		this.felderLinks.forEach(feld => {
			feld.clearIds();
		});
		this.felderRechts.forEach(feld => {
			feld.clearIds();
		});
	}

	override typeIconName() {
		return KartenFrage.TYPE_ICON_NAME;
	}

	override typeDisplayName() {
		return KartenFrage.TYPE_DISPLAY_NAME;
	}

	hasFelder() {
		return this.felderLinks.length > 0 || this.felderRechts.length > 0;
	}

	addFeldLinks() {
		this.felderLinks.push(new KartenFeld());
	}

	removeFeldLinks(feld: KartenFeld) {
		removeElement(this.felderLinks, feld);
	}

	addFeldRechts() {
		this.felderRechts.push(new KartenFeld());
	}

	removeFeldRechts(feld: KartenFeld) {
		removeElement(this.felderRechts, feld);
	}

	isFeldLinks(feld: KartenFeld) {
		return ArrayUtil.contains(this.felderLinks, feld);
	}

	isFeldRechts(feld: KartenFeld) {
		return ArrayUtil.contains(this.felderRechts, feld);
	}

	felder(): KartenFeld[] {
		return this.felderLinks.concat(this.felderRechts);
	}

	override isExportableAsCsv() {
		return true;
	}
}
