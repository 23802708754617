import { UUID } from '@util/export-types';

export class FixeZeile {
	id: string;
	titel = '';
	frage_id: UUID;

	constructor(zeile?: FixeZeile) {
		if (zeile) {
			this.constructFromZeile(zeile);
		}
	}

	private constructFromZeile(zeile: FixeZeile) {
		this.id = zeile.id;
		this.titel = zeile.titel;
		this.frage_id = zeile.frage_id;
	}

	clearIds() {
		this.id = null;
		this.frage_id = null;
	}
}
