import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Abschnitt } from '@shared/model/Abschnitt';
import { PreventDefaultDirective } from '@util/PreventDefaultDirective';
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { AbschnittSortierenComponent } from './abschnitt-sortieren-component/AbschnittSortierenComponent';

@Component({
	selector: "app-abschnitt-sortieren-dialog",
	templateUrl: "./AbschnittSortierenDialogComponent.html",
	styleUrls: ["./AbschnittSortierenDialogComponent.less"],
	standalone: true,
	imports: [AbschnittSortierenComponent, PreventDefaultDirective, ModalModule],
})
export class AbschnittSortierenDialogComponent {
	@ViewChild("sortDialogModal", { static: true })
	sortDialogModal: ModalDirective;

	@Input()
	abschnitte: Abschnitt[];

	@Output()
	abschnitteChange = new EventEmitter<Abschnitt[]>();

	editAbschnitte: Abschnitt[] = [];

	disabled = false;

	show() {
		this.copyAbchnittsListe();

		this.sortDialogModal.show();
	}

	private copyAbchnittsListe() {
		this.editAbschnitte = this.abschnitte.slice(0);
	}

	hide() {
		this.sortDialogModal.hide();
		this.disabled = false;
	}

	onCommitSave() {
		this.disabled = true;
		this.abschnitteChange.emit(this.editAbschnitte);
	}

	onCancelSave() {
		this.hide();
	}

	enableAndHide() {
		this.disabled = false;
		this.hide();
	}
}
