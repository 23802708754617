import {NgClass, NgIf} from '@angular/common';
import {Component, Input, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IInputTypeViewComponent} from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {InputTypeTelefonAntwort} from "@shared/model/antwort/input/InputTypeTelefonAntwort";
import {InputTypeTelefon} from "@shared/model/frage/input/InputTypeTelefon";

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Vollflächiges Eingabefeld ohne Rand zum Einbinden in Tabellenzellen
 */
@Component({
	selector: "app-input-type-telefon-view",
	templateUrl: "./InputTypeTelefonViewComponent.html",
	styleUrls: ["./InputTypeTelefonViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgClass, FormsModule, ScrollToFocusedElementDirective],
})
export class InputTypeTelefonViewComponent
	implements IInputTypeViewComponent<InputTypeTelefon>
{
	context = {};

	variant: string;

	@ViewChild("tooltip") tooltip;

	@Input()
	antwort: InputTypeTelefonAntwort;

	@Input()
	type: InputTypeTelefon;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	isValid() {
		return this.antwort.isValid();
	}

	getPlatzhalterText(): string {
		return this.type.platzhalterText;
	}

	getRegex(): string {
		return "^(\\(?([\\d \\-\\)\\–\\+\\/\\(]+)\\)?([ .\\-–\\/]?)([\\d]+))$";
	}

	variantClass(): string {
		return this.variant;
	}
}
