import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { InputViewComponent } from "@form-viewer/abschnitt/frage-liste/frage/view/input/input-view/InputViewComponent";
import { KartenFeldAntwortTupel } from '@form-viewer/abschnitt/frage-liste/frage/view/karte/karten-view/KartenFeldAntwortTupel';
import { InfoTextViewComponent } from "@form-viewer/shared/info-text/InfoTextViewComponent";
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: 'app-karten-view-spalte',
	templateUrl: './KartenViewSpalteComponent.html',
	styleUrls: ['./KartenViewSpalteComponent.less'],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, InfoTextViewComponent, InputViewComponent, NgFor],
})
export class KartenViewSpalteComponent {
	@Input()
	feldAntworten: KartenFeldAntwortTupel[];

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}
}
