import { DateFormatterUtil } from '@shared/util/DateFormatterUtil';

export class KopieSubstringBuilder {

	static buildKopieSubstringFor(date: Date) {
		return '(Kopie vom '
			+ DateFormatterUtil.transformToDateOnly(date) + ' um '
			+ DateFormatterUtil.transformToTimeOnly(date) + ')';

	}
}
