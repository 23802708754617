import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";
import { UserService } from "@form-viewer/service/UserService";
import { select, Store } from "@ngrx/store";
import { Recherchekomplex } from "@shared/model/Recherchekomplex";
import { selectRecherchekomplex } from "@store/selector/recherchekomplex.selectors";

@Component({
	selector: 'app-footer',
	templateUrl: './FooterComponent.html',
	styleUrls: ['footer.less'],
	standalone: true,
	imports: [NgIf]
})
export class FooterComponent implements OnInit {
	@Input() style: string
	@Input() recherchekomplex: Recherchekomplex
	themaLink: SafeHtml
	public currentUser: string;


	constructor(private store: Store, private userService: UserService) {
	}

	ngOnInit(): void {
		this.store.pipe(select(selectRecherchekomplex)).subscribe(value => this.recherchekomplex = value)
		if (this.recherchekomplex &&
			(this.recherchekomplex.name.toLowerCase().includes('handbuch-steuern') || this.recherchekomplex.name.toLowerCase().includes('steuermarkt'))) {
			this.themaLink = '<a href="http://www.juve-steuermarkt.de/" target="_blank">Juve Steuermarkt</a>'
		}
	}
}
