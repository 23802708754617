import { Injectable } from '@angular/core';
import * as _ from 'underscore';

@Injectable({providedIn: 'root'})
export class QueryParamsService {
	private lastParsedQueryString: string;
	private lastParsedQueryParams: object;

	private parseQueryParams(): object {
		const queryString = window.location.search.substring(1);
		if (!queryString) {
			return null;
		}
		return _
			.chain(queryString.split('&'))
			.map(function(params) {
				const p = params.split('=');
				return [p[0], decodeURIComponent(p[1])];
			})
			.object()
			.value();
	}

	getQueryParams() {
		const queryString = window.location.search.substring(1);

		if (queryString !== this.lastParsedQueryString) {
			this.lastParsedQueryParams = this.parseQueryParams();
		}

		return this.lastParsedQueryParams;
	}

	getQueryParam(name: string) {
		const queryParams = this.parseQueryParams();
		if (queryParams && name in queryParams) {
			return queryParams[name];
		}

		return null;
	}
}
