import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { UserService } from '@form-viewer/service/UserService';
import { FooterComponent } from '@shared/components/footer/FooterComponent';
import { HeaderComponent } from '@shared/components/header/HeaderComponent';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ContentService } from '../services/ContentService';

@Component({
	selector: "app-team",
	templateUrl: "./TeamComponent.html",
	styleUrls: ["TeamComponent.less"],
	standalone: true,
	imports: [
		HeaderComponent, FooterComponent, NgIf
	]
})
export class TeamComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	public content: SafeHtml;

	constructor(
		private sanitizer: DomSanitizer,
		private contentService: ContentService,
		private userService: UserService,
		private title: Title,
		private messageService: MessageService
	) {
		title.setTitle("Portal - Juve Recherche");
	}

	ngOnInit(): void {
		this.contentService
			.holeTeamContent()
			.then(
				(dto) =>
					(this.content = this.sanitizer.bypassSecurityTrustHtml(
						dto.content
					))
			);

		try {
			this.messageService.clear();
			this.userService.isAlive();
		} finally {
			setTimeout(() => {
				try {
					this.blockUI.resetGlobal();
				} catch (exception) {}
			}, 1000);
		}
	}
}
