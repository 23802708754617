import {NgClass, NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IInputTypeViewComponent} from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {InputTypeTextAntwort} from '@shared/model/antwort/input/InputTypeTextAntwort';
import {InputTypeTextarea} from '@shared/model/frage/input/InputTypeTextarea';
import {PreventSubmitOnEnterDirective} from "@util/PreventSubmitOnEnterDirective";

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Vollflächiges Eingabefeld ohne Rand zum Einbinden in Tabellenzellen
 */
@Component({
	selector: "app-input-type-textarea-view",
	templateUrl: "./InputTypeTextareaViewComponent.html",
	styleUrls: ["./InputTypeTextareaViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgClass, FormsModule, PreventSubmitOnEnterDirective, ScrollToFocusedElementDirective],
})
export class InputTypeTextareaViewComponent
	implements IInputTypeViewComponent<InputTypeTextarea>
{
	context = {};

	variant: string;

	@Input()
	antwort: InputTypeTextAntwort;

	@Input()
	type: InputTypeTextarea;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	isEmpty() {
		return this.antwort.wert.length === 0;
	}

	getPlatzhalterText(): string {
		return this.type.platzhalterText;
	}
}
