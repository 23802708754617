import {NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RouterLink} from '@angular/router';
import {
	AusfuellerVerwaltungDialogComponent
} from '@form-viewer/ausfueller-verwaltung/dialog/AusfuellerVerwaltungDialogComponent';
import {
	AbschnittsausfuellerVerwaltungFormConfiguration
} from '@form-viewer/ausfueller-verwaltung/form/abschnittsausfueller/AbschnittsausfuellerVerwaltungFormConfiguration';
import {AbschnittReadonlyService} from '@form-viewer/service/AbschnittReadonlyService';
import {AbschnittSperreService} from '@form-viewer/service/AbschnittSperreService';
import {AbschnittStorageService} from '@form-viewer/service/AbschnittStorageService';
import {AusfuellerBerechtigungsService} from '@form-viewer/service/AusfuellerBerechtigungsService';
import {PreviewService} from '@form-viewer/service/PreviewService';
import {UserService} from '@form-viewer/service/UserService';
import {NewTabComponent} from '@shared/components/new-tab.component';
import {Abschnitt} from '@shared/model/Abschnitt';
import {Fragebogen} from '@shared/model/Fragebogen';
import {AktuelleBerechtigungService} from '@shared/service/AktuelleBerechtigungService';
import {FragebogenService} from '@shared/service/FragebogenService';
import {NavigationService} from '@shared/service/NavigationService';
import {DateFormatterUtil} from '@shared/util/DateFormatterUtil';
import {CapitalizeFirst} from '@util/CapitalizeFirst';
import {NewlineToParagraphsPipe} from '@util/NewlineToParagraphsPipe';
import {PreventDefaultDirective} from '@util/PreventDefaultDirective';
import {SimplePluralPipe} from '@util/SimplePluralPipe';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ModalDirective, ModalModule} from 'ngx-bootstrap/modal';
import {ButtonModule} from 'primeng/button';
import {FragebogenAbschliessenComponent} from '../fragebogen-abschliessen/FragebogenAbschliessenComponent';
import {FragebogenAufschliessenComponent} from '../fragebogen-aufschliessen/FragebogenAufschliessenComponent';
import {FragebogenDruckenComponent} from '../fragebogen-drucken/FragebogenDruckenComponent';

@Component({
			   selector: 'app-viewer-abschnitt-liste',
			   templateUrl: './ViewerAbschnittListeComponent.html',
			   styleUrls: ['./ViewerAbschnittListeComponent.less'],
			   standalone: true,
			   imports: [AusfuellerVerwaltungDialogComponent,
						 NewlineToParagraphsPipe,
						 NgIf,
						 NgClass,
						 NgSwitch,
						 NgSwitchCase,
						 SimplePluralPipe,
						 CapitalizeFirst,
						 FragebogenAbschliessenComponent,
						 FragebogenAufschliessenComponent,
						 ButtonModule,
						 NewTabComponent,
						 ModalModule,
						 NgFor,
						 PreventDefaultDirective,
						 RouterLink],
		   }) export class ViewerAbschnittListeComponent implements OnInit {
	private static readonly ANZAHL_ABSCHNITTE_AB_DENEN_ZWEITER_ZURUECK_BUTTON_EINGEBLENDET_WIRD = 9;

	@ViewChild('vorschau') vorschau: NewTabComponent;

	@BlockUI() blockUI: NgBlockUI;

	@Input() fragebogen: Fragebogen;

	@Input() fragebogen_abgeschlossen: boolean;

	@Input() showControls: boolean;

	@Output() selectAbschnitt = new EventEmitter<Abschnitt>();

	@ViewChild('dialog', {static: true}) dialog: AusfuellerVerwaltungDialogComponent;

	@ViewChild('modal') modal: ModalDirective;

	showBackLinks: boolean;
	hatFragebogenAntworten = false;
	showVorschau = false;

	constructor(private userService: UserService,
				private abschnittStorageService: AbschnittStorageService,
				private abschnittSperreService: AbschnittSperreService,
				private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
				private previewService: PreviewService,
				private aktuelleBerechtigungService: AktuelleBerechtigungService,
				private navigationService: NavigationService,
				private abschnittReadonlyService: AbschnittReadonlyService,
				private fragebogenService: FragebogenService) {
	}

	get institutionId(): string {
		return this.aktuelleBerechtigungService.getInstitutionsId();
	}

	get abschnitte(): Abschnitt[] {
		return this.abschnittStorageService.get(this.fragebogen).abschnitte;
	}

	isAdministrator(): boolean {
		return this.ausfuellerBerechtigungsService.istAdministrator();
	}

	isCoAdministrator(): boolean {
		return this.ausfuellerBerechtigungsService.istCoAdministrator();
	}

	isFragebbogenAusfuellerFuer(abschnitt: Abschnitt): boolean {
		return this.ausfuellerBerechtigungsService.istFragebbogenAusfuellerFuer(abschnitt.fragebogen_id);
	}

	isAbschnittsAusfuellerFuer(abschnitt: Abschnitt): boolean {
		return this.ausfuellerBerechtigungsService.istAbschnittsAusfuellerFuer(abschnitt.id);
	}

	getRechteLabel(abschnitt: Abschnitt) {
		if (this.isAdministrator()) {
			return 'Administrator';
		} else if (this.isCoAdministrator()) {
			return 'CoAdministrator';
		} else if (this.isFragebbogenAusfuellerFuer(abschnitt)) {
			return 'Fragebogenausfüller';
		} else if (this.isAbschnittsAusfuellerFuer(abschnitt)) {
			return 'Abschnittsausfüller';
		} else {
			return 'Mit den aktuellen Berechtigungen haben Sie nur Leserechte';
		}
	}

	async ngOnInit(): Promise<void> {
		this.showBackLinks = this.previewService.isNotInPreview();

		if (this.showBackLinks) {
			this.userService.entsperrenForUser();

			if (this.fragebogen.hatVorjahresfragebogen()) {
				this.hatFragebogenAntworten = await this.fragebogenService
														.hatAbgeschlosseneFragebogenVersion(this.fragebogen.vorjahresfragebogen.id, this.institutionId);
			}
		}
	}

	onSelectAbschnitt(abschnitt: Abschnitt): void {
		this.onNavigationClicked();
		this.selectAbschnitt.emit(abschnitt);
	}

	isAbschnittReadonly(abschnitt: Abschnitt): boolean {
		const fragebogen = this.abschnittStorageService.get(this.fragebogen);
		return this.abschnittReadonlyService.sindAbschnittsinhalteReadonly(fragebogen, this.fragebogen_abgeschlossen, abschnitt);
	}

	iconClassForAbschnitt(abschnitt: Abschnitt): string {
		return this.isAbschnittReadonly(abschnitt) ? 'icon-eye' : 'icon-edit';
	}

	istAbschnittVonAnderemUserGesperrt(abschnitt: Abschnitt): boolean {
		return this.abschnittSperreService.istAbschnittVonAnderemUserGesperrt(this.abschnittStorageService.get(this.fragebogen).abschnittSperren,
																			  abschnitt);
	}

	istVonAktuellemUserInAndererInstanzGesperrt(abschnitt: Abschnitt): boolean {
		return this.abschnittSperreService.istAbschnittVonAktuellemUserInAndererInstanzGesperrt(this.abschnittStorageService.get(
			this.fragebogen).abschnittSperren, abschnitt);
	}

	istAbschnittsausfuellerVerwaltenSichtbar(): boolean {
		return (this.previewService.isNotInPreview() &&
				!this.fragebogen_abgeschlossen &&
				this.ausfuellerBerechtigungsService.darfAbschnittsausfuellerFuerFragebogenVerwalten(this.fragebogen));
	}

	sindAlleFragenGeprueft(abschnitt: Abschnitt): boolean {
		return this.abschnittStorageService.get(this.fragebogen).sindAlleFragenGeprueft(abschnitt);
	}

	getAnzahlGepruefterFragen(abschnitt: Abschnitt): number {
		return this.abschnittStorageService.get(this.fragebogen).getAnzahlGepruefterFragen(abschnitt);
	}

	showLowerBackLink(): boolean {
		return (this.showBackLinks &&
				this.abschnittStorageService.get(this.fragebogen).hatAbschnitte() &&
				this.abschnittStorageService.get(this.fragebogen).length >=
				ViewerAbschnittListeComponent.ANZAHL_ABSCHNITTE_AB_DENEN_ZWEITER_ZURUECK_BUTTON_EINGEBLENDET_WIRD);
	}

	zeigeLetztenBearbeiterAn(abschnitt: Abschnitt): string {
		const status = this.abschnittStorageService.get(this.fragebogen).getStatus(abschnitt);
		if (status) {
			return (DateFormatterUtil.transformToDateOnly(status.modified) + ' von ' + status.modifiedBy);
		} else {
			return '';
		}
	}

	zeigeAktuellenBearbeiterAn(abschnitt: Abschnitt): string {
		const sperre = this.abschnittStorageService.get(this.fragebogen).getSperren(abschnitt.id);
		if (sperre) {
			return sperre.bearbeiter_name;
		} else {
			return '';
		}
	}

	isInBearbeitung(abschnitt: Abschnitt): boolean {
		return this.abschnittStorageService.get(this.fragebogen).hatSperren(abschnitt);
	}

	abschnittNochNichtBearbeitet(abschnitt: Abschnitt): boolean {
		const hatStatus = this.abschnittStorageService.get(this.fragebogen).hatStatus(abschnitt);
		const hatSperre = this.abschnittStorageService.get(this.fragebogen).hatSperren(abschnitt);
		return !(hatSperre || hatStatus);
	}

	zeigeFragebogenAufschliessen(): boolean {
		return (this.previewService.isNotInPreview() &&
				this.fragebogen_abgeschlossen &&
				this.fragebogen.kannRkNochAusgefuelltWerden() &&
				this.fragebogen.kannRkWiedereroeffnetWerden() &&
				this.ausfuellerBerechtigungsService.darfFrageboegenAufUndAbschliessen());
	}

	zeigeDeaktiviertenAufschliessenButton(): boolean {
		return (this.previewService.isNotInPreview() &&
				this.fragebogen_abgeschlossen &&
				(!this.fragebogen.kannRkNochAusgefuelltWerden() || !this.fragebogen.kannRkWiedereroeffnetWerden()) &&
				this.ausfuellerBerechtigungsService.darfFrageboegenAufUndAbschliessen());
	}

	zeigeDeaktiviertenAbschliessenButton(): boolean {
		return (this.previewService.isNotInPreview() &&
				!this.fragebogen_abgeschlossen &&
				!this.fragebogen.kannRkNochAusgefuelltWerden() &&
				this.ausfuellerBerechtigungsService.darfFrageboegenAufUndAbschliessen());
	}

	zeigeFragebogenAbschliessen(): boolean {
		return (this.previewService.isNotInPreview() &&
				!this.fragebogen_abgeschlossen &&
				this.fragebogen.kannRkNochAusgefuelltWerden() &&
				this.ausfuellerBerechtigungsService.darfFrageboegenAufUndAbschliessen());
	}

	abschnittsausfuellerVerwaltenDialogOeffnen(abschnitt: Abschnitt): void {//TODO: JUREC-472/481: Dialog ganz ausbauen oder drin lassen für ausführlichere Variante
		this.dialog.show(new AbschnittsausfuellerVerwaltungFormConfiguration(abschnitt, this.fragebogen));
	}

	getAnzahlFragen(abschnitt: Abschnitt): number {
		return abschnitt ? abschnitt.fragen.length : 0;
	}

	onZurueckZurUebersichtClicked(): void {
		this.navigationService.goToFragebogenuebersicht();
	}

	uebernehmeAntworten(fragebogenId, institutionId): void {
		this.fragebogenService.uebernehmeAntworten(fragebogenId, institutionId);
		this.closeDialog();
	}

	darfAntwortenUebernehmen() {
		return (this.previewService.isNotInPreview() && this.ausfuellerBerechtigungsService.darfAntwortenUebernehmen());
	}

	openDialog(event) {
		event.preventDefault();
		this.modal.show();
	}

	closeDialog() {
		this.modal.hide();
	}

	onNavigationClicked() {
		try {
			this.blockUI.start('Daten werden verarbeitet...');
		}
		finally {
			this.blockUI.stop();
		}
	}

	print() {
		this.showVorschau = true;
		this.vorschau.openContent(FragebogenDruckenComponent, 'Druckvorschau');
	}
}
