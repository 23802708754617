import {NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IFrageViewComponent} from '@form-viewer/abschnitt/frage-liste/frage/view/IFrageViewComponent';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {TextfeldfragenAntwort} from '@shared/model/antwort/TextfeldfragenAntwort';
import {TextfeldFrage} from '@shared/model/frage/TextfeldFrage';
import {PreventSubmitOnEnterDirective} from "@util/PreventSubmitOnEnterDirective";

@Component({
	selector: "app-viewer-abschnitt-frage-view-textfeld",
	templateUrl: "./TextfeldFrageViewComponent.html",
	styleUrls: ["./TextfeldFrageViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgSwitch, NgSwitchCase, FormsModule, PreventSubmitOnEnterDirective, ScrollToFocusedElementDirective],
})
export class TextfeldFrageViewComponent implements IFrageViewComponent {
	@Input()
	frage: TextfeldFrage;

	@Input()
	antwort: TextfeldfragenAntwort;

	@Input()
	readonly: boolean;

	printView = false;

	isReadonly() {
		return this.readonly;
	}

	showTextarea(): boolean {
		return this.frage.grossesTextfeldAktiviert;
	}

	setPrintView(printView: boolean): void {
		this.printView = printView;
	}
}
