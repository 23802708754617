import { VerwaltbarerAusfuellerFragebogenDto } from '@benutzerverwaltung/model/VerwaltbarerAusfuellerFragebogenDto';
import { RolleTitleUtil } from "../../util/RolleTitleUtil";

export class VerwaltbarerAusfuellerRecherchekomplexDto {
	id: string;
	name: string;
	label: string;

	hatBerechtigungFuerDiesesObjekt: boolean;
	istRecherchekomplexAdministrator: boolean;
	dummy: boolean;

	frageboegen: VerwaltbarerAusfuellerFragebogenDto[] = [];

	frageboegenGesamt: number;
	frageboegenBerechtigt: number;
	title: String;

	constructor(verwaltbarerAusfuellerRecherchekomplexDto?: VerwaltbarerAusfuellerRecherchekomplexDto) {
		if (verwaltbarerAusfuellerRecherchekomplexDto) {
			this.id = verwaltbarerAusfuellerRecherchekomplexDto.id;
			this.name = verwaltbarerAusfuellerRecherchekomplexDto.name;
			this.label = verwaltbarerAusfuellerRecherchekomplexDto.label;
			this.title = RolleTitleUtil.dekliniere(this.label);

			this.frageboegen = verwaltbarerAusfuellerRecherchekomplexDto.frageboegen.map(
				fragebogen => new VerwaltbarerAusfuellerFragebogenDto(fragebogen));

			this.frageboegenGesamt = verwaltbarerAusfuellerRecherchekomplexDto.frageboegenGesamt;
			this.frageboegenBerechtigt = verwaltbarerAusfuellerRecherchekomplexDto.frageboegenBerechtigt;
			this.hatBerechtigungFuerDiesesObjekt = verwaltbarerAusfuellerRecherchekomplexDto.hatBerechtigungFuerDiesesObjekt;
			this.istRecherchekomplexAdministrator = verwaltbarerAusfuellerRecherchekomplexDto.istRecherchekomplexAdministrator;
			this.dummy = verwaltbarerAusfuellerRecherchekomplexDto.dummy
		}
	}

	/*setInstitution(institution: VerwaltbarerAusfuellerInstitutionDto) {
		this.idInstitution = institution.id;
		this.anzeigenameInstitution = institution.anzeigename;
	}
	 */
}
