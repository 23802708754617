import {NgClass, NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IInputTypeViewComponent} from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {InputTypeTextAntwort} from '@shared/model/antwort/input/InputTypeTextAntwort';
import {InputTypeText} from '@shared/model/frage/input/InputTypeText';

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Vollflächiges Eingabefeld ohne Rand zum Einbinden in Tabellenzellen
 */
@Component({
	selector: "app-input-type-text-view",
	templateUrl: "./InputTypeTextViewComponent.html",
	styleUrls: ["./InputTypeTextViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgClass, FormsModule, ScrollToFocusedElementDirective],
})
export class InputTypeTextViewComponent
	implements IInputTypeViewComponent<InputTypeText>
{
	context = {};

	variant: string;

	@Input()
	antwort: InputTypeTextAntwort;

	@Input()
	type: InputTypeText;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	getPlatzhalterText(): string {
		return this.type.platzhalterText;
	}

	variantClass(): string {
		return this.variant;
	}
}
