import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { TabelleViewComponentBase } from '@form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabelleViewComponentBase';
import { InfoTextViewComponent } from "@form-viewer/shared/info-text/InfoTextViewComponent";
import { InputViewComponent } from "../../input/input-view/InputViewComponent";

@Component({
	selector: "app-tabelle-fix-view",
	templateUrl: "./TabelleFixViewComponent.html",
	styleUrls: [
		"./TabelleFixViewComponent.less",
		"../tabelle-view.shared.less",
	],
	standalone: true,
 imports: [InfoTextViewComponent, InputViewComponent, NgFor],
})
export class TabelleFixViewComponent extends TabelleViewComponentBase {}
