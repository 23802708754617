import {NgFor} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Alert} from '@shared/alert/service/Alert';
import {AlertService} from '@shared/alert/service/AlertService';
import {AlertModule} from "ngx-bootstrap/alert";

@Component({
	selector: "app-alert",
	templateUrl: "./AlertComponent.html",
	styleUrls: ["./AlertComponent.less"],
	standalone: true,
	imports: [NgFor, AlertModule],
})
export class AlertComponent implements OnInit {
	alerts: Alert[];

	constructor(private alertService: AlertService) {}

	closeAlert(alert: Alert) {
		this.alertService.dismissAlert(alert);
	}

	ngOnInit() {
		this.alertService.newAlert.subscribe(() => this.updateAlertList());
		this.alertService.removeAlert.subscribe(() => this.updateAlertList());
	}

	private updateAlertList() {
		this.alerts = this.alertService.getAlerts();
	}
}
