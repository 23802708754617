import {DatePipe, NgFor, NgIf, ViewportScroller} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {FormEditorHeaderComponent} from '@form-editor/editor-header/FormEditorHeaderComponent';
import {Abschnitt} from '@shared/model/Abschnitt';
import {AntwortVersion} from '@shared/model/AntwortVersion';
import {Fragebogen} from '@shared/model/Fragebogen';
import {Institution} from '@shared/model/Institution';
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {AntwortAnsichtService} from '../service/AntwortAnsichtService';
import {AntwortComponent} from './antwort/AntwortComponent';
import {InstitutionsAuswahlComponent} from './institutions-auswahl/InstitutionsAuswahlComponent';

@Component({
	selector: "app-form-editor",
	templateUrl: "./EditorAntwortenComponent.html",
	styleUrls: ["./EditorAntwortenComponent.less"],
	standalone: true,
	imports: [FormEditorHeaderComponent, NgIf, NgFor, InstitutionsAuswahlComponent, AntwortComponent, DatePipe, PreventDefaultDirective]
})
export class EditorAntwortenComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	fragebogenId: string;
	fragebogen: Fragebogen;
	institutionId: string;
	institution: Institution;
	abschnitte: Abschnitt[];
	version: AntwortVersion;
	existierenMehrereVersionen: boolean;

	diffEinblenden: boolean;

	constructor(
		private route: ActivatedRoute,
		private viewportScroller: ViewportScroller,
		private antwortAnsichtService: AntwortAnsichtService,
		private title: Title
	) {
		title.setTitle("Fragebogen-Editor - Juve Recherche");

		this.fragebogenId = this.route.snapshot.paramMap.get("fragebogenId");
		this.institutionId = this.route.snapshot.paramMap.get("institutionId");
	}

	ngOnInit(): void {
		if (this.institutionId) {
			this.antwortAnsichtService
				.getAntwortAnsichtInstitution(
					this.fragebogenId,
					this.institutionId
				)
				.then((dto) => {
					this.institutionId = dto.institution.id;
					this.institution = dto.institution;
					this.fragebogen = dto.fragebogen;
					this.abschnitte = dto.abschnitte;
					this.version = dto.version;
					this.existierenMehrereVersionen =
						dto.existierenMehrereVersionen;
				});
		} else {
			this.antwortAnsichtService
				.getAntwortAnsicht(this.fragebogenId)
				.then((dto) => {
					this.institution = dto.institution;
					this.fragebogen = dto.fragebogen;
					this.abschnitte = dto.abschnitte;
					this.version = dto.version;
					this.existierenMehrereVersionen =
						dto.existierenMehrereVersionen;
				});
		}
	}

	toggleDiffEinblenden() {
		this.diffEinblenden = !this.diffEinblenden;
	}

	selectInstitution(institution: Institution) {
		this.blockUI.start("Daten werden verarbeitet...");
		this.antwortAnsichtService
			.getAntwortAnsichtInstitution(this.fragebogenId, institution.id)
			.then((dto) => {
				this.institution = dto.institution;
				this.fragebogen = dto.fragebogen;
				this.abschnitte = dto.abschnitte;
				this.version = dto.version;
				this.existierenMehrereVersionen =
					dto.existierenMehrereVersionen;
			})
			.finally(this.blockUI.stop);
	}

	goToAbschnitt(id: string) {
		this.viewportScroller.scrollToAnchor(`a-${id}`);
	}

	goToFrage(id: string) {
		this.viewportScroller.scrollToAnchor(`f-${id}`);
	}
}
