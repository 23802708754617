import { AbschnittAntwortDto } from '@shared/model/AbschnittAntwortDto';
import { KartenAntwort } from '@shared/model/antwort/KartenAntwort';
import { KartenfragenAntwort } from '@shared/model/antwort/KartenfragenAntwort';
import { KartenAntwortDiffDto } from '@shared/model/KartenAntwortDiffDto';
import { KartenAntwortDiffWrapperDto } from '@shared/model/KartenAntwortDiffWrapperDto';

const debug = require('debug')('AbschnittAntwortDiffDetector');

/**
 * Funktioniert aktuell nur für den Abschnitt Mandate
 */
export class AbschnittAntwortDiffDetector {
	private known: { [key: string]: string } = {};

	initialize(antworten: AbschnittAntwortDto): void {
		this.known = {};

		if (!antworten || !antworten.antworten) {
			debug('Keine Antworten vorhanden, lasse Initialisierungszustand leer');
			return;
		}

		console.log(antworten);
		console.log((antworten.antworten[0] as KartenfragenAntwort));

		antworten.antworten.forEach(antwortenIndex => {
			(antwortenIndex as KartenfragenAntwort).karten.forEach(antwort => {
				this.known[antwort.id] = JSON.stringify(antwort);
			});
		});
	}

	getDiff(antworten: AbschnittAntwortDto): KartenAntwortDiffWrapperDto {
		if (!antworten || !antworten.antworten) {
			debug('Keine Antworten vorhanden, lasse Änderungsprüfung aus');
			return null;
		}

		let wrapper = new KartenAntwortDiffWrapperDto(antworten);

		(<KartenfragenAntwort[]>antworten.antworten).forEach(antwort => {
			const changes = KartenAntwortDiffDto.newForAntwort(antwort);

			console.log(changes);
			let deleted = Object.keys(this.known);
			antwort.karten.forEach((antwort: KartenAntwort) => {
				const id = antwort.id;
				if (!id) {
					console.log(antwort);
					changes.added.push(antwort);
					return;
				}
				const i = deleted.indexOf(id, 0);
				if (i > -1) {
					deleted.splice(i, 1);
				}
				if (JSON.stringify(antwort) !== this.known[id]) {
					changes.changed.push(antwort);
				}
			});
			changes.deleted = deleted;
			console.log('added ' + changes.added);
			console.log('changed ' + changes.changed);
			console.log('deleted ' + changes.deleted);

			wrapper.antworten.push(changes);
		});
		console.log(wrapper);

		return wrapper;
	}
}
