import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Store } from "@ngrx/store";
import { AlertService } from "../../shared/alert/service/AlertService";

const debug = require('debug')('service:PasswortZuruecksetzenService');

@Injectable({providedIn: 'root'})
export class PasswortZuruecksetzenService {

	BASE_PATH = '/passwortzuruecksetzen'

	constructor(private http: HttpClient, private store: Store, private alertService: AlertService) {
	}

	sendePasswortZuruecksetzenMail( username: string): Promise<any> {
		console.log('Service ' + username)
		const params = new HttpParams().set('username', username)
		return this.http.post(`${this.BASE_PATH}`, params).toPromise()
	}
}
