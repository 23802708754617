import {NgClass, NgFor, NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {KartenFrageEditSpalte} from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalte';
import {InfoTextEditComponent} from "@form-editor/shared/info-text/InfoTextEditComponent";
import {InputTypeEditComponent} from "@shared/components/input-type/InputTypeEditComponent";
import {InputType} from '@shared/model/frage/input/InputType';
import {InputTypeEmail} from "@shared/model/frage/input/InputTypeEmail";
import {InputTypeTelefon} from "@shared/model/frage/input/InputTypeTelefon";
import {InputTypeText} from "@shared/model/frage/input/InputTypeText";
import {KartenFeld} from '@shared/model/frage/KartenFeld';
import {DragDropModule} from "primeng/dragdrop";

const debug = require('debug')('KartenFrageEditSpalteComponent');

@Component({
	selector: "app-edit-karten-spalte",
	templateUrl: "./KartenFrageEditSpalteComponent.html",
	styleUrls: ["./KartenFrageEditSpalteComponent.less"],
	standalone: true,
	imports: [NgFor, NgIf, FormsModule, NgClass, InputTypeEditComponent, InfoTextEditComponent, DragDropModule]
})
export class KartenFrageEditSpalteComponent {
	@Input()
	spalte: KartenFrageEditSpalte;

	private static canDrag(el, container, handle) {
		return (
			handle.classList.contains("karten-feld-drag-handle") &&
			handle.classList.contains("draggable")
		);
	}

	onTypeChanged(feld: KartenFeld, type: InputType) {
		if (feld.type !== type) {
			if (
				!(feld.type instanceof InputTypeText) ||
				!(
					type instanceof InputTypeEmail ||
					type instanceof InputTypeTelefon
				)
			) {
				debug(
					"Löschen der Vorjahres-Referenz aufgrund von Änderung des Feld-Typs"
				);
				feld.vorjahresfeld = null;
			}
		}

		feld.type = type;
	}
}
