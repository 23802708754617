import { Antwort } from '@shared/model/Antwort';
import { Frage } from '@shared/model/Frage';
import { FrageTypes } from '@shared/model/FrageTypes';

export class FrageAntwortBuilderUtil {
	static build(input: any): Antwort {
		if (input) {
			const antwortType = FrageTypes.antwortTypeForID(input.frage_type);
			if (antwortType) {
				return new antwortType(input);
			}

			return null;
		} else {
			return null;
		}
	}

	static buildForFrage(frage: Frage): Antwort {
		const antwortType = FrageTypes.antwortTypeForID(frage.type);
		return new antwortType({
			id: null,
			frage_id: frage.id,
			frage_type: frage.type,
		} as any);
	}
}
