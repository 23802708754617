import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, NO_ERRORS_SCHEMA } from '@angular/core';
import { PreviewService } from '@form-viewer/service/PreviewService';
import { UserService } from '@form-viewer/service/UserService';
import { ScrollToFocusedElementDirective } from '@form-viewer/util/ScrollToFocusedElementDirective';
import { AlertService } from '@shared/alert/service/AlertService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { Antwort } from '@shared/model/Antwort';
import { Frage } from '@shared/model/Frage';
import { AktuelleBerechtigungService } from '@shared/service/AktuelleBerechtigungService';
import { saveAs } from 'file-saver';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

const debug = require('debug')('ViewerExportTriggerComponent');

@Component({
	selector: "app-viewer-export-trigger-view",
	templateUrl: "./ViewerExportTriggerComponent.html",
	styleUrls: ["./ViewerExportTriggerComponent.less"],
	standalone: true,
	imports: [ToastModule, NgIf, ScrollToFocusedElementDirective, TooltipModule],
	schemas: [NO_ERRORS_SCHEMA],
})
export class ViewerExportTriggerComponent {
	@BlockUI() blockUI: NgBlockUI;

	@Input()
	abschnitt: Abschnitt;

	@Input()
	frage: Frage;

	@Input()
	antwort: Antwort;

	constructor(
		private httpClient: HttpClient,
		private alertService: AlertService,
		private messageService: MessageService,
		private previewService: PreviewService,
		private aktuelleBerechtigungService: AktuelleBerechtigungService,
		private userService: UserService
	) {}

	isExportEnabled() {
		return (
			this.previewService.isNotInPreview() &&
			this.isExportable() &&
			this.userService.getAusfueller() != null
		);
	}

	generateHref() {
		return (
			"/view/fragebogen/csv/export/?frage=" +
			this.frage.id +
			"&institution=" +
			this.aktuelleBerechtigungService.getInstitutionsId() +
			"&ausfuellerIdInterceptor=" +
			this.userService.getAusfueller().id
		);
	}

	onClick($event: Event) {
		if (!this.isExportEnabled()) {
			$event.preventDefault();
		} else {
			$event.preventDefault();
			this.downloadCSV();
		}
	}

	downloadCSV() {
		this.blockUI.start("Download wird verarbeitet...");

		this.httpClient
			.get(
				"/view/fragebogen/csv/export/?frage=" +
					this.frage.id +
					"&institution=" +
					this.aktuelleBerechtigungService.getInstitutionsId() +
					"&ausfuellerIdInterceptor=" +
					this.userService.getAusfueller().id,
				{ responseType: "blob" }
			)
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.then((buffer) => {
				const data: Blob = new Blob([buffer], {
					type: "text/csv;charset=windows-1252",
				});

				let filename =
					this.abschnitt.ueberschrift + "-" + this.frage.ueberschrift;

				let slugi = filename
					.toLowerCase()
					.replace(/ /g, "-")
					.replace(/[^\w-]+/g, "");

				saveAs(data, slugi + ".csv");
			})
			.then(() => {
				this.messageService.clear();
				this.messageService.add({
					severity: "info",
					life: 5000,
					summary: "Download abgeschlossen",
				});

				this.messageService.add({
					severity: "warn",
					life: 8000,
					summary:
						"Zur Bearbeitung mit Excel müssen Sie die CSV in Excel importieren und nicht die Datei selbst öffnen!",
				});
			})
			.finally(this.blockUI.stop);
	}

	getExportTooltip() {
		if (this.userService.getAusfueller() == null) {
			return "Ausfüller kann nicht geladen werden. Bitte ausloggen und erneut einloggen!";
		} else {
			return "";
		}
	}

	isUserEmpty() {
		return this.userService.getAusfueller() == null;
	}

	private isExportable() {
		return this.frage.isExportableAsCsv();
	}
}
