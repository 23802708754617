import { AusfuellerVerwaltungFormConfiguration } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration';
import { CoAdminVerwaltungFormComponent } from '@form-viewer/ausfueller-verwaltung/form/co-admin/CoAdminVerwaltungFormComponent';
import { CoAdminAnlegenDto } from '@form-viewer/ausfueller-verwaltung/model/CoAdminAnlegenDto';

export class BenutzerverwaltungDialogLoescheAusfuellerConfiguration implements AusfuellerVerwaltungFormConfiguration {
	readonly FORM_COMPONENT = CoAdminVerwaltungFormComponent;
	readonly DTO_TYPE = CoAdminAnlegenDto;

	constructor() {
	}

	getWebserviceEndpoint() {
		return 'ersatz';
	}

	getFormTitleHtml() {
		return 'Neue Recherche-Koordinatoren benennen';
	}

	getEinladenButtonToolTipHtml() {
		return '';
	}

	getErklaerungsHtml() {
		return `<p>Dieser Benutzer ist in wenigstens einem Recherchekomplex Recherche-Ansprechpartner.
				Wenn Sie den Benutzer löschen möchten, müssen sie einen neuen Ansprechpartner bestimmen.</p>

				<p>Dieser Bearbeiter darf alle Fragebögen des Recherchekomplexes ansehen und
				beantworten. Sie können wählen, ob dieser Bearbeiter selbstständig Fragebögen an Juve übermitteln darf.</p>`;
	}

	applyToAnlegenDto(anlegenDto: CoAdminAnlegenDto): CoAdminAnlegenDto {
		return anlegenDto;
	}
}
