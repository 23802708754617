import {Injectable} from '@angular/core';
import {AbschnittSperreService} from '@form-viewer/service/AbschnittSperreService';
import {AusfuellerBerechtigungsService} from '@form-viewer/service/AusfuellerBerechtigungsService';
import {Abschnitt} from '@shared/model/Abschnitt';
import {AbschnittSperre} from '@shared/model/AbschnittSperre';
import {IFormViewerAbschnittSperrenHelper} from './IFormViewerAbschnittSperrenHelper';
import {AbschnittStorageService} from "@form-viewer/service/AbschnittStorageService";

@Injectable({providedIn: 'root'})
export class FormViewerAbschnittSperrenHelper implements IFormViewerAbschnittSperrenHelper {

	stoppRegelmaessigeVerlaengerungCallback: () => void = null;

	constructor(private abschnittSperreService: AbschnittSperreService,
				private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
				private abschnittStorageService: AbschnittStorageService) {

	}

	sperreAbschnitt(abschnitt: Abschnitt): Promise<AbschnittSperre> {
		if (this.ausfuellerBerechtigungsService.darfAbschnittBearbeiten(abschnitt)) {
			return this.abschnittStorageService.sperren(abschnitt);
		} else {
			return Promise.resolve(null);
		}
	}

	istAbschnittFuerAktuellenUserGesperrt(abschnitt: Abschnitt): boolean {
		return this.abschnittSperreService.istAbschnittFuerAktuellenUserGesperrt(abschnitt);
	}

	// Promise.catch muss vom Aufrufer bedient werden
	entsperrenAbschnitt(abschnitt: Abschnitt): Promise<void> {

		if (this.ausfuellerBerechtigungsService.darfAbschnittBearbeiten(abschnitt)) {
			return this.abschnittSperreService.entsperren(abschnitt);
		} else {
			return Promise.resolve();
		}
	}

	starteRegelmaessigeVerlaengerung(abschnitt: Abschnitt) {
		if (this.ausfuellerBerechtigungsService.darfAbschnittBearbeiten(abschnitt)) {
			this.stoppRegelmaessigeVerlaengerungCallback = this.abschnittSperreService.starteRegelmaessigeVerlaengerung(abschnitt);
		}
	}

	stoppeRegelmaessigeVerlaengerung() {
		if (this.stoppRegelmaessigeVerlaengerungCallback) {
			this.stoppRegelmaessigeVerlaengerungCallback();
			this.stoppRegelmaessigeVerlaengerungCallback = null;
		}
	}
}
