import { Component, Input } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { AusfuellerVerwaltungInnerFormComponent } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungInnerFormComponent';
import { CoAdminVerwaltungFormConfiguration } from '@form-viewer/ausfueller-verwaltung/form/co-admin/CoAdminVerwaltungFormConfiguration';
import { CoAdminAnlegenDto } from '@form-viewer/ausfueller-verwaltung/model/CoAdminAnlegenDto';
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: "app-coadmin-verwaltung-form",
	templateUrl: "./CoAdminVerwaltungFormComponent.html",
	styleUrls: ["./CoAdminVerwaltungFormComponent.less"],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule],
})
export class CoAdminVerwaltungFormComponent
	implements AusfuellerVerwaltungInnerFormComponent
{
	@Input()
	dto: CoAdminAnlegenDto;

	@Input()
	disabled = false;

	@Input()
	configuration: CoAdminVerwaltungFormConfiguration;
}
