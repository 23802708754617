import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Antwort } from '@shared/model/Antwort';
import { EntscheidungsfragenAntwort } from '@shared/model/antwort/EntscheidungsfragenAntwort';
import { KartenfragenAntwort } from '@shared/model/antwort/KartenfragenAntwort';
import { MehrfachauswahlAntwort } from '@shared/model/antwort/MehrfachauswahlAntwort';
import { TabellenfragenAntwort } from '@shared/model/antwort/TabellenfragenAntwort';
import { TextfeldfragenAntwort } from '@shared/model/antwort/TextfeldfragenAntwort';
import { Frage } from '@shared/model/Frage';
import { EntscheidungsFrage } from '@shared/model/frage/EntscheidungsFrage';
import { KartenFrage } from '@shared/model/frage/KartenFrage';
import { MehrfachauswahlFrage } from '@shared/model/frage/MehrfachauswahlFrage';
import { TabellenFrage } from '@shared/model/frage/TabellenFrage';
import { HtmlSanitizerPipe } from "../../../util/HtmlSanitizerPipe";

@Component({
	selector: "app-antwort",
	templateUrl: "./AntwortComponent.html",
	styleUrls: ["./AntwortComponent.less"],
	standalone: true,
	imports: [NgIf, NgFor, HtmlSanitizerPipe]
})
export class AntwortComponent {
	@Input()
	frage: Frage;

	@Input()
	antwort: Antwort;

	constructor(public sanitizer: DomSanitizer) {}

	isEntscheidungsfragenAntwort(): boolean {
		return (
			this.antwort &&
			this.antwort.frage_type ===
				EntscheidungsfragenAntwort.FRAGEN_TYPE_ID
		);
	}

	getEntscheidungsfragenAntwort(): EntscheidungsfragenAntwort {
		return this.antwort as EntscheidungsfragenAntwort;
	}

	getEntscheidungsfrage(): EntscheidungsFrage {
		return this.frage as EntscheidungsFrage;
	}

	isKartenfragenAntwort(): boolean {
		return (
			this.antwort &&
			this.antwort.frage_type === KartenfragenAntwort.FRAGEN_TYPE_ID
		);
	}

	getKartenfragenAntwort(): KartenfragenAntwort {
		return this.antwort as KartenfragenAntwort;
	}

	getKartenFrage(): KartenFrage {
		return this.frage as KartenFrage;
	}

	isMehrfachauswahlfragenAntwort(): boolean {
		return (
			this.antwort &&
			this.antwort.frage_type === MehrfachauswahlAntwort.FRAGEN_TYPE_ID
		);
	}

	getMehrfachauswahlfragenAntwort(): MehrfachauswahlAntwort {
		return this.antwort as MehrfachauswahlAntwort;
	}

	getMehrfachauswahlFrage(): MehrfachauswahlFrage {
		return this.frage as MehrfachauswahlFrage;
	}

	isTabellenfragenAntwort(): boolean {
		return (
			this.antwort &&
			this.antwort.frage_type === TabellenfragenAntwort.FRAGEN_TYPE_ID
		);
	}

	getTabellenfragenAntwort(): TabellenfragenAntwort {
		return this.antwort as TabellenfragenAntwort;
	}

	getTabellenFrage(): TabellenFrage {
		return this.frage as TabellenFrage;
	}

	isTextfeldfragenAntwort(): boolean {
		return (
			this.antwort &&
			this.antwort.frage_type === TextfeldfragenAntwort.FRAGEN_TYPE_ID
		);
	}

	getTextfeldfragenAntwort(): TextfeldfragenAntwort {
		return this.antwort as TextfeldfragenAntwort;
	}
}
