import {DatePipe, NgFor, NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {Ausfueller} from '@form-viewer/model/Ausfueller';
import {FrageFertigMarker} from '@form-viewer/model/FrageFertigMarker';
import {PopoverModule} from 'ngx-bootstrap/popover';

@Component({
	selector: "app-viewer-antwort-als-fertig-markiert-liste",
	templateUrl: "./ViewerAntwortAlsFertigMarkiertListeComponent.html",
	styleUrls: ["./ViewerAntwortAlsFertigMarkiertListeComponent.less"],
	standalone: true,
	imports: [NgFor, DatePipe, PopoverModule, NgIf],
})
export class ViewerAntwortAlsFertigMarkiertListeComponent {
	@Input()
	frageFertigMarker: FrageFertigMarker[];

	@Input()
	unfertigeAusfueller: Ausfueller[];

	isEmpty() {
		return (
			this.frageFertigMarker.length === 0 &&
			this.unfertigeAusfueller.length === 0
		);
	}
}
