import { Fragebogen } from '@shared/model/Fragebogen';
import { isDefined } from '@util/isDefined';

export class FragebogenBuilderUtil {
	static build(input: any): Fragebogen {
		return new Fragebogen(input as Fragebogen);
	}

	static buildList(input: any): Fragebogen[] {
		if (isDefined(input)) {
			return input.map(fragebogen => FragebogenBuilderUtil.build(fragebogen));
		} else {
			return null;
		}

	}
}
