import { Directive, Input, OnInit } from '@angular/core';
import { TabellenView } from '@form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabellenView';
import { TabellenZeileView } from '@form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabellenZeileView';
import { TabellenfragenAntwort } from '@shared/model/antwort/TabellenfragenAntwort';
import { TabellenFrage } from '@shared/model/frage/TabellenFrage';
import { TabellenSpalte } from '@shared/model/frage/TabellenSpalte';

@Directive()
export abstract class TabelleViewComponentBase implements OnInit {
	@Input()
	tabellenFrage: TabellenFrage;

	_tabellenAntwort: TabellenfragenAntwort;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	tabellenView: TabellenView;

	ngOnInit(): void {
		this.tabellenView = new TabellenView(this.tabellenFrage, this._tabellenAntwort);
		this.afterTabellenviewInitialized();
	}

	@Input()
	set tabellenAntwort(tabellenAntwort: TabellenfragenAntwort) {
		this._tabellenAntwort = tabellenAntwort;

		if (this.tabellenView) {
			this.tabellenView.setTabellenAntwort(tabellenAntwort);
		}
	}

	isReadonly() {
		return this.readonly;
	}

	getSpalten(): TabellenSpalte[] {
		return this.tabellenView.getSpalten();
	}

	getZeilen(): TabellenZeileView[] {
		return this.tabellenView.getZeilenViews();
	}

	protected afterTabellenviewInitialized() {
		// override me if desired
	}
}
