import { Component, Input } from '@angular/core';
import { ContactComponent } from '../contact/ContactComponent';
import { FooterComponent } from '../footer/FooterComponent';

@Component({
	selector: "app-message",
	templateUrl: "./MessageComponent.html",
	styleUrls: ["./message.less"],
	standalone: true,
	imports: [FooterComponent, ContactComponent]
})
export class MessageComponent {
	@Input() title: string;
}
