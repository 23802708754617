import { SichtbarerFragebogenDto } from '@portal-selector/model/SichtbarerFragebogenDto';
import { Recherchekomplex } from '@shared/model/Recherchekomplex';
import { ArrayUtil } from '@shared/util/ArrayUtil';

export class SichtbarerRecherchekomplexDto {
	recherchekomplex: Recherchekomplex;
	frageboegen = new Array<SichtbarerFragebogenDto>();
	berechtigungen = new Array<string>();

	constructor(dto?: SichtbarerRecherchekomplexDto) {
		if (dto) {
			this.recherchekomplex = new Recherchekomplex(dto.recherchekomplex);
			this.frageboegen = dto.frageboegen.map(fb => new SichtbarerFragebogenDto(fb));

			if (dto.berechtigungen) {
				this.berechtigungen = dto.berechtigungen.slice();
			}
		}
	}

	istRecherchekomplexadmin(): boolean {
		return this.hatBerechtigung('RECHERCHEKOMPLEX_ADMIN');
	}

	istCoAdmin(): boolean {
		return this.hatBerechtigung('CO_ADMIN');
	}

	istAbschnittausfueller(): boolean {
		return this.hatBerechtigung('ABSCHNITTSAUSFUELLER');
	}

	istFragebogenausfueller(): boolean {
		return this.hatBerechtigung('FRAGEBOGENAUSFUELLER');
	}

	hatBerechtigung(berechtigung: string): boolean {
		return ArrayUtil.contains(this.berechtigungen, berechtigung);
	}

	kannWiedereroeffnetWerden(): boolean {
		return this.recherchekomplex.kannWiedereroeffnetWerden();
	}

	kannNochAusgefuelltWerden(): boolean {
		return this.recherchekomplex.kannNochAusgefuelltWerden();
	}
}
