import { AusfuellerVerwaltungFormConfiguration } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration';
import { Abschnitt } from '@shared/model/Abschnitt';
/* eslint-disable-next-line max-len */
import { AbschnittsausfuellerVerwaltungFormComponent } from '@form-viewer/ausfueller-verwaltung/form/abschnittsausfueller/AbschnittsausfuellerVerwaltungFormComponent';
import { AbschnittsausfuellerAnlegenDto } from '@form-viewer/ausfueller-verwaltung/model/AbschnittsausfuellerAnlegenDto';
import { Fragebogen } from '@shared/model/Fragebogen';

export class AbschnittsausfuellerVerwaltungFormConfiguration implements AusfuellerVerwaltungFormConfiguration {
	readonly FORM_COMPONENT = AbschnittsausfuellerVerwaltungFormComponent;
	readonly DTO_TYPE = AbschnittsausfuellerAnlegenDto;

	constructor(private fuerAbschnitt: Abschnitt, private fuerFragebogen: Fragebogen) {
	}

	getWebserviceEndpoint() {
		return 'abschnittsausfueller';
	}

	getFormTitleHtml() {
		return 'Neuen Abschnitts-Bearbeiter für <strong>' + this.fuerAbschnitt.ueberschrift + '</strong> einladen';
	}

	getEinladenButtonToolTipHtml() {
		return 'Hier können Sie weitere Bearbeiter für den Abschnitt <strong>' + this.fuerAbschnitt.ueberschrift + '</strong> einladen.';
	}

	getErklaerungsHtml() {
		return `<p>Hier können Sie weitere <a
				href="faq?recherchekomplex=${this.fuerFragebogen.recherchekomplex.id}#benutzerrolle_bearbeiter"
				target="_blank">Abschnitts-Bearbeiter</a> für den Abschnitt
				<b>${this.fuerAbschnitt.ueberschrift}</b>
				im Fragebogen <b>${this.fuerFragebogen.name}</b> einladen. Dieser Bearbeiter darf alle Fragebögen einsehen, jedoch nur
				Fragen innerhalb dieses Abschnitts beantworten. Sie können ein Datum angeben, bis zu welchem dieser seine Antworten
				einzureichen hat. Dieses wird ihm beim Ausfüllen angezeigt.</p>`;
	}

	applyToAnlegenDto(anlegenDto: AbschnittsausfuellerAnlegenDto): AbschnittsausfuellerAnlegenDto {
		anlegenDto.fuerAbschnitt = this.fuerAbschnitt.id;
		return anlegenDto;
	}
}
