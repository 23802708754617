import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
	selector: "app-view-info-text",
	templateUrl: "./InfoTextViewComponent.html",
	styleUrls: ["./InfoTextViewComponent.less"],
	standalone: true,
	imports: [NgIf, TooltipModule]
})
export class InfoTextViewComponent {
	@Input()
	beschreibung: string;

	hasBeschreibung() {
		return !!this.beschreibung;
	}

	getBeschreibung() {
		return this.beschreibung;
	}
}
