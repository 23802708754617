<br>
<p-confirmDialog [baseZIndex]="10000"
				 [style]="{width: '50vw'}"
				 rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-table #dt1 [globalFilterFields]="['ausfuellerAnrede', 'ausfuellerName', 'ausfuellerEmail', 'ausfuellerEingeladen']"
		 [paginator]="true"
		 [rowTrackBy]="trackeVeraenderungenAmBaum"
		 [rows]="rows"
		 [showCurrentPageReport]="true"
		 [value]="institutionenUndRechechekomplexe"
		 [(selection)]="selectedValue"
		 sortField="ausfuellerName"
		 [sortOrder]="1"
		 selectionMode="single"
		 styleClass="p-datatable-striped">

	<ng-template pTemplate="caption">
		<div class="flex flex-row justify-content-between">
			<div>{{getAnzahlNichtRegistrierterNutzer()}} {{getAnzahlNichtRegistrierterNutzer() === 1 ? 'offene Einladung gefunden' : 'offene Einladungen gefunden'}}</div>
			<div class="p-input-icon-left">
				<i class="pi pi-filter"></i>
				<input (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
					   pInputText
					   class="searchField"
					   placeholder="Filter über alle Spalten"
					   style="margin-right: 10rem"
					   type="text">
			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="header">
		<tr>
			<th pSortableColumn="ausfuellerAnrede" style="width: 13rem">Anrede
				<p-sortIcon field="ausfuellerAnrede"></p-sortIcon>
			</th>
			<th pSortableColumn="ausfuellerName">Name
				<p-sortIcon field="ausfuellerName"></p-sortIcon>
			</th>
			<th pSortableColumn="ausfuellerEmail">E-Mail
				<p-sortIcon field="ausfuellerEmail"></p-sortIcon>
			</th>
			<th pSortableColumn="ausfuellerEingeladen" style="width: 25rem">Einladungsdatum
				<p-sortIcon field="ausfuellerEingeladen"></p-sortIcon>
			</th>
			<th style="width: 5rem">
			</th>
			<th style="width: 5rem">
			</th>
		</tr>

		<tr>
			<th>
				<p-columnFilter [showMenu]="false" field="ausfuellerAnrede" matchMode="contains">
					<ng-template let-filter="filterCallback" let-value pTemplate="filter">
						<span class="p-input-icon-left p-column-filter-row anredeFilter">
							<i class="pi pi-filter" style="z-index: 2;"></i>
							<p-dropdown (onChange)="filter($event.value)"
										[ngModel]="value"
										[options]="anreden"
										[showClear]="true"
										placeholder="&nbsp;">
								<ng-template let-option pTemplate="item">
									<span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
								</ng-template>
							</p-dropdown>
						</span>
					</ng-template>
				</p-columnFilter>
			</th>
			<th>
				<span class="p-input-icon-left p-column-filter-row">
				<i class="pi pi-filter"></i>
				<input (input)="dt1.filter($any($event.target).value, 'ausfuellerName','contains')"
					   class="p-column-filter-row"
					   pInputText
					   type="text">
				</span>
			</th>
			<th>
				<span class="p-input-icon-left p-column-filter-row">
				<i class="pi pi-filter"></i>
				<input (input)="dt1.filter($any($event.target).value, 'ausfuellerEmail','contains')"
					   class="p-column-filter-row"
					   pInputText
					   type="text">
				</span>
			</th>
			<th>
				<span class="p-input-icon-left p-column-filter-row">
					<i class="pi pi-filter"></i>
					<input (input)="dt1.filter($any($event.target).value, 'ausfuellerEingeladenString','contains')"
						   class="p-column-filter-row"
						   pInputText
						   type="text">
				</span>
			</th>
			<th></th>
			<th></th>
		</tr>
	</ng-template>

	<ng-template let-verwaltbarerAusfueller pTemplate="body">
		<tr (dblclick)="bearbeiteEingeladenenBenutzer(verwaltbarerAusfueller)"
			class="selectableRow">
			<td>{{verwaltbarerAusfueller.ausfuellerAnrede}}</td>
			<td>{{verwaltbarerAusfueller.ausfuellerName}}</td>
			<td>{{verwaltbarerAusfueller.ausfuellerEmail}}</td>
			<td>{{verwaltbarerAusfueller.ausfuellerEingeladenString}}</td>
			<td style="text-align: center">
				<button (click)="bearbeiteEingeladenenBenutzer(verwaltbarerAusfueller)"
						class="p-button-outlined p-button-rounded table-button p-button-raised"
						pButton
						data-bs-toggle="tooltip" data-bs-placement="bottom"
						title="Noch nicht registrierten Benutzer bearbeiten">
					<fa-icon style="margin-right: 1px" [icon]="faPen"></fa-icon>
				</button>
			</td>
			<td style="text-align: center">
				<button (click)="loescheNichtRegistriertenBenutzer(verwaltbarerAusfueller)"
						class="p-button-outlined p-button-rounded table-button p-button-raised table-trash-button"
						pButton
						data-bs-toggle="tooltip" data-bs-placement="bottom"
						title="Nicht registrierten Benutzer löschen">
					<fa-icon style="margin-right: 1px" [icon]="faTrash"></fa-icon>
				</button>
			</td>
		</tr>
	</ng-template>

</p-table>
