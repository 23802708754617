import { NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FragenSortService } from '@form-editor/service/FragenSortService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { Fragebogen } from '@shared/model/Fragebogen';
import { EditLockService } from '@shared/service/EditLockService';
import { AbschnittBuilderUtil } from '@shared/util/AbschnittBuilderUtil';
import { AbschnittReihenfolgeSpeicher } from '@shared/util/dragula-support/AbschnittReihenfolgeSpeicher';
import { KopieSubstringBuilder } from '@shared/util/KopieSubstringBuilder';
import { removeElement } from '@util/removeElement';
import { AbschnittComponent } from './abschnitt/AbschnittComponent';

@Component({
	selector: "app-editor-abschnitt-liste",
	templateUrl: "./AbschnittListeComponent.html",
	styleUrls: ["./AbschnittListeComponent.less"],
	standalone: true,
	imports: [NgFor, AbschnittComponent],
})
export class AbschnittListeComponent implements OnInit {
	private static readonly DRAGULA_BAG = "Abschnitt";

	@Input()
	fragebogen: Fragebogen;

	@Input()
	abschnitte: Abschnitt[] = [];

	readonly: Boolean = true;

	constructor(
		private fragenSortService: FragenSortService,
		private lock: EditLockService
	) {
		const reihenfolgeSpeicher = new AbschnittReihenfolgeSpeicher();
	}

	ngOnInit() {
		this.readonly = this.lock.isLockedButNotFor(this);
	}

	private getAbschnittIdFromAbschnittElement(targetElem: any): string {
		return targetElem.getAttribute("data-abschnitt");
	}

	addAbschnitt() {
		const abschnitt = new Abschnitt();
		abschnitt.fragebogen_id = this.fragebogen.id;
		this.abschnitte.push(abschnitt);
	}

	removeAbschnitt(abschnitt: Abschnitt) {
		removeElement(this.abschnitte, abschnitt);
	}

	duplicateAbschnitt(abschnitt: Abschnitt) {
		const newAbschnitt = AbschnittBuilderUtil.build(abschnitt);
		newAbschnitt.id = null;
		this.addKopieSubstringToUeberschrift(newAbschnitt);
		newAbschnitt.fragen.map((frage) => {
			frage.clearIds();
			return frage;
		});
		this.abschnitte.push(newAbschnitt);
	}

	isReadOnly() {
		return this.lock.isLockedButNotFor(this);
	}

	private addKopieSubstringToUeberschrift(abschnitt: Abschnitt) {
		abschnitt.ueberschrift =
			abschnitt.ueberschrift +
			" " +
			KopieSubstringBuilder.buildKopieSubstringFor(new Date());
	}

	saveAbschnittOnReorder($event: Abschnitt) {
		this.fragenSortService.speichereAbschnittListe(
			[$event.id],
			this.abschnitte
		);
	}
}
