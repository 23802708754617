import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IInputTypeEditComponent } from '@shared/components/input-type/IInputTypeEditComponent';
import { InputTypeEmail } from "@shared/model/frage/input/InputTypeEmail";
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: "app-input-type-email-edit",
	templateUrl: "./InputTypeEmailEditComponent.html",
	styleUrls: ["./InputTypeEmailEditComponent.less"],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule],
})
export class InputTypeEmailEditComponent
	implements OnInit, IInputTypeEditComponent<InputTypeEmail>
{
	@Input()
	inputType: InputTypeEmail;

	platzhalterText = "";

	getEditorTitel(): string {
		return "Optionen";
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
	}
}
