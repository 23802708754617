import { UUID } from "@util/export-types";
import { FragebogenFreigaben } from "./FragebogenFreigaben";


export class RecherchekomplexFreigabenDTO {
	id: UUID = undefined;
	name: String = "";
	frageboegenFreigabenList: FragebogenFreigaben[] = [];

	constructor(tmp: RecherchekomplexFreigabenDTO) {
		this.id = tmp.id;
		this.name = tmp.name;
		this.frageboegenFreigabenList = tmp.frageboegenFreigabenList.map(fragebogen => new FragebogenFreigaben(fragebogen))
	}
}
