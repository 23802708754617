import {NgClass, NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IInputTypeViewComponent} from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {InputTypeCheckboxAntwort} from '@shared/model/antwort/input/InputTypeCheckboxAntwort';
import {InputTypeCheckbox} from '@shared/model/frage/input/InputTypeCheckbox';

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Einsatz in Tabellenzelle, ohne Margin
 */
@Component({
	selector: "app-input-type-checkbox-view",
	templateUrl: "./InputTypeCheckboxViewComponent.html",
	styleUrls: ["./InputTypeCheckboxViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgClass, FormsModule, ScrollToFocusedElementDirective],
})
export class InputTypeCheckboxViewComponent
	implements IInputTypeViewComponent<InputTypeCheckbox>
{
	context = {};

	variant: string;

	@Input()
	antwort: InputTypeCheckboxAntwort;

	@Input()
	type: InputTypeCheckbox;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	title(): string {
		return this.context["hint"];
	}
}
