import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Frage } from '@shared/model/Frage';
import { EditLockService } from '@shared/service/EditLockService';

@Component({
	selector: "app-editor-abschnitt-frage-view",
	templateUrl: "./FrageViewComponent.html",
	styleUrls: ["./FrageViewComponent.less"],
	standalone: true,
	imports: [NgIf]
})
export class FrageViewComponent {
	@Input()
	frage: Frage;

	@Output()
	edit = new EventEmitter();

	@Output()
	duplicate = new EventEmitter();

	@Output()
	remove = new EventEmitter();

	constructor(private lock: EditLockService) {}

	beginEdit() {
		this.edit.emit();
	}

	beginDuplicate() {
		this.duplicate.emit();
	}

	commitRemove() {
		this.remove.emit();
	}

	isReadOnly() {
		return this.lock.isLockedButNotFor(this);
	}
}
