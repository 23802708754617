import {NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IFrageViewComponent} from '@form-viewer/abschnitt/frage-liste/frage/view/IFrageViewComponent';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {EntscheidungsfragenAntwort} from '@shared/model/antwort/EntscheidungsfragenAntwort';
import {EntscheidungsFrage} from '@shared/model/frage/EntscheidungsFrage';
import {LastIdPipe} from "@util/LastIdPipe";
import {NewIdPipe} from "@util/NewIdPipe";
import {PreventSubmitOnEnterDirective} from "@util/PreventSubmitOnEnterDirective";

@Component({
	selector: "app-viewer-abschnitt-frage-view-entscheidung",
	templateUrl: "./EntscheidungsFrageViewComponent.html",
	styleUrls: ["./EntscheidungsFrageViewComponent.less"],
	standalone: true,
	imports: [NewIdPipe, LastIdPipe, NgIf, FormsModule, NgSwitch, NgSwitchCase, NgSwitchDefault, ScrollToFocusedElementDirective, PreventSubmitOnEnterDirective],
})
export class EntscheidungsFrageViewComponent implements IFrageViewComponent {
	@Input()
	frage: EntscheidungsFrage;

	@Input()
	antwort: EntscheidungsfragenAntwort;

	@Input()
	readonly: boolean;

	printView = false;

	isReadonly() {
		return this.readonly;
	}

	private isEntscheidungGetroffen(): boolean {
		return this.antwort.isEntscheidungGetroffen();
	}

	begruendungsTextfeldAktiviert(): boolean {
		return (
			this.isEntscheidungGetroffen() &&
			this.frage.istBegruendungAktivBei(this.antwort.auswahl)
		);
	}

	setPrintView(printView: boolean): void {
		this.printView = printView;
	}
}
