import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: "[appPreventDefault]",
	standalone: true,
})
export class PreventDefaultDirective {
	@HostListener("click", ["$event"])
	onClick(e) {
		e.preventDefault();
		e.stopPropagation();
	}
}
