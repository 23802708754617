import { KartenFrageEditSpalte } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalte';
import { KartenFeld } from '@shared/model/frage/KartenFeld';

export class KartenFrageEditSpalteLinks extends KartenFrageEditSpalte {

	getFelder(): KartenFeld[] {
		return this.frage.felderLinks;
	}

	addFeld(): void {
		this.frage.addFeldLinks();
	}

	removeFeld(feld: KartenFeld): void {
		this.frage.removeFeldLinks(feld);
	}
}
