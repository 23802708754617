import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Frage } from '@shared/model/Frage';

@Component({
	selector: "app-viewer-fragen-nav-eintrag",
	templateUrl: "./ViewerFragenNavEintragComponent.html",
	styleUrls: ["./ViewerFragenNavEintragComponent.less"],
	standalone: true,
	imports: [NgIf]
})
export class ViewerFragenNavEintragComponent {
	@Input()
	frage: Frage;

	@Input()
	isAktiveFrage: boolean;

	constructor() {}
}
