import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@shared/alert/service/AlertService';
import { Fragebogen } from '@shared/model/Fragebogen';
import { FragebogenStatus } from '@shared/model/FragebogenStatus';
import { AktuelleBerechtigungService } from '@shared/service/AktuelleBerechtigungService';

@Injectable({ providedIn: "root" })
export class FragebogenStatusService {
	constructor(
		private http: HttpClient,
		private alerts: AlertService,
		private aktuelleBerechtigungService: AktuelleBerechtigungService
	) {}

	schliesseFragebogen(fragebogen: Fragebogen): Promise<any> {
		return this.http
			.post("/viewer/fragebogenstatus/schliesse", null, {
				params: this.aktuelleBerechtigungService.addCommonParams({
					fragebogen: fragebogen.id,
				}),
			})
			.toPromise()
			.then(() => {
				this.alerts.showSuccess(
					"Fragebogen " +
						fragebogen.name +
						" erfolgreich an JUVE übermittelt."
				);
			})
			.catch(this.alerts.handleHttpError);
	}

	getAbgeschlosseneFrageboegen(): Promise<FragebogenStatus[]> {
		return this.http
			.get<FragebogenStatus[]>(
				"/viewer/fragebogenstatus/listAbgeschlossen",
				{ params: this.aktuelleBerechtigungService.addCommonParams({}) }
			)
			.toPromise()
			.then((response) => {
				return response.map(
					(fragebogen) => new FragebogenStatus(fragebogen)
				);
			})
			.catch(this.alerts.handleHttpError);
	}

	schliesseFragebogenAuf(fragebogen: Fragebogen) {
		return this.http
			.post("/viewer/fragebogenstatus/schliesse-auf", null, {
				params: this.aktuelleBerechtigungService.addCommonParams({
					fragebogen: fragebogen.id,
				}),
			})
			.toPromise()
			.then(() => {
				this.alerts.showSuccess(
					"Fragebogen " +
						fragebogen.name +
						" erfolgreich wieder geöffnet"
				);
			})
			.catch(this.alerts.handleHttpError);
	}
}
