import { UUID } from '@util/export-types';
import { KartenAntwort } from "./antwort/KartenAntwort";
import { KartenfragenAntwort } from "./antwort/KartenfragenAntwort";

export class KartenAntwortDiffDto {
	antwortId: UUID;
	frageId: UUID;
	added: KartenAntwort[] = [];
	changed: KartenAntwort[] = [];
	deleted: UUID[] = [];

	static newForAntwort(antwort: KartenfragenAntwort): KartenAntwortDiffDto {
		const diff = new KartenAntwortDiffDto();

		diff.antwortId = antwort.id;
		diff.frageId = antwort.frage_id;

		return diff;
	}

}
