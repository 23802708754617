import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, NullValidationHandler, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '../environments/environment';

export const authConfig: AuthConfig = {
	issuer: `${environment.keycloak.url}realms/${environment.keycloak.realm}`,
	clientId: environment.keycloak.clientId,
	redirectUri: window.location.origin,
	responseType: 'code',
	requireHttps: false,
	strictDiscoveryDocumentValidation: false,
	skipIssuerCheck: true,
	preserveRequestedRoute: true
};

let codeParam: String;

function getCodeParam() {
	if (window.location.href.includes('code=')) {
		let number = window.location.href.indexOf('code=');
		return window.location.href.slice(number);
	}
	return codeParam;
}

codeParam = getCodeParam();
export { codeParam };

// @ts-ignore
@Injectable()
export class AuthService implements OnDestroy {
	private subscription: Subscription = new Subscription();

	constructor(private readonly oauthService: OAuthService, private router: Router, private readonly auth: AuthConfig) {
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	private getRedirectUri() {
		if (codeParam) {
			return window.location.pathname + '?' + codeParam;
		}
		return window.location.pathname;
	}

	async initAuth(): Promise<any> {
		this.subscription = this.oauthService.events.subscribe((s: OAuthEvent) => {
			if (s.type === 'token_received') {
				const redirectUri = decodeURIComponent(this.oauthService.state);
				if (redirectUri.length > 1) {
					this.router.navigateByUrl(redirectUri);
				}
			}
		});

		try {
			await this.setupOAuthService();
			const isLoggedIn = await this.oauthService.loadDiscoveryDocumentAndLogin();
			if (isLoggedIn) {
				this.oauthService.setupAutomaticSilentRefresh();
				return isLoggedIn;
			} else {
				this.oauthService.initCodeFlow(this.getRedirectUri());
				throw new Error('User is not logged in');
			}
		} catch (error) {
			this.handleAuthError(error);
			throw error;
		}
	}

	private async setupOAuthService(): Promise<void> {
		this.oauthService.configure(this.auth);
		this.oauthService.setStorage(localStorage);
		this.oauthService.tokenValidationHandler = new NullValidationHandler();
	}

	private handleAuthError(error: any): void {
		if (error.message.includes('Validating access token failed, wrong state/nonce.')) {
			console.error('Überprüfung des Zugriffstokens fehlgeschlagen, falscher Zustand/Nonce. Möglicher CSRF-Angriff oder Fehlkonfiguration.', error);
			if (confirm('Es gab einen Fehler bei der Authentifizierung. Bitte loggen Sie sich erneut ein.')) {
				this.oauthService.logOut();
			}
		} else if (error.message.includes('Network Error')) {
			console.error('Netzwerkfehler während der Authentifizierung aufgetreten', error);
			alert('Netzwerkfehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.');
		} else if (error.message.includes('Error refreshing token')) {
			console.error('Fehler beim Aktualisieren des Tokens', error);
			alert('Fehler beim Aktualisieren des Tokens. Bitte loggen Sie sich erneut ein.');
			this.oauthService.logOut();
		} else {
			console.error('Fehler während der Authentifizierung', error);
		}
	}
}
