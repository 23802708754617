import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

const zxcvbn = require('zxcvbn/dist/zxcvbn.js');

@Component({
	selector: "app-password-strength",
	templateUrl: "./PasswordStrengthComponent.html",
	styleUrls: ["./PasswordStrengthComponent.less"],
	standalone: true,
	imports: [
		NgIf, NgFor
	]
})
export class PasswordStrengthComponent {
	@Input()
	warning: string;

	@Input()
	suggestions: string[];

	@Input()
	score: number;

	hasWarning() {
		return this.warning && this.warning.length > 0;
	}

	hasSuggestions() {
		return this.suggestions && this.suggestions.length > 0;
	}
}
