import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Abschnitt } from '@shared/model/Abschnitt';
import { Frage } from '@shared/model/Frage';
import { EditLockService } from '@shared/service/EditLockService';
import { FrageBuilderUtil } from '@shared/util/FrageBuilderUtil';
import { KopieSubstringBuilder } from '@shared/util/KopieSubstringBuilder';
import { OrderListModule } from 'primeng/orderlist';
import { FrageComponent } from './frage/FrageComponent';

@Component({
	selector: "app-editor-abschnitt-frage-liste",
	templateUrl: "./FrageListeComponent.html",
	styleUrls: ["./FrageListeComponent.less"],
	standalone: true,
	imports: [OrderListModule, FrageComponent, NgIf],
})
export class FrageListeComponent {
	@Input()
	abschnitt: Abschnitt;

	@Output()
	fragenUmsortiert = new EventEmitter<Abschnitt>();

	constructor(private lock: EditLockService) {}

	isNew() {
		return !this.abschnitt.id;
	}

	addFrage() {
		this.abschnitt.addFrage();
	}

	removeFrage(frage: Frage) {
		this.abschnitt.removeFrage(frage);
	}

	duplicateFrage(frage: Frage) {
		const newFrage = FrageBuilderUtil.build(frage);
		newFrage.clearIds();
		this.addKopieSubstringToUeberschrift(newFrage);
		this.abschnitt.addFrage(newFrage);
	}

	updateFrage(oldFrage: Frage, newFrage: Frage) {
		this.abschnitt.updateFrage(oldFrage, newFrage);
	}

	isReadOnly() {
		return this.lock.isLockedButNotFor(this);
	}

	canDrag() {
		return !this.lock.isLocked();
	}

	onReorder() {
		this.fragenUmsortiert.emit(this.abschnitt);
	}

	private addKopieSubstringToUeberschrift(frage: Frage) {
		frage.ueberschrift =
			frage.ueberschrift +
			" " +
			KopieSubstringBuilder.buildKopieSubstringFor(new Date());
	}
}
