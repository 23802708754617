import { Recherchekomplex } from '@shared/model/Recherchekomplex';

export class RecherchekomplexModalSteuerung {
	constructor(private recherchekomplex: Recherchekomplex) {

	}

	zeigeFrageboegenNichtWiederaufschliessbarModal(): boolean {
		return !this.zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal() && !this.recherchekomplex.kannWiedereroeffnetWerden();
	}

	zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal(): boolean {
		return !this.recherchekomplex.kannNochAusgefuelltWerden();
	}
}
