<!--<p-toast></p-toast>-->
<div style="height: calc(100vh - 240px); overflow-y: auto; overflow-x: hidden;">
	<p-card>
		<div class="form-group p-fluid">
			<div>
				<label class="col-fixed" style="width: 200px">Person</label>
				<span
					class="col">{{institutionUndRecherchekomplex.ausfuellerAnrede}} {{verwaltbarerAusfueller.anzeigename}}</span>
			</div>
			<div>
				<label class="col-fixed" style="width: 200px">Recherchekomplex</label>
				<span class="col"> {{institutionUndRecherchekomplex.recherchekomplexName}}  </span>
			</div>
			<div>
				<label class="col-fixed" style="width: 200px">Unternehmen</label>
				<span class="col"> {{currentInstituteString}}  </span>
			</div>
		</div>

		<ng-template pTemplate="footer">
			<div *ngIf="isAdminOrCoAdmin()" style="font-weight: bold; font-size: 1.2em; margin-top: -10px;">
				<fa-icon [icon]="faExclamationTriangle" style="color: orangered; margin: 10px;"></fa-icon>
				{{message}}
			</div>
		</ng-template>
	</p-card>

	<div *ngFor="let recherchekomplexMitAllenAbschnitten of recherchekomplexMitAllenAbschnittenListe">
		<p class="areaLabel">Fragebögen ({{recherchekomplexMitAllenAbschnitten.frageboegenGesamtAnzahl}})</p>
		<p-accordion>
			<p-accordionTab *ngFor="let fragebogenMitFreigaben of recherchekomplexMitAllenAbschnitten.frageboegen">
				<ng-template pTemplate="header">
					<div class="col-md-8">
						{{fragebogenMitFreigaben.name}}
					</div>

					<div class="col-md-2">
						<p-tag *ngIf="fragebogenMitFreigaben.hatAlleRechte()" [rounded]="true"
							   severity="success">Freigegeben
						</p-tag>
						<p-tag *ngIf="fragebogenMitFreigaben.hatTeilweiseRechte()" [rounded]="true"
							   severity="warning">Teilweise
						</p-tag>
						<p-tag *ngIf="fragebogenMitFreigaben.hatKeineRechte()" [rounded]="true"
							   severity="danger">Blockiert
						</p-tag>
					</div>

					<div class="col-md-2">
						<button (click)="$event.stopPropagation(); fragebogenMitFreigaben.toggleRechte()"
								*ngIf="!isAdminOrCoAdmin()"
								class="p-button-rounded p-button-outlined p-button-raised table-button"
								pButton
								pRipple
								style="float: right"
								type="button"
								data-bs-toggle="tooltip" data-bs-placement="bottom"
								title="Fragebogen freigeben/blockieren">
							<fa-icon style="margin-right: 1px" [icon]="faExchange"></fa-icon>
						</button>
					</div>

				</ng-template>

				<div *ngFor="let abschnitt of fragebogenMitFreigaben.abschnitteFreigabenList"
					 class="flex hoverline selectableRow"
					 (click)="$event.stopPropagation(); abschnitt.toggleRechte(isAdminOrCoAdmin())"
					 style="padding-right: 10px; padding-left: 40px;">

					<div class="md:col-8 p-2" style="margin-top: 2.5px">{{abschnitt.ueberschrift}}</div>

					<span class="col-md-2 p-ml-6 p-p-2">
						<p-tag *ngIf="abschnitt.hatRechte"
							   [rounded]="true"
							   severity="success">Freigegeben</p-tag>
						<p-tag *ngIf="!abschnitt.hatRechte" [rounded]="true" severity="danger">Blockiert</p-tag>
					</span>

					<div class="md:col-2 p-2">
						<button class="p-button-outlined p-button-rounded p-button-raised table-button"
								icon="{{abschnitt.hatRechte}}"
								pButton
								pRipple
								style="float: right"
								type="button"
								*ngIf="!isAdminOrCoAdmin()"
								(click)="$event.stopPropagation(); abschnitt.toggleRechte()"
								data-bs-toggle="tooltip" data-bs-placement="bottom"
								title="Abschnitt freigeben/blockieren">
							<fa-icon style="margin-right: 1px" [icon]="faExchange"></fa-icon>
						</button>
					</div>
				</div>
			</p-accordionTab>
		</p-accordion>
	</div>
</div>

<p-confirmDialog [baseZIndex]="10000"
				 [style]="{width: '50vw'}"
				 rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<div style="background-color: white; width: calc(100vw - 40px); max-width: 99%; position: absolute; bottom: 5px; left: 10px;">
	<div class="pull-right">
		<button (click)="closeDialog()"
				class="p-mr-2 p-mb-2 p-button-text"
				label="Abbrechen"
				onclick="window.getSelection().removeAllRanges();"
				pButton
				pRipple
				type="button"
				iconPos="left"
				icon="pi pi-times">
		</button>
		<button (click)="updateRechte()"
				class="p-mr-2 p-mb-2"
				[label]="verwaltbarerAusfueller.id === null ? 'Einladen' : 'Speichern'"
				onclick="window.getSelection().removeAllRanges();"
				pButton
				pRipple
				*ngIf="!isAdminOrCoAdmin()"
				type="submit"
				iconPos="left"
				icon="pi pi-check">
		</button>
	</div>
</div>
