import { AbschnittAntwortDto } from '@shared/model/AbschnittAntwortDto';

export class AbschnittAntwortDtoBuilderUtil {
	static build(input: AbschnittAntwortDto): AbschnittAntwortDto {
		return new AbschnittAntwortDto(input);
	}

	static buildWithoutTypes(abschnittAntwort: AbschnittAntwortDto): AbschnittAntwortDto {
		abschnittAntwort = AbschnittAntwortDtoBuilderUtil.build(abschnittAntwort);
		abschnittAntwort.antworten = abschnittAntwort.antworten.map(antwort => {
			antwort.deleteType();
			return antwort;
		});
		return abschnittAntwort;
	}
}
