import {UUID} from '@util/export-types';

export class AbschnittStatus {
	id: UUID;
	abschnitt_id: string;
	institution_id: string;
	modified: Date;
	modifiedBy: string;

	constructor(abschnittStatus?: AbschnittStatus) {
		if (abschnittStatus) {
			this.id = abschnittStatus.id;
			this.abschnitt_id = abschnittStatus.abschnitt_id;
			this.institution_id = abschnittStatus.institution_id;
			this.modified = new Date(abschnittStatus.modified);
			this.modifiedBy = abschnittStatus.modifiedBy;
		}
	}
}
