import { Component, Input } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { AusfuellerVerwaltungInnerFormComponent } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungInnerFormComponent';
/* eslint-disable-next-line max-len */
import { FragebogenausfuellerVerwaltungFormConfiguration } from '@form-viewer/ausfueller-verwaltung/form/fragebogenausfueller/FragebogenausfuellerVerwaltungFormConfiguration';
import { FragebogenausfuellerAnlegenDto } from '@form-viewer/ausfueller-verwaltung/model/FragebogenausfuellerAnlegenDto';
import { LastIdPipe } from "@util/LastIdPipe";
import { NewIdPipe } from "@util/NewIdPipe";

@Component({
	selector: "app-fragebogenausfueller-verwaltung-form",
	templateUrl: "./FragebogenausfuellerVerwaltungFormComponent.html",
	styleUrls: ["./FragebogenausfuellerVerwaltungFormComponent.less"],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
	standalone: true,
 imports: [NewIdPipe, LastIdPipe, FormsModule],
})
export class FragebogenausfuellerVerwaltungFormComponent
	implements AusfuellerVerwaltungInnerFormComponent
{
	@Input()
	dto: FragebogenausfuellerAnlegenDto;

	@Input()
	disabled = false;

	@Input()
	configuration: FragebogenausfuellerVerwaltungFormConfiguration;
}
