import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from '@shared/alert/service/AlertService';
import {Abschnitt} from '@shared/model/Abschnitt';
import {AbschnittSperre} from '@shared/model/AbschnittSperre';
import {AktuelleBerechtigungService} from '@shared/service/AktuelleBerechtigungService';
import {HttpUtil} from '@shared/util/HttpUtil';
import {UUID} from '@util/export-types';
import {firstValueFrom, Subject} from 'rxjs';
import {UserService} from './UserService';

const debug = require('debug')('service:ajax:AbschnittSperreService');

@Injectable({providedIn: 'root'})
export class AbschnittSperreService {
	static readonly VERLAENGERUNGS_INTERVAL_MILLISECONDS = 1000 * 30;
	sperrenAktualisiert = new Subject<void>();
	private eigeneAbschnittIdToSperreIdMap: { [s: string]: string } = {};

	constructor(private http: HttpClient, private userService: UserService, private alerts: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	get(abschnitt: Abschnitt): Promise<AbschnittSperre> {
		return firstValueFrom(this.http.get<AbschnittSperre>('/api/abschnitt/sperre/ist_gesperrt', {
			params: this.aktuelleBerechtigungService.addCommonParams({abschnitt: abschnitt.id}),
		})).then(response => new AbschnittSperre(response));
	}

	istAbschnittFuerAktuellenUserGesperrt(abschnitt: Abschnitt): boolean {
		try {
			return abschnitt.id in this.eigeneAbschnittIdToSperreIdMap;
		} catch (error) {
			console.error('Error checking if Abschnitt is locked for current user:', error);
			return false;
		}
	}

	getAbschnittSperreId(abschnitt: Abschnitt): UUID {
		return this.eigeneAbschnittIdToSperreIdMap[abschnitt.id] ? this.eigeneAbschnittIdToSperreIdMap[abschnitt.id] as UUID : null;
	}

	sperren(abschnitt: Abschnitt): Promise<AbschnittSperre> {
		debug('SPERRE Abschnitt', abschnitt);
		console.log('SPERRE Abschnitt', abschnitt);
		return firstValueFrom(this.http
								  .post('/api/abschnitt/sperre/anlegen', null, {
									  params: this.aktuelleBerechtigungService.addCommonParams({
																								   abschnitt: abschnitt.id,
																							   }),
								  }))
			.then((response) => {
				return new AbschnittSperre(<AbschnittSperre>response);
			})
			.catch((error: Response) => {
				if (error.status === HttpUtil.STATUS_CONFLICT) {
					console.log('SPERRE Abschnitt: Sperre nicht erteilt', abschnitt);
					this.alerts.showWarning('Sperre für Abschnitt ' + abschnitt.ueberschrift + ' wurde nicht erteilt');
					return Promise.resolve(null);
				} else {
					return Promise.reject(error);
				}
			})
			.then((sperre: AbschnittSperre) => {
				if (sperre.neu_angelegt) {
					this.updateAbschnittIdToSperreIdMap(abschnitt, sperre);
				}
				return sperre;
			})
			.catch(this.alerts.handleHttpError);
	}

	// Promise.catch muss vom Aufrufer bedient werden
	entsperren(abschnitt: Abschnitt): Promise<void> {
		if (!this.istAbschnittFuerAktuellenUserGesperrt(abschnitt)) {
			return Promise.resolve();
		}

		debug('ENTSPERRE Abschnitt', abschnitt);
		console.log('ENTSPERRE Abschnitt', abschnitt);

		const sperreId = this.getAbschnittSperreId(abschnitt);

		return firstValueFrom(this.http
								  .post('/api/abschnitt/sperre/entsperren', null, {
									  params: this.aktuelleBerechtigungService.addCommonParams({
																								   abschnitt: abschnitt.id,
																								   sperre: sperreId,
																							   }),
								  }))
			.then(() => {
				this.updateAbschnittIdToSperreIdMap(abschnitt, null);
				return;
			});
	}

	verlaengern(abschnitt: Abschnitt): Promise<AbschnittSperre> {
		debug('VERLAENGERE Abschnitt', abschnitt);
		console.log('VERLAENGERE Abschnitt', abschnitt);

		const sperreId = this.getAbschnittSperreId(abschnitt);

		if (!sperreId) {
			//Bugfix für JUREC-459
			return Promise.resolve(null);
		}

		return firstValueFrom(this.http
								  .post('/api/abschnitt/sperre/verlaengern/', null, {
									  params: this.aktuelleBerechtigungService.addCommonParams({
																								   abschnitt: abschnitt.id,
																								   sperre: sperreId,
																							   }),
								  }))
			.then((response) => {
				return new AbschnittSperre(<AbschnittSperre>response);
			})
			.catch((error: Response) => {
				if (error.status === HttpUtil.STATUS_GONE) {
					debug('VERLAENGERE Abschnitt: In anderem Tab geöffnet', abschnitt);
					return Promise.resolve(null);
				} else {
					return Promise.reject(error);
				}
			})
			.then((sperre: AbschnittSperre) => {
				this.updateAbschnittIdToSperreIdMap(abschnitt, sperre);
				return sperre;
			})
			.catch(this.alerts.handleHttpError);
	}

	istAktuell(abschnittSperre: AbschnittSperre): boolean {
		return (this.eigeneAbschnittIdToSperreIdMap[abschnittSperre.abschnitt_id] === abschnittSperre.id);
	}

	istAktuelleSperre(sperre: AbschnittSperre): boolean {
		for (const key in this.eigeneAbschnittIdToSperreIdMap) {
			if (this.eigeneAbschnittIdToSperreIdMap[key] === sperre.id) {
				return true;
			}
		}
		return false;
	}

	starteRegelmaessigeVerlaengerung(abschnitt: Abschnitt): () => void {
		let interval = setInterval(() => {
			this.verlaengern(abschnitt).then((sperre) => {
				if (!sperre) {
					clearInterval(interval);
					interval = null;
				}
			});
		}, AbschnittSperreService.VERLAENGERUNGS_INTERVAL_MILLISECONDS);

		return function () {
			if (interval) {
				clearInterval(interval);
			}
		};
	}

	istAbschnittVonAnderemUserGesperrt(abschnittSperren: AbschnittSperre[], abschnitt: Abschnitt): boolean {
		return abschnittSperren.some((sperre) => this._hatAktuellerUserKeinenZugriff(sperre, abschnitt));
	}

	istAbschnittVonAktuellemUserInAndererInstanzGesperrt(abschnittSperren: AbschnittSperre[], abschnitt: Abschnitt): boolean {
		return abschnittSperren.some((sperre) => this._istSperreVonAktuellemUserInAndererInstanzGesperrt(sperre, abschnitt));
	}

	hatAktuellerUserKeinenZugriff(abschnittSperren: AbschnittSperre[], abschnitt: Abschnitt): boolean {
		return abschnittSperren.some((sperre) => this._hatAktuellerUserKeinenZugriff(sperre, abschnitt));
	}

	istEigeneSperre(sperre: AbschnittSperre): boolean {
		return sperre.gehoertZu(this.userService.getAusfueller());
	}

	private updateAbschnittIdToSperreIdMap(abschnitt: Abschnitt, sperre: AbschnittSperre): void {
		if (sperre) {
			this.eigeneAbschnittIdToSperreIdMap[abschnitt.id] = sperre.id;
		} else {
			delete this.eigeneAbschnittIdToSperreIdMap[abschnitt.id];
		}
		debug('updateAbschnittIdToSperreIdMap', this.eigeneAbschnittIdToSperreIdMap);
		this.sperrenAktualisiert.next();
	}

	private _hatAktuellerUserKeinenZugriff(sperre, abschnitt: Abschnitt): boolean {
		return (sperre.gehoertZuAbschnitt(abschnitt) && !this.istEigeneSperre(sperre));
	}

	private _istSperreVonAktuellemUserInAndererInstanzGesperrt(sperre: AbschnittSperre, abschnitt: Abschnitt): boolean {
		return (sperre.gehoertZuAbschnitt(abschnitt) && this.istEigeneSperre(sperre) && !this.istAktuelleSperre(sperre));
	}
}
