import { DatePipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FrageFertigMarker } from '@form-viewer/model/FrageFertigMarker';
import { AbschnittSaveEventServiceService } from "@form-viewer/service/AbschnittSaveEventServiceService";
import { AlertService } from "@shared/alert/service/AlertService";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
	selector: "app-viewer-antwort-als-fertig-markieren",
	templateUrl: "./ViewerAntwortAlsFertigMarkierenComponent.html",
	styleUrls: ["./ViewerAntwortAlsFertigMarkierenComponent.less"],
	standalone: true,
	imports: [NgIf, DatePipe]
})
export class ViewerAntwortAlsFertigMarkierenComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	@Input()
	frageFertigMarker: FrageFertigMarker;

	@Output()
	alsFertigMarkiert = new EventEmitter<void>();

	@Output()
	alsUnfertigMarkiert = new EventEmitter<void>();

	@Input()
	readonly = false;

	private saving: boolean;

	constructor(
		private alertService: AlertService,
		private abschnSaveSvc: AbschnittSaveEventServiceService
	) {}

	ngOnInit() {
		this.abschnSaveSvc.savingEventListner().subscribe((info) => {
			// console.log(info); // here you get the message from Child component
			this.saving = JSON.parse(String(info));
		});
	}

	isSaving() {
		console.log("isSaving ... " + this.saving);
		return this.saving;
	}

	istAlsFertigMarkiert() {
		return this.frageFertigMarker != null;
	}

	alsFertigMarkieren() {
		this.alsFertigMarkiert.emit();
	}

	alsUnfertigMarkieren() {
		this.alsUnfertigMarkiert.emit();
	}

	toggleFertig() {
		// console.log("toggleGeprueft");
		this.blockUI.start("Daten werden verarbeitet...");
		if (this.isSaving()) {
			this.blockUI.stop();
			this.alertService.clearAlerts();
			this.alertService.showDanger(
				"Fehler beim Ausführen der Aktion! \nWährend dem Speichern kann der Status nicht verändert werden. \nBitte versuchen Sie es erneut!"
			);
		} else {
			if (this.istAlsFertigMarkiert()) {
				this.alsUnfertigMarkieren();
			} else {
				this.alsFertigMarkieren();
			}
		}
	}

	istVersteckt() {
		return this.readonly && !this.istAlsFertigMarkiert();
	}
}
