import {NgIf} from '@angular/common';
import {Component, ElementRef, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {RouterLink} from "@angular/router";
import {Ausfueller} from "@form-viewer/model/Ausfueller";
import {AusfuellerBerechtigungsService} from "@form-viewer/service/AusfuellerBerechtigungsService";
import {UserService} from "@form-viewer/service/UserService";
import {PortalSelectorComponent} from '@portal-selector/PortalSelectorComponent';
import {ContactComponent} from '@shared/components/contact/ContactComponent';
import {FooterComponent} from '@shared/components/footer/FooterComponent';
import {HeaderComponent} from '@shared/components/header/HeaderComponent';
import {SpinnerComponent} from '@shared/components/spinner/SpinnerComponent';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {MessageService} from 'primeng/api';

@Component({
	selector: "app-portal-allgemein",
	templateUrl: "./PortalAllgemeinComponent.html",
	styleUrls: [
		"../content-page.less",
		"../../../website-styles/content-page/_portal-meta.less",
	],
	standalone: true,
	imports: [NgIf, HeaderComponent, FooterComponent, PortalSelectorComponent, SpinnerComponent, ContactComponent, RouterLink]
})
export class PortalAllgemeinComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	ausfueller: Ausfueller;
	public initDone: boolean = false;

	constructor(
		private elementRef: ElementRef,
		private userService: UserService,
		private messageService: MessageService,
		private title: Title,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService
	) {
		title.setTitle("Portal - Juve Recherche");
		this.initDone = false;
	}

	ngOnInit(): void {
		try {
			this.messageService.clear();
			this.userService.isAlive();
			this.userService.getAusfueller();
			this.ausfueller = this.userService.getAusfueller();
			this.ausfuellerBerechtigungsService.clearCache();
			this.userService.getSichtbareInstitutionen();
		} finally {
			this.initDone = true;
			setTimeout(() => {
				try {
					this.blockUI.resetGlobal();
				} catch (exception) {}
			}, 1000);
		}
	}
}
