import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TabellenSpalte } from '@shared/model/frage/TabellenSpalte';
import { InfoTextEditComponent } from "../../../../../../../shared/info-text/InfoTextEditComponent";

@Component({
	selector: "app-editor-abschnitt-frage-edit-tabelle-title",
	templateUrl: "./TabelleFrageTitleEditComponent.html",
	styleUrls: ["./TabelleFrageTitleEditComponent.less"],
	standalone: true,
 imports: [InfoTextEditComponent, NgIf, FormsModule],
})
export class TabelleFrageTitleEditComponent {
	@Input()
	spalte: TabellenSpalte;

	constructor() {}

	infoTextChanged(beschreibung: string) {
		this.spalte.beschreibung = beschreibung;
	}
}
