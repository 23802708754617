import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Abschnitt } from '@shared/model/Abschnitt';
import { EditLockService } from '@shared/service/EditLockService';

@Component({
	selector: "app-editor-abschnitt-view",
	templateUrl: "./AbschnittViewComponent.html",
	styleUrls: ["./AbschnittViewComponent.less"],
	standalone: true,
})
export class AbschnittViewComponent {
	@Input()
	abschnitt: Abschnitt;

	@Output()
	edit = new EventEmitter();

	@Output()
	duplicate = new EventEmitter();

	constructor(private lock: EditLockService) {}

	beginEdit() {
		this.edit.emit();
	}

	beginDuplicate() {
		this.duplicate.emit();
	}

	isReadOnly() {
		return this.lock.isLockedButNotFor(this);
	}
}
