import {NgFor} from '@angular/common';
import {Component, HostListener, OnInit} from '@angular/core';
import {RouterLink} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {TableModule} from 'primeng/table';
import {UserService} from "../../../form-viewer/service/UserService";
import {NavigationService} from "../../../shared/service/NavigationService";
import {RecherchekomplexUebersichtDto} from "../../model/RecherchekomplexUebersichtDto";
import {AusfuellerService} from "../../services/AusfuellerService";
import {EventService} from "../../services/EventService";
import {RecherchekomplexUebersichtService} from "../../services/recherchekomplex-uebersicht.service";

@Component({
	selector: "app-recherchekomplexe",
	templateUrl: "./recherchekomplexe.component.html",
	styleUrls: ["./recherchekomplexe.component.less"],
	standalone: true,
	imports: [TableModule, NgFor, RouterLink, FontAwesomeModule, InputTextModule, ButtonModule],
})
export class RecherchekomplexeComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;
	cols: any[];
	public rows: number;
	faPen = faPencilAlt;
	public recherchekomplexListe: RecherchekomplexUebersichtDto[];
	public recherchekomplex: RecherchekomplexUebersichtDto;
	public selectedValue: RecherchekomplexUebersichtDto;
	private screenWidth: number;
	private screenHeight: number;

	constructor(
		public recherchekomplexUebersichtService: RecherchekomplexUebersichtService,
		private userService: UserService,
		public ausfuellerService: AusfuellerService,
		private navigationService: NavigationService,
		private eventService: EventService
	) {}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.calcTabelRows(event.target.innerWidth, event.target.innerHeight);
	}

	ngOnInit(): void {
		this.calcTabelRows(window.innerWidth, window.innerHeight);
		// console.log("ngOnInit")
		this.rufeRecherchekomplexListeAb();
		// this.vorbelegungStatusFilter();
		// this.setDefaultValues();
		this.cols = [
			{ field: "name", header: "Recherchekomplex", tooltip: "" },
			{
				field: "eingeladeneAusfueller",
				header: "Eingeladene Benutzer",
				tooltip: "",
			},
			{ field: "anzahlFrageboegen", header: "Fragebögen", tooltip: "" },
			{
				field: "status",
				header: "Aktivitätsstatus",
				tooltip:
					"Benutzer können beendete Recherchekomplexe nicht mehr bearbeiten",
			},
		];

		// this.isAlive()
	}

	rufeRecherchekomplexListeAb() {
		// console.log("rufeRecherchekomplexListeAb")

		this.blockUI.start("Daten werden verarbeitet...");
		try {
			let currentRights = this.userService.getRightsForCurrentInstitute();

			this.recherchekomplexUebersichtService
				.holeRecherchekomplexUebersicht()
				.then((recherchekomplexDtoListe) => {
					this.recherchekomplexListe = [];
					let komplexIds = new Set();

					for (const recherchekomplexUebersichtDto of recherchekomplexDtoListe) {
						if (!komplexIds.has(recherchekomplexUebersichtDto.id)) {
							for (const currentRight of currentRights) {
								if (
									currentRight.type == "CO_ADMIN" ||
									currentRight.type ==
										"RECHERCHEKOMPLEX_ADMIN"
								) {
									if (
										currentRight.recherchekomplex.id ===
										recherchekomplexUebersichtDto.id
									) {
										komplexIds.add(
											recherchekomplexUebersichtDto.id
										);
										this.recherchekomplexListe.push(
											recherchekomplexUebersichtDto
										);
										break;
									}
								}
							}
						}
					}
				});
		} finally {
			this.blockUI.stop();
		}
	}

	isAlive() {
		this.userService.isAlive();
	}

	goToRecherchekomplex(recherchekomplex: RecherchekomplexUebersichtDto) {
		// console.log("recherchekomplex: " + recherchekomplex.id)
		this.isAlive();

		this.navigationService.goToRecherkomplex(recherchekomplex);
	}

	eingeladeneBenutzerAnzeigen(
		recherchekomplex: RecherchekomplexUebersichtDto
	) {}

	private calcTabelRows(width, height) {
		this.screenWidth = width;
		this.screenHeight = height;
		this.rows = Math.floor(
			(this.screenHeight - 65 - 40 - 40 - 210) / 41 - 1
		);
		if (this.rows < 4) {
			this.rows = 4;
		}
	}
}
