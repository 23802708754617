import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@shared/alert/service/AlertService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { AbschnittAntwortDto } from '@shared/model/AbschnittAntwortDto';
import { KartenAntwortDiffWrapperDto } from "@shared/model/KartenAntwortDiffWrapperDto";
import { AktuelleBerechtigungService } from '@shared/service/AktuelleBerechtigungService';
import { AbschnittAntwortDtoBuilderUtil } from '@shared/util/AbschnittAntwortDtoBuilderUtil';

const debug = require('debug')('service:ajax:AbschnittAntwortService');

@Injectable({providedIn: 'root'})
export class AbschnittAntwortService {

	constructor(private http: HttpClient, private alertService: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	getAbschnittAntworten(abschnitt: Abschnitt): Promise<AbschnittAntwortDto> {
		debug('Lade Antworten für Abschnitt', abschnitt);
		return this.http.get<AbschnittAntwortDto>('/view/antworten', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				abschnitt: abschnitt.id,
			})
		})
			.toPromise()
			.then(response => AbschnittAntwortDtoBuilderUtil.build(response))
			.catch(this.alertService.handleHttpError);
	}

	saveAbschnittAntworten(abschnittAntwort: AbschnittAntwortDto): Promise<AbschnittAntwortDto> {
		console.log('Speichere Antworten für Abschnitt', abschnittAntwort.abschnitt_id);
		abschnittAntwort = AbschnittAntwortDtoBuilderUtil.buildWithoutTypes(abschnittAntwort);
		abschnittAntwort.institution_id = this.aktuelleBerechtigungService.getInstitutionsId();

		return this.http.post<AbschnittAntwortDto>('/view/antworten/abschnitt', abschnittAntwort)
			.toPromise()
			.then(response => AbschnittAntwortDtoBuilderUtil.build(response))
			.catch(this.alertService.handleHttpError);
	}

	saveAbschnittAntwortenDiff(wrapper: KartenAntwortDiffWrapperDto): Promise<Object> {
		console.log(wrapper);
		debug('Speichere Antworten Diff für Abschnitt', wrapper.abschnitt_id);
		wrapper.institution_id = this.aktuelleBerechtigungService.getInstitutionsId();

		return this.http.post('/view/antworten/diff', wrapper)
				   .toPromise()
				   .then()
				   .catch(this.alertService.handleHttpError);
	}
}
