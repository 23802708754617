import { Component, Input } from '@angular/core';
import { IFrageEditComponent } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';
import { MehrfachauswahlFrage } from '@shared/model/frage/MehrfachauswahlFrage';
import { SortableOptionsComponent } from '../widgets/sortable-options/SortableOptionsComponent';

@Component({
	selector: "app-editor-abschnitt-frage-edit-mehrfachauswahl",
	templateUrl: "./MehrfachauswahlFrageEditComponent.html",
	styleUrls: ["./MehrfachauswahlFrageEditComponent.less"],
	standalone: true,
	imports: [SortableOptionsComponent],
})
export class MehrfachauswahlFrageEditComponent
	implements IFrageEditComponent<MehrfachauswahlFrage>
{
	@Input()
	frage: MehrfachauswahlFrage;
}
