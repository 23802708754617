import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbschnittEditorService } from '@form-editor/service/AbschnittEditorService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { EditLockService } from '@shared/service/EditLockService';
import { TickService } from '@shared/service/TickService';
import { AbschnittBuilderUtil } from '@shared/util/AbschnittBuilderUtil';
import { AbschnittEditComponent } from "./edit/AbschnittEditComponent";
import { FrageListeComponent } from "./frage-liste/FrageListeComponent";
import { AbschnittViewComponent } from "./view/AbschnittViewComponent";

@Component({
	selector: "app-editor-abschnitt",
	templateUrl: "./AbschnittComponent.html",
	styleUrls: ["./AbschnittComponent.less"],
	standalone: true,
 imports: [AbschnittViewComponent, AbschnittEditComponent, FrageListeComponent, NgIf],
})
export class AbschnittComponent implements OnInit, OnDestroy {
	@Input()
	abschnitt: Abschnitt;

	@Output()
	abschnittChange = new EventEmitter<Abschnitt>();

	@Output()
	remove = new EventEmitter();

	@Output()
	duplicate = new EventEmitter();

	@Output()
	fragenUmsortiert = new EventEmitter();

	editAbschnitt: Abschnitt = null;

	constructor(
		private abschnittService: AbschnittEditorService,
		private lock: EditLockService
	) {}

	ngOnInit() {
		if (this.isNew()) {
			TickService.onNextTick(() => this.beginEdit());
		}
	}

	ngOnDestroy(): void {
		this.lock.unlockIfLockedFor(this);
	}

	isEditing() {
		return this.editAbschnitt != null;
	}

	hasQuestions() {
		return this.abschnitt.fragen.length > 0;
	}

	isNew() {
		return !this.abschnitt.id;
	}

	beginEdit() {
		if (this.lock.lockFor(this)) {
			this.editAbschnitt = AbschnittBuilderUtil.build(this.abschnitt);
		}
	}

	beginDuplicate() {
		this.duplicate.emit();
	}

	cancelEdit() {
		this.editAbschnitt = null;
		this.lock.unlockIfLockedFor(this);
		if (this.isNew()) {
			this.commitRemove();
		}
	}

	commitRemove() {
		if (!this.isNew()) {
			this.abschnittService
				.deleteAbschnitt(this.abschnitt)
				.then(() => this.remove.emit());
		} else {
			this.remove.emit();
		}
	}

	commitEdit() {
		this.abschnittService
			.saveAbschnitt(this.editAbschnitt)
			.then((abschnitt) => {
				this.abschnitt = abschnitt;
				this.abschnittChange.emit(this.abschnitt);
				this.editAbschnitt = null;
				this.lock.unlockIfLockedFor(this);
			});
	}

	isReadOnly() {
		return this.lock.isLockedButNotFor(this);
	}

	onFragenUmsortiert($event: Abschnitt) {
		this.fragenUmsortiert.emit($event);
	}
}
