import {NgClass, NgIf} from '@angular/common';
import {Component, Input, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IInputTypeViewComponent} from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {InputTypeKommazahlAntwort} from "@shared/model/antwort/input/InputTypeKommazahlAntwort";
import {InputTypeKommazahl} from "@shared/model/frage/input/InputTypeKommazahl";
import {InputNumberModule} from 'primeng/inputnumber';

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Vollflächiges Eingabefeld ohne Rand zum Einbinden in Tabellenzellen
 */
@Component({
	selector: "app-input-type-kommazahl-view",
	templateUrl: "./InputTypeKommazahlViewComponent.html",
	styleUrls: ["./InputTypeKommazahlViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgClass, FormsModule, InputNumberModule, ScrollToFocusedElementDirective],
})
export class InputTypeKommazahlViewComponent
	implements IInputTypeViewComponent<InputTypeKommazahl>
{
	context = {};

	variant: string;

	@ViewChild("tooltip") tooltip;

	@Input()
	antwort: InputTypeKommazahlAntwort;

	@Input()
	type: InputTypeKommazahl;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	getPlatzhalterText(): string {
		return this.type.platzhalterText;
	}

	getMaxLength(): number {
		// Länge = Vorkommastellen + Nachkommastellen + Komma
		return this.type.vorkommastellen
			? this.type.vorkommastellen + this.type.nachkommastellen + 1
			: null;
	}

	getAnzahlNachkommastellen(): number {
		return this.type.nachkommastellen;
	}

	showTooltipIfMaxlength(value) {
		if (
			this.type.vorkommastellen &&
			Math.floor(Math.log10(Number(value))) + 1 >
				this.type.vorkommastellen
		) {
			this.tooltip.show();
		} else {
			this.tooltip.hide();
		}
	}

	variantClass(): string {
		return this.variant;
	}
}
