import { Abschnitt } from '@shared/model/Abschnitt';
import { isDefined } from '@util/isDefined';

export class AbschnittBuilderUtil {
	static build(input: Abschnitt): Abschnitt {
		return new Abschnitt(input as Abschnitt);
	}

	static buildList(input: Abschnitt[]): Abschnitt[] {
		if (isDefined(input)) {
			return input.map(abschnitt => AbschnittBuilderUtil.build(abschnitt));
		} else {
			return null;
		}

	}
}
