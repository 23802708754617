import { UUID } from '@util/export-types';

export abstract class Antwort {
	static readonly FRAGEN_TYPE_ID: string;

	id: UUID;
	frage_id: UUID;
	frage_type: string;

	constructor(antwort?: Antwort) {
		if (antwort) {
			this.id = antwort.id;
			this.frage_id = antwort.frage_id;
			this.frage_type = antwort.frage_type;
		}
	}

	deleteType() {
		delete this.frage_type;
	}

	abstract isEmpty();

	applyIds(antwort: Antwort) {
		this.id = antwort.id;
	}

	abstract istInhaltlichGleich(o: Antwort): boolean;

	isValid() {
		return true;
	}
}
