import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { JuveAnsprechpartnerboxComponent } from '@component/ansprechpartnerbox/JuveAnsprechpartnerboxComponent';
import { NotificationSeenService } from '@form-viewer/service/NotificationSeenService';
import { UUID } from '@util/export-types';
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';

@Component({
	selector: "app-frageboegen-nicht-wiederaufschliessbar-modal",
	templateUrl: "./FrageboegenNichtWiedereroeffenbarComponent.html",
	styleUrls: ["../common/ModalCommonComponent.less"],
	standalone: true,
	imports: [JuveAnsprechpartnerboxComponent, ModalModule]
})
/* VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto nicht umbenennen da das Modal ansonsten noch einmal angezeigt wird */
export class FrageboegenNichtWiedereroeffenbarComponent
	implements AfterViewInit
{
	@ViewChild("modalDirective", { static: true })
	modalDirective: ModalDirective;

	@Input()
	recherchekomplexid: UUID;

	constructor(private notificationSeenService: NotificationSeenService) {}

	ngAfterViewInit(): void {
		if (
			!this.notificationSeenService.hasSeen(this, this.recherchekomplexid)
		) {
			this.openDialog();
		}
	}

	openDialog() {
		this.modalDirective.config.backdrop = "static";
		this.modalDirective.show();
	}

	closeDialog() {
		this.notificationSeenService.setHasSeen(this, this.recherchekomplexid);
		this.modalDirective.hide();
	}
}
