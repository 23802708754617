import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@shared/alert/service/AlertService';
import { Frage } from '@shared/model/Frage';
import { FrageBuilderUtil } from '@shared/util/FrageBuilderUtil';

@Injectable({providedIn: 'root'})
export class FrageEditorService {

	constructor(private http: HttpClient, private alerts: AlertService) {
	}

	saveFrage(frage: Frage): Promise<Frage> {
		return this.http.post('/api/editor/frage', frage)
			.toPromise()
			.then(response => FrageBuilderUtil.build(response))
			.catch(this.alerts.handleHttpError);
	}

	deleteFrage(frage: Frage): Promise<any> {
		return this.http.delete('/api/editor/frage/',
			{
				params:
					{
						frage: frage.id
					}
			})
			.toPromise()
			.catch(this.alerts.handleHttpError);
	}
}
