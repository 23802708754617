import {NgFor, NgIf, NgStyle} from '@angular/common';
import {Component, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {RecherchekomplexModalSteuerung} from '@form-viewer/modal/RecherchekomplexModalSteuerung';
import {Ausfueller} from '@form-viewer/model/Ausfueller';
import {AusfuellerBerechtigungsService} from '@form-viewer/service/AusfuellerBerechtigungsService';
import {FragebogenStatusService} from '@form-viewer/service/FragebogenStatusService';
import {UserService} from '@form-viewer/service/UserService';
import {AlertComponent} from '@shared/alert/component/AlertComponent';
import {Fragebogen} from '@shared/model/Fragebogen';
import {FragebogenStatus} from '@shared/model/FragebogenStatus';
import {Recherchekomplex} from '@shared/model/Recherchekomplex';
import {FragebogenService} from '@shared/service/FragebogenService';
import {NavigationService} from '@shared/service/NavigationService';
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";
import {
	AusfuellerVerwaltungDialogButtonComponent
} from "../ausfueller-verwaltung/button/AusfuellerVerwaltungDialogButtonComponent";
import {FragebogenListeViewComponent} from "../fragebogen-liste/FragebogenListeViewComponent";
import {
	FrageboegenNichtWiedereroeffenbarComponent
} from "../modal/frageboegen-nicht-wiedereroeffenbar/FrageboegenNichtWiedereroeffenbarComponent";
import {
	RecherchekomplexBeantwortenNichtMehrMoeglichModalComponent
} from "../modal/recherchekomplex-beantworten-nicht-mehr-moeglich-modal/RecherchekomplexBeantwortenNichtMehrMoeglichModalComponent";

@Component({
	selector: "app-viewer-portal-recherchekomplex",
	templateUrl: "./ViewerPortalRecherchekomplexComponent.html",
	styleUrls: ["./ViewerPortalRecherchekomplexComponent.less"],
	standalone: true,
	imports: [
		NgIf,
		AlertComponent,
		FrageboegenNichtWiedereroeffenbarComponent,
		RecherchekomplexBeantwortenNichtMehrMoeglichModalComponent,
		AusfuellerVerwaltungDialogButtonComponent,
		NgStyle,
		FragebogenListeViewComponent,
		NgFor,
		RouterLink,
		PreventDefaultDirective
	],
})
export class ViewerPortalRecherchekomplexComponent {
	@Input() recherchekomplex: Recherchekomplex;

	@Input() recherchekomplexFrist: string;

	@Input() showSpeichernInfo: boolean;

	@Input() zeitraeumeInfo: string;

	@Input() showSubmissionInfo: boolean;

	ausfueller: Ausfueller;

	frageboegen: Fragebogen[];

	abgeschlosseneFrageboegen: FragebogenStatus[];

	constructor(
		userService: UserService,
		fragebogenService: FragebogenService,
		fragebogenStatusService: FragebogenStatusService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
		private navigationService: NavigationService
	) {
		this.abgeschlosseneFrageboegen = [];
		this.frageboegen = [];

		this.ausfueller = userService.getAusfueller();
		fragebogenService.getOffeneFrageboegen().then((list) => {
			this.frageboegen = list;
		});
		fragebogenStatusService.getAbgeschlosseneFrageboegen().then((list) => {
			this.abgeschlosseneFrageboegen = list;
		});
	}

	darfCoAdminsVerwalten() {
		return this.ausfuellerBerechtigungsService.darfCoAdminsVerwalten();
	}

	goToFragebogen(fragebogen: Fragebogen) {
		this.navigationService.goToViewerFragebogen(fragebogen);
	}

	zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal(): boolean {
		return new RecherchekomplexModalSteuerung(
			this.recherchekomplex
		).zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal();
	}

	zeigeFrageboegenNichtWiederaufschliessbarModal(): boolean {
		return new RecherchekomplexModalSteuerung(
			this.recherchekomplex
		).zeigeFrageboegenNichtWiederaufschliessbarModal();
	}
}
