import {NgIf} from "@angular/common";
import {Component, Input, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IFrageEditComponent} from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';
import {InputType} from '@shared/model/frage/input/InputType';
import {InputTypeTextarea} from '@shared/model/frage/input/InputTypeTextarea';
import {TabellenFrage} from '@shared/model/frage/TabellenFrage';
import {InputTypes} from '@shared/model/InputTypes';
import {ArrayUtil} from '@shared/util/ArrayUtil';
import {LastIdPipe} from "@util/LastIdPipe";
import {NewIdPipe} from "@util/NewIdPipe";
import {TabelleErweiterbarFrageEditComponent} from './erweiterbar/TabelleErweiterbarFrageEditComponent';
import {TabelleFixFrageEditComponent} from './fix/TabelleFixFrageEditComponent';

@Component({
	selector: "app-editor-abschnitt-frage-edit-tabelle",
	templateUrl: "./TabelleFrageEditComponent.html",
	styleUrls: ["./TabelleFrageEditComponent.less"],
	standalone: true,
	imports: [NewIdPipe, LastIdPipe, FormsModule, TabelleFixFrageEditComponent, TabelleErweiterbarFrageEditComponent, NgIf],
})
export class TabelleFrageEditComponent
	implements IFrageEditComponent<TabellenFrage>, OnInit
{
	@Input()
	frage: TabellenFrage;

	isErweiterbar: boolean;

	availableTypes: (typeof InputType)[];

	constructor() {
		const inputTypesNotInTable: (typeof InputType)[] = [InputTypeTextarea];
		this.availableTypes = InputTypes.TYPES.filter(
			(type) => !ArrayUtil.contains(inputTypesNotInTable, type)
		);
	}

	ngOnInit(): void {
		this.isErweiterbar = this.frage.isErweiterbar();
	}

	erweiterbarkeitChanged() {
		if (this.isErweiterbar) {
			this.frage.clearFixeZeilen();
		} else {
			if (!this.frage.hasFixeZeilen()) {
				this.frage.addFixeZeile();
			}
		}
	}
}
