import { AnzahlGepruefterFragenProAbschnittDto } from '@shared/model/AnzahlGepruefterFragenProAbschnittDto';

export class AnzahlGepruefterFragenProAbschnittDtoBuilderUtil {
	static build(anzahlGepruefterFragenProAbschnittDto: AnzahlGepruefterFragenProAbschnittDto): AnzahlGepruefterFragenProAbschnittDto {
		return new AnzahlGepruefterFragenProAbschnittDto(anzahlGepruefterFragenProAbschnittDto);
	}

	static buildList(anzahlGepruefterFragenProAbschnittDtos: AnzahlGepruefterFragenProAbschnittDto[]): AnzahlGepruefterFragenProAbschnittDto[] {
		const abschnittAntwortMetaInfoDtoList: AnzahlGepruefterFragenProAbschnittDto[] = [];
		for (const anzahlGepruefterFragemProAbschnittDtos of anzahlGepruefterFragenProAbschnittDtos) {
			abschnittAntwortMetaInfoDtoList.push(this.build(anzahlGepruefterFragemProAbschnittDtos));
		}
		return abschnittAntwortMetaInfoDtoList;
	}
}
