import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { finalize } from 'rxjs/internal/operators/finalize';
import { environment } from '../../environments/environment';
import { UserService } from '../form-viewer/service/UserService';
import { AlertService } from '../shared/alert/service/AlertService';
import { LoggingService } from "../shared/service/LoggingService";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
	@BlockUI() blockUI: NgBlockUI;

	constructor(private alertService: AlertService,
				private userService: UserService,
				private cookieService: CookieService,
				private oauthService: OAuthService,
				private loggingService: LoggingService) {
	}

	intercept(request: HttpRequest<any>,
			  next: HttpHandler): Observable<HttpEvent<any>> {

		this.cookieService.deleteAll('/error', 'localhost', false, 'Lax');
		let expired = false;

		function decodeB64(str): string {
			return decodeURIComponent(escape(atob(str)));
		}

		function parseISOString(s) {
			var b = s.split(/\D+/);
			return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
		}

		if (!request.url.includes(environment.keycloak.url) && this.oauthService.hasValidAccessToken()) {
			request = request.clone(
				{
					setHeaders: {
						Authorization: `Bearer ${this.oauthService.getAccessToken()}`
					}
				});
		}
		// console.log('test');
		if (request.url.includes('admin/user')
			|| request.url.includes('admin/registrieren')
			|| request.url.includes('/passwortzuruecksetzen')
			|| request.url.includes('ansprechpartner-login')
			|| request.url.includes(environment.keycloak.url)) {

			return next.handle(request).pipe(
				catchError((error: HttpErrorResponse) => {
					return this.handleError(error);
				}),
				finalize(() => {
				})
			) as Observable<HttpEvent<any>>;
		} else {

			try {
				console.log('Ausfueller: ' + this.userService.getAusfueller()?.id);
			} catch (ignore) {
			}

			const clonedRequest = request.clone({
													params: request.params.append('ausfuellerIdInterceptor', this.userService.getAusfueller()?.id),
													headers: request.headers.append('ausfuellerIdInterceptor', this.userService.getAusfueller()?.id)
												});

			return next.handle(clonedRequest).pipe(
				catchError((error: HttpErrorResponse) => {
					return this.handleError(error);
				}),
				finalize(() => {
				})
			) as Observable<HttpEvent<any>>;
		}
	}

	private handleError(error: HttpErrorResponse) {
		this.logMessage(error);

		if (error.message.includes('504 Gateway Timeout')) {
			this.userService.logout504();
			return;
		} else if (error.message.includes('401') || error.message.includes('403')) {
			this.userService.logout401();
			return;
		} else if (error.message.includes('404')) {
			this.alertService.showWarning('Unerwarteter Fehler: Die angeforderte Seite kann nicht geladen werden. \n\n' + error.message + '\n' + error.error.message);
			this.blockUI.stop();
			return;
		} else if (error.message.includes('409')) {
			this.blockUI.stop();
		} else if (error.message.includes('Ausfueller kann nicht geladen werden')) {
			this.userService.logoutCustomMessage('FEHLER: Ihre Benutzerdaten konnten nicht geladen werden...');
			return;
		} else if ((error.message + '').includes('Cannot read properties of undefined') ||
			(error.error.message + '').includes('Cannot read properties of undefined')) {
			this.userService.logoutCustomMessage('FEHLER: Logout nach technischem Problem...');
		} else {
			this.alertService.showDanger('Unerwarteter Fehler: ' + error.message + '\n' + error.error.message);
			this.loggingService.logError(error);
			this.blockUI.stop();
		}

		return throwError(error);
	}

	private logMessage(error: HttpErrorResponse) {
		try {
			console.log(window.location.href);
		} catch (ignore) {
		}
		try {
			console.log(error.url);
		} catch (ignore) {
		}
		try {
			console.error('Error From Interceptor', error.message);
		} catch (ignore) {
		}
		try {
			console.error('Error From Interceptor', error.error.message);
		} catch (ignore) {
		}
	}
}
