import {NgClass, NgForOf, NgIf} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {RouterLink} from '@angular/router';
import {Store} from "@ngrx/store";
import {SichtbarerFragebogenDto} from '@portal-selector/model/SichtbarerFragebogenDto';
import {SichtbarerRecherchekomplexDto} from '@portal-selector/model/SichtbarerRecherchekomplexDto';
import {Institution} from '@shared/model/Institution';
import {setInstitution} from "../../store/action/institution.actions";
import {setRecherchekomplex} from "../../store/action/recherchekomplex.actions";

@Component({
	selector: "app-portal-recherchekomplex-eintrag",
	templateUrl: "./PortalRecherchekomplexEintragComponent.html",
	styleUrls: ["./PortalRecherchekomplexEintragComponent.less"],
	standalone: true,
	imports: [NgClass, NgIf, RouterLink, NgForOf]
})
export class PortalRecherchekomplexEintragComponent implements OnInit {
	@Input()
	institution: Institution;

	@Input()
	sichtbarerRecherchekomplex: SichtbarerRecherchekomplexDto;

	open = true;

	constructor(private store: Store) {}

	get id(): string {
		return this.sichtbarerRecherchekomplex.recherchekomplex.id;
	}

	get name(): string {
		return this.sichtbarerRecherchekomplex.recherchekomplex.name;
	}

	get frageboegen(): Array<SichtbarerFragebogenDto> {
		return this.sichtbarerRecherchekomplex.frageboegen;
	}

	ngOnInit(): void {
		this.open = !this.zeigeAlsDeaktiviert();
	}

	zeigeRecherchekomplexAdminHinweis(): boolean {
		return this.sichtbarerRecherchekomplex.istRecherchekomplexadmin();
	}

	zeigeCoAdminHinweis(): boolean {
		return (
			this.sichtbarerRecherchekomplex.istCoAdmin() &&
			!this.zeigeRecherchekomplexAdminHinweis()
		);
	}

	zeigeFragebogenAusfuellerHinweis(): boolean {
		return (
			this.sichtbarerRecherchekomplex.istFragebogenausfueller() &&
			!(
				this.zeigeRecherchekomplexAdminHinweis() ||
				this.zeigeCoAdminHinweis()
			)
		);
	}

	zeigeAbschnittAusfuellerHinweis(): boolean {
		return (
			this.sichtbarerRecherchekomplex.istAbschnittausfueller() &&
			!(
				this.zeigeRecherchekomplexAdminHinweis() ||
				this.zeigeCoAdminHinweis() ||
				this.zeigeFragebogenAusfuellerHinweis()
			)
		);
	}

	zeigeWiedereroeffnenNichtMehrMoeglichHinweis(): boolean {
		return (
			!this.zeigeNichtMehrAusfuellbarHinweis() &&
			!this.sichtbarerRecherchekomplex.kannWiedereroeffnetWerden()
		);
	}

	zeigeAlsDeaktiviert(): boolean {
		return (
			!this.sichtbarerRecherchekomplex.kannNochAusgefuelltWerden() ||
			!this.hatFrageboegen()
		);
	}

	hatFrageboegen(): boolean {
		return this.sichtbarerRecherchekomplex.frageboegen.length > 0;
	}

	zeigeNichtMehrAusfuellbarHinweis(): boolean {
		return !this.sichtbarerRecherchekomplex.kannNochAusgefuelltWerden();
	}

	toggleOpen(event) {
		this.open = !this.open;
		event.preventDefault();
	}

	setCurrentRKundInstitution() {
		this.store.dispatch(setInstitution(this.institution));
		this.store.dispatch(
			setRecherchekomplex(
				this.sichtbarerRecherchekomplex.recherchekomplex
			)
		);
	}
}
