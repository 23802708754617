import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IInputTypeViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import { MultiSelectComponent } from "@shared/components/select/multi/MultiSelectComponent";
import { InputTypeMehrfachauswahlAntwort } from '@shared/model/antwort/input/InputTypeMehrfachauswahlAntwort';
import { InputTypeMehrfachauswahl } from '@shared/model/frage/input/InputTypeMehrfachauswahl';

@Component({
	selector: "app-input-type-mehrfachauswahl-view",
	templateUrl: "./InputTypeMehrfachauswahlViewComponent.html",
	styleUrls: ["./InputTypeMehrfachauswahlViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgClass, MultiSelectComponent],
})
export class InputTypeMehrfachauswahlViewComponent
	implements IInputTypeViewComponent<InputTypeMehrfachauswahl>
{
	context = {};

	variant: string;

	@Input()
	antwort: InputTypeMehrfachauswahlAntwort;

	@Input()
	type: InputTypeMehrfachauswahl;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}
}
