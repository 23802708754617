
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
	providedIn: 'root'
})
export class EventService{

	private userActiveToggledBenutzer = new BehaviorSubject<string>('');
	private userRightsEdit = new BehaviorSubject<string>('');
	private userActiveToggledInaktive = new BehaviorSubject<string>('');
	private userOffeneEinladungen = new BehaviorSubject<string>('');
	private recherchekomplexEingeladeneUser = new BehaviorSubject<string>('');

	emitUserActiveToggledEventBenutzer(msg: string){
		console.log("emitUserActiveToggledEventBenutzer(" + msg + ")")
		this.userActiveToggledBenutzer.next(msg)
	}

	userActiveToggledOnBenutzerListener(){
		console.log("userActiveToggledOnBenutzerListener")

		return this.userActiveToggledBenutzer.asObservable();
	}

	emitUserRightsEditEventBenutzer(msg: string){
		console.log("emitUserRightsEditEventBenutzer(" + msg + ")")
		this.userRightsEdit.next(msg)
	}

	userUserRightsEditListener(){
		console.log("userUserRightsEditListener")
		return this.userRightsEdit.asObservable();
	}

	emitUserActiveToggledEventInactive(msg: string){
		console.log("emitUserActiveToggledEvent(" + msg + ")")
		this.userActiveToggledInaktive.next(msg)
	}

	userActiveToggledOnInaktiveListener(){
		console.log("userActiveToggledOnInaktiveListener")

		return this.userActiveToggledInaktive.asObservable();
	}

	emitUserOffeneEinladungenEvent(msg: string){
		console.log("Event emits emitUserOffeneEinladungenEvent")
		this.userOffeneEinladungen.next(msg)
	}

	userOffeneEinladungenListener(){
		console.log("Event Listens userOffeneEinladungenListener")
		return this.userOffeneEinladungen.asObservable();
	}

	emitRecherchekomplexEingeladeneUserEvent(msg: string){
		console.log("Event emits emitRecherchekomplexEingeladeneUserEvent")
		this.recherchekomplexEingeladeneUser.next(msg)
	}

	recherchekomplexEingeladeneUserListener(){
		console.log("Event Listens recherchekomplexEingeladeneUserListener")
		return this.recherchekomplexEingeladeneUser.asObservable();
	}
}
