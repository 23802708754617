import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from '@shared/alert/service/AlertService';
import {firstValueFrom} from 'rxjs';
import {AntwortAnsichtDto} from '../antworten/AntwortAnsichtDto';

@Injectable({providedIn: 'root'})
export class AntwortAnsichtService {

	constructor(private http: HttpClient, private alerts: AlertService) {
	}

	getAntwortAnsicht(fragebogenId: string): Promise<AntwortAnsichtDto> {
		return firstValueFrom(this.http.get<AntwortAnsichtDto>(`/api/editor/fragebogen/${fragebogenId}/antworten`))
			.then(response => new AntwortAnsichtDto(response))
			.catch(this.alerts.handleHttpError);
	}

	getAntwortAnsichtInstitution(fragebogenId: string, institutionId: string): Promise<AntwortAnsichtDto> {
		return firstValueFrom(this.http.get<AntwortAnsichtDto>(`/api/editor/fragebogen/${fragebogenId}/antworten/${institutionId}`))
				   .then(response => new AntwortAnsichtDto(response))
				   .catch(this.alerts.handleHttpError);
	}

}
