/* eslint-disable-next-line max-len */
import {NgIf} from '@angular/common';
import {Component, Input, ViewChild} from '@angular/core';
import {RouterLink} from "@angular/router";
/* eslint-disable-next-line max-len */
import {AusfuellerVerwaltungDialogComponent} from '@form-viewer/ausfueller-verwaltung/dialog/AusfuellerVerwaltungDialogComponent';
/* eslint-disable-next-line max-len */
import {
	FragebogenausfuellerVerwaltungFormConfiguration
} from '@form-viewer/ausfueller-verwaltung/form/fragebogenausfueller/FragebogenausfuellerVerwaltungFormConfiguration';
import {AusfuellerBerechtigungsService} from '@form-viewer/service/AusfuellerBerechtigungsService';
import {PreviewService} from '@form-viewer/service/PreviewService';
import {UserService} from '@form-viewer/service/UserService';
import {Fragebogen} from '@shared/model/Fragebogen';
import {NavigationService} from '@shared/service/NavigationService';
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";
import {SimplePluralPipe} from "@util/SimplePluralPipe";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
	selector: "app-viewer-fragebogen-navbar",
	templateUrl: "./ViewerFragebogenNavbarComponent.html",
	styleUrls: ["./ViewerFragebogenNavbarComponent.less"],
	providers: [],
	standalone: true,
	imports: [AusfuellerVerwaltungDialogComponent, SimplePluralPipe, NgIf, RouterLink, PreventDefaultDirective]
})
export class ViewerFragebogenNavbarComponent {
	@BlockUI()
	blockUI: NgBlockUI;

	@Input()
	fragebogen: Fragebogen;

	@Input()
	abgeschlossen: boolean;

	@Input()
	anzahlAbschnitte: number;

	@ViewChild("dialog", { static: true })
	dialog: AusfuellerVerwaltungDialogComponent;

	constructor(
		private userService: UserService,
		private previewService: PreviewService,
		private navigationService: NavigationService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService
	) {
		ausfuellerBerechtigungsService.clearCache();
	}

	zeigeZurueckButton(): boolean {
		return this.previewService.isNotInPreview();
	}

	istFragebogenausfuellerVerwaltenAktiv() {
		return (
			this.previewService.isNotInPreview() &&
			!this.abgeschlossen &&
			this.ausfuellerBerechtigungsService.darfFragebogenausfuellerVerwalten()
		);
	}

	fragebogenausfuellerVerwaltenDialogOeffnen() {
		this.dialog.show(
			new FragebogenausfuellerVerwaltungFormConfiguration(this.fragebogen)
		);
	}

	onZurueckZurUebersichtClicked() {
		this.blockUI.start("Daten werden verarbeitet...");
	}
}
