import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "newlinesToParagraphs", standalone: true })
export class NewlineToParagraphsPipe implements PipeTransform {
	private static escape(text: string): string {
		return text
			.replace(/&/g, "&amp;")
			.replace(/>/g, "&gt;")
			.replace(/</g, "&lt;");
	}

	private static normalize(text: string): string {
		return text
			.replace(/\r\n/, "\n")
			.replace(/\r/, "\n")
			.replace(/\n+/, "\n");
	}

	transform(text: string): string {
		text = NewlineToParagraphsPipe.normalize(
			NewlineToParagraphsPipe.escape(text)
		);

		text = text.replace(/\n/, "</p><p>");

		return "<p>" + text + "</p>";
	}
}
