import { EventEmitter, Injectable } from '@angular/core';
import { AusfuellerBenutzerdatenApiService } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerBenutzerdatenApiService';
import { AusfuellerBenutzerdatenDto } from '@form-viewer/ausfueller-verwaltung/form/AusfuellerBenutzerdatenDto';

@Injectable({providedIn: 'root'})
export class AusfuellerBenutzerdatenService {
	private static readonly HOLE_AUSFUELLER_DATEN_TIMEOUT_MS = 250;
	private static readonly EMAIl_REGEX = /^[^@]+@[^@]+\.[^.]+$/;

	benutzerdatenErkannt = new EventEmitter<AusfuellerBenutzerdatenDto>();
	benutzerdatenUngueltig = new EventEmitter<void>();

	private holeAusfuellerDatenTimer: number | undefined;
	private email: string;

	private benutzerdaten: AusfuellerBenutzerdatenDto;

	private static isEmailValid(email: string): boolean {
		return AusfuellerBenutzerdatenService.EMAIl_REGEX.test(email);
	}

	constructor(private apiService: AusfuellerBenutzerdatenApiService) {
	}

	emailChanged(email: string) {
		if (this.email === email) {
			return;
		}

		this.email = email;
		if (this.benutzerdaten) {

			this.benutzerdatenUngueltig.emit();
			this.benutzerdaten = null;
		}

		clearTimeout(this.holeAusfuellerDatenTimer);
		if (AusfuellerBenutzerdatenService.isEmailValid(this.email)) {
			this.holeAusfuellerDatenTimer = window.setTimeout(
				() => this.holeAusfuellerDaten(),
				AusfuellerBenutzerdatenService.HOLE_AUSFUELLER_DATEN_TIMEOUT_MS);
		}
	}

	reset() {
		clearTimeout(this.holeAusfuellerDatenTimer);
		this.email = null;
		this.benutzerdaten = null;
	}

	get benutzerdatenBearbeitbar(): boolean {
		return this.benutzerdaten && !this.benutzerdaten.bekannt;
	}

	get emailFeedback(): boolean | null {
		if (!AusfuellerBenutzerdatenService.isEmailValid(this.email)) {
			return false;
		}

		if (this.benutzerdaten && this.benutzerdaten.nichtVerfuegbar) {
			return false;
		}

		if (this.benutzerdaten) {
			// Server-Antwort ist da
			return true;
		}

		return null;
	}

	get emailAdresseNichtVerfuegbar(): boolean {
		return this.benutzerdaten && this.benutzerdaten.nichtVerfuegbar;
	}

	private holeAusfuellerDaten() {
		this.benutzerdaten = null;

		this.apiService.ladeBenutzerdaten(this.email).then((benutzerdaten) => {
			this.benutzerdaten = benutzerdaten;
			if (benutzerdaten.bekannt) {
				this.benutzerdatenErkannt.emit(benutzerdaten);
			}
		});
	}
}
