import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IInputTypeViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import { SelectMitSonstigesOptionComponent } from "@shared/components/select/mit-sonstiges-option/SelectMitSonstigesOptionComponent";
import { StyledSelectComponent } from "@shared/components/select/styled/StyledSelectComponent";
import { InputTypeAuswahlAntwort } from '@shared/model/antwort/input/InputTypeAuswahlAntwort';
import { InputTypeAuswahl } from '@shared/model/frage/input/InputTypeAuswahl';

@Component({
	selector: "app-input-type-auswahl-view",
	templateUrl: "./InputTypeAuswahlViewComponent.html",
	styleUrls: ["./InputTypeAuswahlViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgClass, SelectMitSonstigesOptionComponent, StyledSelectComponent],
})
export class InputTypeAuswahlViewComponent
	implements IInputTypeViewComponent<InputTypeAuswahl>
{
	context = {};

	variant: string;

	@Input()
	antwort: InputTypeAuswahlAntwort;

	@Input()
	type: InputTypeAuswahl;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}
}
