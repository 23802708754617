import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IFrageEditComponent } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';
import { KartenFrageEditSpalte } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalte';
import { KartenFrageEditSpalteLinks } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalteLinks';
import { KartenFrageEditSpalteRechts } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalteRechts';
import { KartenFeld } from '@shared/model/frage/KartenFeld';
import { KartenFrage } from '@shared/model/frage/KartenFrage';
import { UUID } from '@util/export-types';
import { KartenFrageEditSpalteComponent } from "./KartenFrageEditSpalteComponent/KartenFrageEditSpalteComponent";
import { KartenFrageEditTitelComponent } from "./KartenFrageEditTitelComponent/KartenFrageEditTitelComponent";

const debug = require('debug')('KartenFrageEditComponent');

@Component({
	selector: "app-editor-abschnitt-frage-edit-karten",
	templateUrl: "./KartenFrageEditComponent.html",
	styleUrls: ["./KartenFrageEditComponent.less"],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
	standalone: true,
	imports: [KartenFrageEditTitelComponent, KartenFrageEditSpalteComponent],
})
export class KartenFrageEditComponent
	implements IFrageEditComponent<KartenFrage>, OnInit
{
	@Input()
	frage: KartenFrage;

	spalteLinks: KartenFrageEditSpalte;
	spalteRechts: KartenFrageEditSpalte;

	constructor() {}

	ngOnInit() {
		this.spalteLinks = new KartenFrageEditSpalteLinks(
			this.frage,
			"links",
			"Linke Spalte"
		);
		this.spalteRechts = new KartenFrageEditSpalteRechts(
			this.frage,
			"rechts",
			"Rechte Spalte"
		);

		if (!this.frage.hasFelder()) {
			this.spalteLinks.addFeld();
			this.spalteRechts.addFeld();
		}
	}

	private findFeld(id: UUID): KartenFeld {
		const matchedLinks = this.spalteLinks.findFeldById(id);
		const matchedRechts = this.spalteRechts.findFeldById(id);

		if (matchedLinks) {
			return matchedLinks;
		} else if (matchedRechts) {
			return matchedRechts;
		} else {
			return null;
		}
	}
}
