import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Ausfueller } from '@form-viewer/model/Ausfueller';
import { AusfuellerBerechtigungsService } from '@form-viewer/service/AusfuellerBerechtigungsService';
import { UserService } from '@form-viewer/service/UserService';
import { Store } from '@ngrx/store';
import { AlertService } from '@shared/alert/service/AlertService';
import { NavigationService } from '@shared/service/NavigationService';
import { OAuthService } from 'angular-oauth2-oidc';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';

@Component({
			   selector: 'app-init-page',
			   templateUrl: './InitPageComponent.html',
			   styleUrls: ['../content-page.less', '../../../website-styles/content-page/_portal-meta.less'],
	standalone: true,
		   })
export class InitPageComponent {

	public ausfueller: Ausfueller;
	public initDone: boolean = false;
	@BlockUI() blockUI: NgBlockUI;

	constructor(private elementRef: ElementRef,
				private userService: UserService,
				private title: Title,
				private router: Router,
				private httpClient: HttpClient,
				private alertService: AlertService,
				private store: Store,
				private messageService: MessageService,
				private navigationService: NavigationService,
				private oauthService: OAuthService,
				private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService) {
		title.setTitle('Portal - Juve Recherche');
		this.initDone = false;
		this.init();
	}

	init(): void {
		try {
			console.log('Daten werden initial geladen...');

			this.blockUI.start('Benutzerdaten werden geladen...');
			this.userService.isAliveInitPage();
			this.loadUserData();

			this.blockUI.stop();

		} catch (exception) {
			alert(exception);

			this.blockUI.start('FEHLER ' + exception);
		}
	}

	loadUserData() {

		console.log('#############################');
		console.log('loadUserData');
		console.log('#############################');

		this.userService.authenticate().then(() => {
			if (this.userService.isEnabled()) {
				if (this.userService.isAutor()) {
					try {
						console.log('NAVIGATE...To EDITOR');
						this.blockUI.stop();
						this.blockUI.resetGlobal();
						this.router.navigate(['/editor/fragebogen']);
					} catch (exception) {
						alert(exception);
						this.blockUI.start('FEHLER ' + exception);
					}
				} else {
					try {
						this.blockUI.start('Benutzerdaten werden verarbeitet...');
						this.userService.collectSichtbareInstitutionen();
						this.userService.getSichtbareInstitutionen();
						this.userService.getAktuelleInstitution();
					} catch (exception) {
						alert(exception);
						this.blockUI.start('FEHLER ' + exception);
					}
					finally {
						setTimeout(() => {
							try {
								this.userService.entsperrenForUser();
								console.log('NAVIGATE...To Portal');
								this.router.navigate(['/portal-allgemein']);
							} catch (exception) {
								alert(exception);
								this.blockUI.start('FEHLER ' + exception);
							}
						}, 1500);
					}
				}
			} else {
				this.blockUI.start('Benutzer ist nicht registriert. Sie werden ausgeloggt...');
			}
		})
			.catch(this.alertService.handleHttpError)
			.finally();
	}
}
