import { Antwort } from "@shared/model/Antwort";
import { removeElement } from '@util/removeElement';
import * as _ from 'underscore';

export class MehrfachauswahlAntwort extends Antwort {
	static override readonly FRAGEN_TYPE_ID = 'MehrfachauswahlFrage';

	antworten: string[] = [];

	constructor(antwort?: MehrfachauswahlAntwort) {
		super(antwort);
		if (antwort) {
			if (antwort.antworten) {
				this.antworten = antwort.antworten.slice();
			} else {
				this.antworten = [];
			}
		}
	}

	istGewaehlt(option: string): boolean {
		return _.contains(this.antworten, option);
	}

	isEmpty() {
		return this.antworten == null || this.antworten.length === 0;
	}

	push(option: string) {
		this.antworten.push(option);
	}

	remove(option: string) {
		removeElement(this.antworten, option);
	}

	istInhaltlichGleich(o: Antwort): boolean {
		return this.antworten.sort() === (o as MehrfachauswahlAntwort).antworten.sort();
	}
}
