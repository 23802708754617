import {NgFor, NgTemplateOutlet} from '@angular/common';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ScrollToFocusedElementDirective} from '@form-viewer/util/ScrollToFocusedElementDirective';
import {StyledSelectComponent} from '@shared/components/select/styled/StyledSelectComponent';
import {TickService} from '@shared/service/TickService';
import {isDefined} from '@util/isDefined';
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";
import * as _ from 'underscore';

/**
 * Select-Komponente mit anpassbaren Option-Templates und der möglichkeit, "Sonstiges" zu wählen und einzugeben
 *
 * <app-select
 *   [(wert)]="wertVariableOderSetter"
 *   [options]="getOptions()"
 *   id="some-id"
 * ></app-select>
 *
 * um ein eigenes Template für den Inhalt anzugeben muss folgende Syntax verwendet werden:
 *
 * <app-select …>
 *   <ng-template let-option #option>
 *     <em *ngIf="!option">nichts gewählt</em>
 *     <ng-container *ngIf="option">
 *       <strong>{{ option?.title }}</strong>
 *        <div class="badge">{{ option?.count }}</div>
 *     </ng-container>
 *   </ng-template>
 * </app-select>
 *
 * um das Select mit Instanzen einer eigenen Klassen als [options] benutzen zu können, muss ein  optionGenerator angegeben werden.
 * Dieser hat folgende Signatur:
 *
 *   (sonstigesWert: string, vorherigeOption: null|any): any
 *
 * Als erstes Argument wird der Text-Wert aus dem "Sonstiges"-Textfeld übergeben, als zweites, optional, eine durch einen vorherigen Aufruf
 * des Generators erzeugte Instanz. Wird hier null übergeben muss der Generator eine neue Instanz erstellen und zurückgeben. Wird hier
 * nicht-null übergeben ist dies garantiert eine zuvor vom Generator erzeugte Instanz die modifiziert und zurückgegeben werden kann. Dadurch
 * kann verhindert werden, dass bei jedem getippten Buchstaben eine neue Instanz erzeugt werden muss.
 *
 * Die verwendete VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto sollte toString() so implementieren, dass der zurückgegebene Wert der inversen
 * Aktivität des optionsGenerators entspricht, so dass folgendes gültig ist:
 *
 *   optionsGenator(X).toString() == X
 *
 *
 * Diese Eigenschaft wird bemötigt, wenn von außen, `wert` auf eine solche Instanz gesetzt wird. Das "Sonstiges"-Textfeld wird dann mit dem
 * Ergbnis eines Aufrufs von `toString()` gefüllt.
 */
@Component({
	selector: "app-select-mit-sonstiges-option",
	templateUrl: "./SelectMitSonstigesOptionComponent.html",
	styleUrls: ["./SelectMitSonstigesOptionComponent.less"],
	standalone: true,
	imports: [FormsModule, NgFor, ScrollToFocusedElementDirective, NgTemplateOutlet, PreventDefaultDirective],
})
export class SelectMitSonstigesOptionComponent
	extends StyledSelectComponent
	implements OnInit
{
	readonly SONSTIGES_MARKER = "__SONSTIGES__";
	readonly SONSTIGES_LABEL = "Sonstiges";

	private _selectedWert: any = null;
	private _sonstigesWert: any = null;

	@ViewChild("textInput", { static: true })
	textInput: ElementRef<HTMLInputElement>;

	private _generatedSonstigesOption: any = null;

	sonstigesOption: any = null;

	@Input()
	optionGenerator: (string, any) => any = this.optionIdentityGenerator;

	ngOnInit(): void {
		this.sonstigesOption = this.optionGenerator(this.SONSTIGES_LABEL, null);
		this._generatedSonstigesOption = this.optionGenerator("", null);
	}

	optionIdentityGenerator(sonstigesWert: string): any {
		return sonstigesWert;
	}

	override get selectedWert(): any {
		return this._selectedWert;
	}

	override set selectedWert(selectedWert: any) {
		this._selectedWert = selectedWert;

		if (this.isSonstigesMarker(selectedWert)) {
			TickService.onNextTick(() => {
				this.textInput.nativeElement.focus();
			});
		}

		this.wertChanged();
	}

	get sonstigesWert(): any {
		return this._sonstigesWert;
	}

	set sonstigesWert(sonstigesWert: any) {
		if (this._sonstigesWert !== sonstigesWert) {
			this._sonstigesWert = sonstigesWert;
			this.updateGeneratedSonstigesOption();
		}

		this.wertChanged();
	}

	private updateGeneratedSonstigesOption() {
		this._generatedSonstigesOption = this.optionGenerator(
			this._sonstigesWert,
			this._generatedSonstigesOption
		);
	}

	override get wert(): any {
		if (this.sonstigesTextfeldSichtbar) {
			return this._generatedSonstigesOption;
		} else {
			return this._selectedWert;
		}
	}

	@Input()
	override set wert(wert: any) {
		if (this.wert === wert) {
			return;
		}

		if (!isDefined(wert) || wert === "" || _.contains(this.options, wert)) {
			this._selectedWert = wert;
			this._sonstigesWert = "";
			this.updateGeneratedSonstigesOption();
		} else {
			this._selectedWert = this.SONSTIGES_MARKER;
			this._sonstigesWert = wert.toString();
			this._generatedSonstigesOption = wert;
		}
	}

	get sonstigesTextfeldSichtbar(): boolean {
		return this.isSonstigesMarker(this._selectedWert);
	}

	get sonstigesTextfeldFocused(): boolean {
		return document.activeElement === this.textInput.nativeElement;
	}

	private isSonstigesMarker(wert: string): boolean {
		return wert === this.SONSTIGES_MARKER;
	}

	get displayOption(): any {
		return this.sonstigesTextfeldSichtbar
			? this.sonstigesOption
			: this.selectedWert;
	}

	override onWertSelected(wert: any): void {
		this.selectedWert = wert;
		this.close();
	}

	override isWertSelected(wert: any): boolean {
		return wert === this.selectedWert;
	}

	override onKeyUp(event: KeyboardEvent) {
		if (this.sonstigesTextfeldFocused) {
			return;
		} else {
			super.onKeyUp(event);
		}
	}

	protected override getSelectedWertForIndex(index: number): string {
		if (index >= this.options.length) {
			return this.SONSTIGES_MARKER;
		} else {
			return this.options[this.limitOptionIndex(index)];
		}
	}

	protected override indexOfWert(wert: string): number {
		if (this.isSonstigesMarker(wert)) {
			return this.options.length;
		} else {
			return this.options.indexOf(wert);
		}
	}

	protected override selectWertViaKeyboard(wert: string): void {
		this.selectedWert = wert;

		if (this.isSonstigesMarker(wert)) {
			this.close();
		}
	}

	protected override numberOfSelectOptions(): number {
		return this.options.length + 1;
	}
}
