import { Antwort } from './Antwort';
import { EntscheidungsfragenAntwort } from './antwort/EntscheidungsfragenAntwort';
import { KartenfragenAntwort } from './antwort/KartenfragenAntwort';
import { MehrfachauswahlAntwort } from './antwort/MehrfachauswahlAntwort';
import { TabellenfragenAntwort } from './antwort/TabellenfragenAntwort';
import { TextfeldfragenAntwort } from './antwort/TextfeldfragenAntwort';
import { Frage } from './Frage';
import { EntscheidungsFrage } from './frage/EntscheidungsFrage';
import { KartenFrage } from './frage/KartenFrage';
import { MehrfachauswahlFrage } from './frage/MehrfachauswahlFrage';
import { TabellenFrage } from './frage/TabellenFrage';
import { TextfeldFrage } from './frage/TextfeldFrage';

const debug = require('debug')('model:FrageTypes');

export class FrageTypes {
	static readonly TYPES: typeof Frage[] = [
		TextfeldFrage,
		EntscheidungsFrage,
		TabellenFrage,
		KartenFrage,
		MehrfachauswahlFrage,
	];

	static readonly ANTWORT_TYPES: (new(antwort?: Antwort) => Antwort)[] = [
		TextfeldfragenAntwort,
		EntscheidungsfragenAntwort,
		TabellenfragenAntwort,
		KartenfragenAntwort,
		MehrfachauswahlAntwort
	];

	static typeForID(id: string): typeof Frage {
		const typeClass = FrageTypes.TYPES.find(type => type.ID === id);
		if (!typeClass) {
			debug('Fragentyp nicht definiert: ', id);
		}

		return typeClass;
	}

	static antwortTypeForID(id: string): new(antwort?: Antwort) => Antwort {
		const typeClass = FrageTypes.ANTWORT_TYPES.find(type => type['FRAGEN_TYPE_ID'] === id);
		if (!typeClass) {
			debug('Antworttyp nicht definiert: ', id);
		}

		return typeClass;
	}
}
