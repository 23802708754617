import { InputTypeCheckboxViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-type-checkbox-view/InputTypeCheckboxViewComponent';
import { InputTypeCheckboxAntwort } from "@shared/model/antwort/input/InputTypeCheckboxAntwort";
import { InputType } from "@shared/model/frage/input/InputType";

export class InputTypeCheckbox extends InputType {
	static override readonly ID = 'InputTypeCheckbox';
	static override readonly ANTWORT = InputTypeCheckboxAntwort;

	static override readonly TYPE_DISPLAY_NAME = 'Checkbox';

	static override readonly VIEWER = InputTypeCheckboxViewComponent;
	static override readonly EDITOR = null;
}
