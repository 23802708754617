import {NgFor, NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {AusfuellerVerwaltungDialogButtonComponent} from '@form-viewer/ausfueller-verwaltung/button/AusfuellerVerwaltungDialogButtonComponent';
import {Ausfueller} from '@form-viewer/model/Ausfueller';
import {AusfuellerBerechtigungsService} from '@form-viewer/service/AusfuellerBerechtigungsService';
import {Fragebogen} from '@shared/model/Fragebogen';
import {AktuelleBerechtigungService} from '@shared/service/AktuelleBerechtigungService';
import {NavigationService} from '@shared/service/NavigationService';
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
	selector: "app-fragebogen-liste",
	templateUrl: "./FragebogenListeViewComponent.html",
	styleUrls: ["./FragebogenListeViewComponent.less"],
	standalone: true,
	imports: [
		NgFor, RouterLink, AusfuellerVerwaltungDialogButtonComponent, NgIf, PreventDefaultDirective
	]
})
export class FragebogenListeViewComponent {
	@BlockUI()
	blockUI: NgBlockUI;

	@Input()
	frageboegen: Fragebogen[];

	@Input()
	ausfueller: Ausfueller;

	@Input()
	recherchekomplexTitel: string;

	constructor(
		private aktuelleBerechtigungService: AktuelleBerechtigungService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
		private navigationService: NavigationService
	) {
		this.ausfuellerBerechtigungsService.clearCache();
	}

	isAdministrator(): boolean {
		return this.ausfuellerBerechtigungsService.istAdministrator();
	}

	isCoAdministrator(): boolean {
		return this.ausfuellerBerechtigungsService.istCoAdministrator();
	}

	isFragebbogenAusfuellerFuer(fragebogen: Fragebogen): boolean {
		return this.ausfuellerBerechtigungsService.istFragebbogenAusfuellerFuer(
			fragebogen.id
		);
	}

	getRechteLabel(fragebogen) {
		if (this.isAdministrator()) {
			return "Recherchekomplex-Administrator";
		} else if (this.isCoAdministrator()) {
			return "CoAdministrator";
		} else if (this.isFragebbogenAusfuellerFuer(fragebogen)) {
			return "Fragebogenausfüller";
		} else {
			return "Mit den aktuellen Berechtigungen können Sie den Fragebogen nur teilweise bearbeiten!";
		}
	}

	goToFragebogen(fragebogen: Fragebogen) {
		this.navigationService.goToViewerFragebogen(fragebogen);
	}

	darfFragebogenausfuellerVerwalten() {
		return this.ausfuellerBerechtigungsService.darfFragebogenausfuellerVerwalten();
	}

	onNavigationClicked() {
		try {
			this.blockUI.start("Daten werden verarbeitet...");
		} finally {
			this.blockUI.stop();
		}
	}
}
