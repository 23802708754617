import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { UserService } from "../form-viewer/service/UserService";

@Injectable()
export class AuthGuardEditor {

	constructor(private userService: UserService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (route.url.toString().includes('/ansprechpartner-login')){
			console.log('AuthGuardEditor returning for url ansprechpartner-login')
			return this.router.navigate(['/ansprechpartner-login']);
		}

		if (this.userService.isAutor()) {
			return true
		} else {
			return this.router.navigate(['/403'])
		}
	}

}
