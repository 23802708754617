import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { AlertService } from "../../shared/alert/service/AlertService";
import { RecherchekomplexBenutzerDto } from "../model/RecherchekomplexBenutzerDto";
import { RecherchekomplexUebersichtDto } from "../model/RecherchekomplexUebersichtDto";

@Injectable({providedIn: 'root'})
export class RecherchekomplexUebersichtService {

  constructor(private httpClient: HttpClient, private alertService: AlertService) { }

	holeRecherchekomplexUebersicht(): Promise<RecherchekomplexUebersichtDto[]> {

	  return this.httpClient.get<RecherchekomplexUebersichtDto[]>('view/recherchekomplex/uebersicht')
		  .toPromise()
		  .catch(this.alertService.handleHttpError)
		  .then(json => RecherchekomplexUebersichtDto.fromList(json));
	}

	holeRecherchekomplexBenutzer(): Promise<RecherchekomplexBenutzerDto[]> {

	  return this.httpClient.get<RecherchekomplexBenutzerDto[]>('view/recherchekomplex/benutzer')
		  .toPromise()
		  .catch(this.alertService.handleHttpError)
		  .then(json => RecherchekomplexBenutzerDto.fromList(json));
	}
}
