import {NgIf} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {AusfuellerService} from "@benutzerverwaltung/services/AusfuellerService";
import {UserService} from "@form-viewer/service/UserService";
import {select, Store} from "@ngrx/store";
import {Recherchekomplex} from "@shared/model/Recherchekomplex";
import {selectRecherchekomplex} from "@store/selector/recherchekomplex.selectors";

@Component({
	selector: 'app-header',
	templateUrl: './HeaderComponent.html',
	styleUrls: ['./header.less'],
	standalone: true,
	imports: [NgIf, RouterLink]
})
export class HeaderComponent implements OnInit {
	@Input() style: string
	recherchekomplex: Recherchekomplex
	istRecherchekomplexAdmin: boolean;
	istRecherchekomplexCoAdmin: boolean;

	constructor(public ausfuellerService: AusfuellerService, public userService: UserService, private store: Store) {
	}

	async ngOnInit(): Promise<void> {
		this.istRecherchekomplexAdmin = await this.userService.getAusfueller()?.istRecherchekomplexAdmin()
		this.ausfuellerService.isAnyCoAdminByID(this.userService.getAusfueller().id).then(returnVal => {
				this.istRecherchekomplexCoAdmin = returnVal;
			}
		);
		this.store.pipe(select(selectRecherchekomplex)).subscribe(value => this.recherchekomplex = value)
	}

	showTeam(): boolean {
		return this.recherchekomplex
			&& ['Top-Arbeitgeber für Juristen 2023',
				'Top-Arbeitgeber im Steuermarkt 2023',
				'Top-Arbeitgeber im Steuermarkt 2024',
				'Top-Arbeitgeber im Steuermarkt 2025',
				'JUVE Handbuch Steuern 2025. Teil 1',
				'JUVE Handbuch Steuern 2025. Teil 2',
				'JUVE Handbuch Steuern 2025. Teil 3',
				'JUVE Handbuch Wirtschaftskanzleien 2025/26 Teil 1',
				'JUVE Handbuch Wirtschaftskanzleien 2025/26 Teil 2',
				'JUVE Handbuch Wirtschaftskanzleien 2025/26 Teil 3',
				'Personal- und Finanzkennzahlen 2024/25',
				'Personal- und Finanzkennzahlen Steuern 2024/25'].indexOf(this.recherchekomplex.name) == -1
	}
}
