<ul class="list-group">
	<li class="list-group-item" *ngFor="let option of options; trackBy: trackByFn; let index = index">
		<div class="form-group has-feedback">
			<input class="form-control" [(ngModel)]="options[index]">
			<span title="Option löschen" (click)="removeOption(index)" class="form-control-feedback">&times;</span>
		</div>
	</li>
	<li *ngIf="sonstigesTextfeld" class="list-group-item">
		<div class="sonstigesOption">Sonstiges</div>
	</li>
</ul>
<div class="checkbox abc-checkbox">
	<input type="checkbox" [(ngModel)]="sonstigesTextfeld" id="{{ 'sonstiges' | newID }}">
	<label for="{{ 'sonstiges' | lastID }}">
		Freitext-Option „Sonstiges“ anbieten
	</label>
</div>
<button class="btn btn-default add" (click)="addOption()" type="button">Option Hinzufügen</button>
