import { Abschnitt } from '@shared/model/Abschnitt';
import { Fragebogen } from '@shared/model/Fragebogen';
import { Institution } from '@shared/model/Institution';
import { Recherchekomplex } from '@shared/model/Recherchekomplex';
import { UUID } from '@util/export-types';

export class Berechtigung {
	type: string;

	recherchekomplex: Recherchekomplex;
	institution: Institution;

	fuerFragebogen: Fragebogen;
	fuerAbschnitt: Abschnitt;

	abgabefrist: string;
	darfFrageboegenAufUndAbschliessen = false;

	constructor(berechtigung?: Berechtigung) {
		if (berechtigung) {
			this.type = berechtigung.type;

			this.institution = new Institution(berechtigung.institution);
			this.recherchekomplex = new Recherchekomplex(berechtigung.recherchekomplex);

			this.fuerAbschnitt = berechtigung.fuerAbschnitt ? new Abschnitt(berechtigung.fuerAbschnitt) : null;
			this.fuerFragebogen = berechtigung.fuerFragebogen ? new Fragebogen(berechtigung.fuerFragebogen) : null;

			this.abgabefrist = berechtigung.abgabefrist;
			this.darfFrageboegenAufUndAbschliessen = berechtigung.darfFrageboegenAufUndAbschliessen;

			this._recherchekomplexAdmin = berechtigung._recherchekomplexAdmin;
		}
	}

	private _recherchekomplexAdmin: boolean

	get recherchekomplexAdmin(): boolean {
		return this._recherchekomplexAdmin
			|| this.type == "CO_ADMIN"
			|| this.type == "RECHERCHEKOMPLEX_ADMIN";
	}

	gehoertZu(institutionId: UUID, recherchekomplexId: UUID) {
		return this.institution.id === institutionId && this.recherchekomplex.id === recherchekomplexId;
	}
}
