import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: "app-xsrf-form-field",
	templateUrl: "./XsrfFormFieldComponent.html",
	standalone: true,
})
export class XsrfFormFieldComponent implements OnInit {
	private tokenExtractor: HttpXsrfTokenExtractor;
	token: string;

	constructor(tokenExtractor: HttpXsrfTokenExtractor) {
		this.tokenExtractor = tokenExtractor;
	}

	ngOnInit() {
		this.token = this.tokenExtractor.getToken();
	}
}
