import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SichtbareInstitutionDto } from '@portal-selector/model/SichtbareInstitutionDto';
import { StyledSelectComponent } from '@shared/components/select/styled/StyledSelectComponent';
import { UserService } from "../form-viewer/service/UserService";

@Component({
	selector: "app-portal-institution-selector",
	templateUrl: "./PortalInstitutionSelectorComponent.html",
	styleUrls: ["./PortalInstitutionSelectorComponent.less"],
	standalone: true,
	imports: [NgIf, StyledSelectComponent]
})
export class PortalInstitutionSelectorComponent implements OnInit {
	@Input()
	institution: SichtbareInstitutionDto[] = [];

	constructor(private userService: UserService) {}

	@Output()
	aktuelleInstitutionChange = new EventEmitter<SichtbareInstitutionDto>();

	@Input() private _aktuelleInstitution: SichtbareInstitutionDto;

	get aktuelleInstitution(): SichtbareInstitutionDto {
		return this._aktuelleInstitution;
	}

	set aktuelleInstitution(value: SichtbareInstitutionDto) {
		this._aktuelleInstitution = value;
	}

	aktuelleInstitutionChanged(value: SichtbareInstitutionDto) {
		this._aktuelleInstitution = value;
		this.aktuelleInstitutionChange.emit(value);
		this.userService.setAktuelleInstitution(value.institution);
		this.userService.setAktuelleSichtbareInstitutionDto(value);
	}

	ngOnInit(): void {
		let currentInst = this.userService.getAktuelleInstitution();

		for (const sichtbareInstitutionDto of this.institution) {
			if (sichtbareInstitutionDto.institution.id == currentInst.id) {
				this._aktuelleInstitution = sichtbareInstitutionDto;
			}
		}
	}
}
