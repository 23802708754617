import { Component } from '@angular/core';
import { ContactComponent } from "@shared/components/contact/ContactComponent";
import { RegistrierungsFormularComponent } from "./RegistrierungsFormularComponent";

@Component({
	selector: "app-registrierungs-wrapper",
	templateUrl: "./RegistrierungsWrapperComponent.html",
	styleUrls: ["RegistrierungsWrapperComponent.less"],
	standalone: true,
	imports: [RegistrierungsFormularComponent, ContactComponent]
})
export class RegistrierungsWrapperComponent {}
