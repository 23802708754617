import { ArrayUtil } from '@shared/util/ArrayUtil';
import { DateFormatterUtil } from '@shared/util/DateFormatterUtil';

export class HttpErrorUtil {
	static formatDatePrefix(): string {
		return DateFormatterUtil.transformToDateAndTime(new Date()) + ': ';
	}

	static formatTechnicalDescription(error: any) {
		return '<code>' +
			'<span>Technische Beschreibung:</span> ' +
			error.status + ' ' + error.error + ': ' + error.exception + '<br>' +
			error.message +
			'</code>';
	}

	static formatValidationResults(validationResults: any[]): string {
		if (!ArrayUtil.isNonEmptyArray(validationResults)) {
			return '';
		}

		return '<ul>' +
			validationResults
				.map(validationResult => '<li>' + validationResult.propertyPath + ': ' + validationResult.message + '</li>')
				.join('') +
			'</ul>';
	}

	static formatAnnotationValidationResults(errorBody: any): string {
		if (!ArrayUtil.isNonEmptyArray(errorBody.errors)) {
			return '';
		}

		return '<ul>' +
			errorBody.errors
				.map(error => '<li>' + error.field + ': ' + error.defaultMessage + '</li>')
				.join('') +
			'</ul>';
	}
}
