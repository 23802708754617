import { SafeHtml } from "@angular/platform-browser";
import { InputTypeAntwort } from "@shared/model/antwort/input/InputTypeAntwort";
import { InputTypeTextarea } from "@shared/model/frage/input/InputTypeTextarea";

export class InputTypeTextareaAntwort extends InputTypeAntwort<InputTypeTextarea> {
	wert = '';

	constructor(antwort?: InputTypeTextareaAntwort) {
		super(antwort);

		if (antwort) {
			this.wert = antwort.wert;
		}
	}

	getType() {
		return InputTypeTextarea.ID;
	}

	isEmpty(): boolean {
		return !this.wert || this.wert.length === 0;
	}

	getHtml(): SafeHtml {
		return `<span class='antwort-multiline'>${this.wert.trim()}</span>`;
	}

	istInhaltlichGleich(o: InputTypeAntwort<any>): boolean {
		return this.wert.trim() === (o as InputTypeTextareaAntwort).wert.trim();
	}
}
