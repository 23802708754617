import { UUID } from '@util/export-types';
import { Recherchekomplex } from './Recherchekomplex';

export class Fragebogen {
	id: UUID;
	name = '';
	veroeffentlicht: boolean;
	recherchekomplex: Recherchekomplex;
	vorjahresfragebogen: Fragebogen = undefined;
	beschreibungLang = '';
	beschreibungKurz = '';
	abgeschlossen: boolean;
	hatRechte: boolean;

	constructor(fragebogen?: Fragebogen) {

		this.recherchekomplex = new Recherchekomplex();

		if (fragebogen) {
			this.id = fragebogen.id;
			this.name = fragebogen.name;
			this.veroeffentlicht = fragebogen.veroeffentlicht;

			if (fragebogen.recherchekomplex) {
				this.recherchekomplex = new Recherchekomplex(fragebogen.recherchekomplex);
			}

			this.vorjahresfragebogen = fragebogen.vorjahresfragebogen;

			this.beschreibungLang = fragebogen.beschreibungLang;
			this.beschreibungKurz = fragebogen.beschreibungKurz;

			this.abgeschlossen = fragebogen.abgeschlossen;
		}
	}

	kannRkNochAusgefuelltWerden(): boolean {
		return this.recherchekomplex.kannNochAusgefuelltWerden();
	}

	kannRkWiedereroeffnetWerden(): boolean {
		return this.recherchekomplex.kannWiedereroeffnetWerden();
	}

	hatVorjahresfragebogen() {
		return !!this.vorjahresfragebogen;
	}
}
