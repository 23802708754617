import { AusfuellerAnlegenDto } from '@form-viewer/ausfueller-verwaltung/model/AusfuellerAnlegenDto';
import { AusfuellerType } from '@form-viewer/model/AusfuellerType';
import { UUID } from '@util/export-types';

export class FragebogenausfuellerAnlegenDto extends AusfuellerAnlegenDto {
	type: AusfuellerType = AusfuellerType.Fragebogenausfueller;

	fuerFragebogen: UUID;
	abgabefrist = '';
}
