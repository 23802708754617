import { KartenFeldAntwort } from '@shared/model/antwort/KartenFeldAntwort';
import { KartenFeld } from '@shared/model/frage/KartenFeld';

export class KartenFeldAntwortTupel {
	feld: KartenFeld;
	antwort: KartenFeldAntwort;

	constructor(feld: KartenFeld, antwort: KartenFeldAntwort) {
		this.feld = feld;
		this.antwort = antwort;
	}
}
