import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { TabellenZeileView } from '@form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabellenZeileView';
import { TabelleViewComponentBase } from '@form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabelleViewComponentBase';
import { InfoTextViewComponent } from "@form-viewer/shared/info-text/InfoTextViewComponent";
import { InputViewComponent } from "../../input/input-view/InputViewComponent";

@Component({
	selector: "app-tabelle-erweiterbar-view",
	templateUrl: "./TabelleErweiterbarViewComponent.html",
	styleUrls: [
		"./TabelleErweiterbarViewComponent.less",
		"../tabelle-view.shared.less",
	],
	standalone: true,
 imports: [InfoTextViewComponent, InputViewComponent, NgIf, NgFor],
})
export class TabelleErweiterbarViewComponent extends TabelleViewComponentBase {
	addZeile(): void {
		this.tabellenView.addZeile();
	}

	removeZeile(zeile: TabellenZeileView): void {
		if (!this.istZeileLoeschbar()) {
			return;
		}

		this.tabellenView.removeZeile(zeile);
		if (this.tabellenView.isEmpty()) {
			this.addZeile();
		}
	}

	spaltenAnzahlMitControls(): number {
		return this.tabellenFrage.anzahlSpalten() + 1;
	}

	istZeileLoeschbar() {
		if (this.tabellenView.getAnzahlZeilen() === 1) {
			const einzigeZeile = this.tabellenView.getZeilenViews()[0];
			if (einzigeZeile.isEmpty()) {
				return false;
			}
		}

		return true;
	}

	protected afterTabellenviewInitialized() {
		if (this.tabellenView.isEmpty()) {
			this.addZeile();
		}
	}
}
