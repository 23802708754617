import { SafeHtml } from "@angular/platform-browser";
import { InputTypeAntwort } from "@shared/model/antwort/input/InputTypeAntwort";
import { InputTypeMehrfachauswahl } from "@shared/model/frage/input/InputTypeMehrfachauswahl";

export class InputTypeMehrfachauswahlAntwort extends InputTypeAntwort<InputTypeMehrfachauswahl> {
	wert: string[] = [];

	constructor(antwort?: InputTypeMehrfachauswahlAntwort) {
		super(antwort);

		if (antwort) {
			this.wert = antwort.wert.slice(0);
		}
	}

	getType() {
		return InputTypeMehrfachauswahl.ID;
	}

	isEmpty(): boolean {
		return !this.wert || this.wert.length === 0;
	}

    getHtml(): SafeHtml {
		return this.wert.join(', ');
	}

	istInhaltlichGleich(o: InputTypeAntwort<any>): boolean {
		return this.wert === (o as InputTypeMehrfachauswahlAntwort).wert;
	}
}
