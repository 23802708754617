import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@shared/alert/service/AlertService';
import { Fragebogen } from '@shared/model/Fragebogen';

@Injectable({ providedIn: "root" })
export class FragebogenVeroeffentlichenService {
	constructor(private http: HttpClient, private alertService: AlertService) {}

	veroeffentlichen(fragebogen: Fragebogen): Promise<any> {
		return this.http
			.post(
				"/api/editor/fragebogen/" + fragebogen.id + "/veroeffentlichen",
				{}
			)
			.toPromise()
			.catch(this.alertService.handleHttpError);
	}
}
