import { KartenFrageEditSpalte } from '@form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalte';
import { KartenFeld } from '@shared/model/frage/KartenFeld';

export class KartenFrageEditSpalteRechts extends KartenFrageEditSpalte {

	getFelder(): KartenFeld[] {
		return this.frage.felderRechts;
	}

	addFeld(): void {
		this.frage.addFeldRechts();
	}

	removeFeld(feld: KartenFeld): void {
		this.frage.removeFeldRechts(feld);
	}
}
