import { Type } from '@angular/core';
import { UUID } from '@util/export-types';
import { Antwort } from "./Antwort";

export class Frage {
	static readonly ID: string;
	static readonly EDITOR: Type<any>;
	static readonly VIEWER: Type<any>;

	id: UUID;
	ueberschrift = '';
	beschreibung = '';
	abschnitt_id: UUID;
	type: string;
	sortIndex: number;
	inhaltlichGeaendert: boolean;
	vorjahresfrage: Frage;

	neuesteAntwort: Antwort;
	aeltesteAntwort: Antwort;

	constructor(frage ?: Frage) {
		if (frage) {
			this.constructFromFrage(frage);
		}
	}

	typeIconName() {
		return '';
	}

	typeDisplayName() {
		return '';
	}

	clearIds() {
		this.id = null;
		this.abschnitt_id = null;
	}

	isExportableAsCsv() {
		return false;
	}

	istVerlinkt() {
		return !!this.vorjahresfrage && !this.inhaltlichGeaendert;
	}

	istGefuellt(): boolean {
		return !!this.neuesteAntwort;
	}

	getDiffClass() {
		if (!this.aeltesteAntwort || !this.aeltesteAntwort.id || !this.neuesteAntwort || !this.neuesteAntwort.id) {
			return ''
		}

		return this.neuesteAntwort.istInhaltlichGleich(this.aeltesteAntwort) ? 'diffGleich' : 'diffUngleich'
	}

	private constructFromFrage(frage: Frage) {
		this.id = frage.id;
		this.ueberschrift = frage.ueberschrift;
		this.beschreibung = frage.beschreibung;
		this.abschnitt_id = frage.abschnitt_id;
		this.type = frage.type;
		this.sortIndex = frage.sortIndex;
		this.inhaltlichGeaendert = frage.inhaltlichGeaendert;
		this.vorjahresfrage = frage.vorjahresfrage;

		this.neuesteAntwort = frage.neuesteAntwort;
		this.aeltesteAntwort = frage.aeltesteAntwort;
	}
}
