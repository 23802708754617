import { InputTypeTextViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-type-text-view/InputTypeTextViewComponent';
import { InputTypeTextEditComponent } from "@shared/components/input-type/text/InputTypeTextEditComponent";
import { InputTypeTextAntwort } from "@shared/model/antwort/input/InputTypeTextAntwort";
import { InputType } from "@shared/model/frage/input/InputType";

export class InputTypeText extends InputType {
	static override readonly ID = 'InputTypeText';
	static override readonly TYPE_DISPLAY_NAME = 'Text';
	static override readonly ANTWORT = InputTypeTextAntwort;

	static override readonly VIEWER = InputTypeTextViewComponent;
	static override readonly EDITOR = InputTypeTextEditComponent;

	platzhalterText = '';

	constructor(type?: InputTypeText) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
		}
	}
}
