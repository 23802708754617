import { SichtbarerRecherchekomplexDto } from '@portal-selector/model/SichtbarerRecherchekomplexDto';
import { Institution } from '@shared/model/Institution';

export class SichtbareInstitutionDto {
	institution: Institution;
	recherchekomplexe: SichtbarerRecherchekomplexDto[];

	static buildList(dtos: any[]): SichtbareInstitutionDto[] {
		return dtos.map(dto => new SichtbareInstitutionDto(dto));
	}

	constructor(dto?: SichtbareInstitutionDto) {
		if (dto) {
			this.institution = new Institution(dto.institution);
			this.recherchekomplexe = dto.recherchekomplexe.map(rk => new SichtbarerRecherchekomplexDto(rk));
		}
	}
}
