import {NgClass, NgIf} from '@angular/common';
import {Component, Input, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ScrollToFocusedElementDirective} from "@form-viewer/util/ScrollToFocusedElementDirective";
import {InputTypeEmailAntwort} from "@shared/model/antwort/input/InputTypeEmailAntwort";
import {InputTypeEmail} from "@shared/model/frage/input/InputTypeEmail";
import {IInputTypeViewComponent} from '../input-view/IInputTypeView';

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Vollflächiges Eingabefeld ohne Rand zum Einbinden in Tabellenzellen
 */
@Component({
	selector: "app-input-type-email-view",
	templateUrl: "./InputTypeEmailViewComponent.html",
	styleUrls: ["./InputTypeEmailViewComponent.less"],
	standalone: true,
	imports: [NgIf, NgClass, FormsModule, ScrollToFocusedElementDirective],
})
export class InputTypeEmailViewComponent
	implements IInputTypeViewComponent<InputTypeEmail>
{
	context = {};

	variant: string;

	@ViewChild("tooltip") tooltip;

	@Input()
	antwort: InputTypeEmailAntwort;

	@Input()
	type: InputTypeEmail;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	isValid() {
		return this.antwort.isValid();
	}

	getPlatzhalterText(): string {
		return this.type.platzhalterText;
	}

	getRegex() {
		return "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]*$";
		//		return "^\\S+@\\S+$";
	}

	variantClass(): string {
		return this.variant;
	}
}
