import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from '@shared/alert/service/AlertService';
import {Institution} from '@shared/model/Institution';
import {InstitutionFragebogenStatus} from '@shared/model/InstitutionFragebogenStatus';
import {UUID} from '@util/export-types';
import {firstValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class InstitutionAntwortService {

	constructor(private http: HttpClient, private alerts: AlertService) {
	}

	getInstitutionenUndStatusFuerFragebogen(fragebogenId: UUID): Promise<InstitutionFragebogenStatus[]> {
		return firstValueFrom(this.http.get<InstitutionFragebogenStatus[]>('/api/editor/fragebogen/' + fragebogenId + '/institutionen'))
			.then(response => response.map(
				institutionStatus => new InstitutionFragebogenStatus(
					new Institution(institutionStatus.institution as Institution),
					institutionStatus.abgeschlossen,
					new Date(institutionStatus.abschlussdatum))))
			.catch(reason => {
				this.alerts.handleHttpError(reason);
				return [];
			});
	}
}
