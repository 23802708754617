import {Component, HostListener, NgZone} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {BlockUI, BlockUIModule, NgBlockUI} from "ng-block-ui";
import {ToastModule} from 'primeng/toast';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	standalone: true,
	imports: [RouterOutlet, ToastModule, BlockUIModule]
})
export class AppComponent {

	@BlockUI()
	blockUI: NgBlockUI;

	constructor(private router: Router, private ngZone: NgZone) {
		/*const events = router.events.pipe(filter(event => event instanceof NavigationEnd));
		events.subscribe((e: NavigationEnd) => {
			 this.blockUI.block();
		})*/
	}

	handleClick(event: Event): void {
		if (event.target instanceof HTMLAnchorElement) {
			const element = event.target as HTMLAnchorElement;
			if (element.className === 'routerlink') {
				event.preventDefault();
				const route = element?.getAttribute('href');

				this.ngZone.run(() => {
					if (route) {
						this.router.navigate([`/${route}`]);
					}
				})
			}
		}
	}

	@HostListener('window:beforeunload', ['$event'])
	unloadHandler(event: Event) {
		console.log(event)
		// confirm(event + "")
	}

	ngOnInit() {
		function getCookie(cookieName) {
			let cookie = {};
			document.cookie.split(';').forEach(function (el) {
				let [key, value] = el.split('=');
				cookie[key.trim()] = value;
			})
			return cookie[cookieName];
		}

		// window.addEventListener('beforeunload', (event) => {
		// 	let cookieVal = getCookie("JUREC_SESSION")
		// 	// console.log(cookieVal)
		// 	if (cookieVal != null && (cookieVal+"").length > 0)
		// 		event.returnValue = `Wollen Sie JUREC wirklich ohne Logout verlassen?`;
		// });

		this.ngZone.runOutsideAngular(() => {
			document.body.addEventListener('click', ($event) => this.handleClick($event));
			window.addEventListener("keyup", disableF5);
			window.addEventListener("keydown", disableF5);
		});


		function unloadHandlerFunction(event) {
			console.log(event)
			// confirm(event + "")
		}

		function disableF5(e) {
			// var press = window.event? event : e
			if (e.keyCode == 82 && e.ctrlKey) {
				console.log("Ctrl + R is disabled");
				e.preventDefault();
			}

			if ((e.which || e.keyCode) == 116) {
				console.log("F5 is disabled");
				e.preventDefault();
			}
		}
	}
}
