import { VerwaltbarerAusfuellerAbschnittDto } from '@benutzerverwaltung/model/VerwaltbarerAusfuellerAbschnittDto';
import { RolleTitleUtil } from "../../util/RolleTitleUtil";

export class VerwaltbarerAusfuellerFragebogenDto {
    id: string;
    name: string;
    label: string;
    hatBerechtigungFuerDiesesObjekt: boolean;

    abschnitte: VerwaltbarerAusfuellerAbschnittDto[] = [];

    abschnitteGesamt: number;
    abschnitteBerechtigt: number;
    title: String;

    constructor(verwaltbarerAusfuellerFragebogenDto?: VerwaltbarerAusfuellerFragebogenDto) {
        if (verwaltbarerAusfuellerFragebogenDto) {
            this.id = verwaltbarerAusfuellerFragebogenDto.id;
            this.name = verwaltbarerAusfuellerFragebogenDto.name;
            this.label = verwaltbarerAusfuellerFragebogenDto.label;
            this.title = RolleTitleUtil.dekliniere(this.label);


            this.abschnitte = verwaltbarerAusfuellerFragebogenDto.abschnitte
                .map(abschnitt => new VerwaltbarerAusfuellerAbschnittDto(abschnitt));

            this.abschnitteGesamt = verwaltbarerAusfuellerFragebogenDto.abschnitteGesamt;
            this.abschnitteBerechtigt = verwaltbarerAusfuellerFragebogenDto.abschnitteBerechtigt;
            this.hatBerechtigungFuerDiesesObjekt = verwaltbarerAusfuellerFragebogenDto.hatBerechtigungFuerDiesesObjekt;
        }
    }
}
