import {Component, HostListener, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faArrowsRotate, faPencilAlt, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DropdownModule} from 'primeng/dropdown';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {InputTextModule} from "primeng/inputtext";
import {Table, TableModule} from 'primeng/table';
import {AusfuellerVerwaltungFormConfiguration} from "../../../form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration";
import {UserService} from "../../../form-viewer/service/UserService";
import {VerwaltbarerAusfuellerDto} from "../../model/VerwaltbarerAusfuellerDto";
import {VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto} from "../../model/VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto";
import {VerwaltbarerAusfuellerRecherchekomplexDto} from "../../model/VerwaltbarerAusfuellerRecherchekomplexDto";
import {AusfuellerService} from "../../services/AusfuellerService";
import {EventService} from "../../services/EventService";
import {BenutzerBearbeitenComponent} from "../registrierte-benutzer/benutzer-bearbeiten/benutzer-bearbeiten.component";

@Component({
	selector: "app-offene-einladungen",
	templateUrl: "./offene-einladungen.component.html",
	styleUrls: ["./offene-einladungen.component.less"],
	providers: [DialogService, ConfirmationService],
	standalone: true,
	imports: [ConfirmDialogModule, FontAwesomeModule, TableModule, DropdownModule, FormsModule, InputTextModule],
})
export class OffeneEinladungenComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	public ausfuellerListe: VerwaltbarerAusfuellerDto[];
	public verwaltbarerAusfueller: VerwaltbarerAusfuellerDto;
	public verwaltbarerRecherchekomplex: VerwaltbarerAusfuellerRecherchekomplexDto;
	public institutionenUndRechechekomplexe: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto[];
	public selectedValue: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto;
	public anreden: any[];

	public rows: number;
	ref: DynamicDialogRef;
	configuration: AusfuellerVerwaltungFormConfiguration;
	faPen = faPencilAlt;
	faTrash = faTrashCan;
	faArrowsRotate = faArrowsRotate;
	private screenWidth: number;
	private screenHeight: number;

	constructor(
		public ausfuellerService: AusfuellerService,
		public userService: UserService,
		private dialogService: DialogService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		private eventService: EventService
	) {}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.calcTabelRows(event.target.innerWidth, event.target.innerHeight);
	}

	ngOnInit() {
		this.calcTabelRows(window.innerWidth, window.innerHeight);
		// this.rufeNeueAusfuellerlisteAb();
		this.anreden = [
			{ label: "Herr", value: "herr" },
			{ label: "Frau", value: "frau" },
		];

		this.eventService
			.userOffeneEinladungenListener()
			.subscribe((event) => this.rufeNeueAusfuellerlisteAb());

		// this.isAlive()
	}

	update() {
		this.blockUI.start("Daten werden verarbeitet...");
		this.rufeNeueAusfuellerlisteAb();
	}

	rufeNeueAusfuellerlisteAb() {
		// console.log("rufeNeueAusfuellerlisteAb")

		this.ausfuellerService
			.holeAusfuellerForCurrentInst(false)
			.then((ausfuellerDtoListe) => {
				this.ausfuellerListe = ausfuellerDtoListe.filter(
					(ausfueller) =>
						!ausfueller.enabled && !ausfueller.archiviert
				);

				this.institutionenUndRechechekomplexe = [];

				if (this.ausfuellerListe) {
					this.ausfuellerListe.forEach((ausfueller) => {
						let wrapper =
							new VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto(
								ausfueller
							);
						if (wrapper) {
							this.institutionenUndRechechekomplexe.push(wrapper);
						}
					});
				}

				this.blockUI.stop();
			});
	}

	getAnzahlNichtRegistrierterNutzer() {
		return (this.ausfuellerListe || []).filter((o) => !o.enabled).length;
	}

	clear(table: Table) {
		table.clear();
	}

	neueEinladungAnlegen() {}

	loescheNichtRegistriertenBenutzer(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		// this.isAlive()

		this.getAusfuellerObj(
			verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
		);
		if (this.verwaltbarerAusfueller == null) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary: "Es konnte kein Benutzer zum löschen geladen werden",
			});
		}

		this.confirmationService.confirm({
			message:
				'\nWollen Sie den nicht registrierten Benutzer "' +
				this.verwaltbarerAusfueller.anzeigename +
				'" wirklich löschen?\n',
			acceptLabel: "Löschen",
			rejectLabel: "Abbrechen",
			header: "Bitte Löschen bestätigen",
			icon: "pi pi-info-circle",
			accept: () => {
				this.messageService.clear();
				this.messageService.add({
					severity: "info",
					summary: "Benutzer wird gelöscht...",
				});
				this.alleRechteEinesAusfuellersLoeschen(
					verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
				);
			},
			reject: (type) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Löschen wurde abgebrochen",
						});
						this.verwaltbarerAusfueller = null;
						break;
					case ConfirmEventType.CANCEL:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Löschen wurde abgebrochen",
						});
						this.verwaltbarerAusfueller = null;
						break;
				}
			},
		});
	}

	alleRechteEinesAusfuellersLoeschen(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		this.getAusfuellerObj(
			verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
		);
		if (this.verwaltbarerAusfueller == null) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary: "Es konnte kein Benutzer zum löschen geladen werden",
			});
		}

		this.ausfuellerService
			.alleRechteEinesAusfuellersLoeschen(this.verwaltbarerAusfueller)
			.then(() => {
				this.rufeNeueAusfuellerlisteAb();
				this.messageService.add({
					severity: "success",
					summary: "Der Benutzer wurde gelöscht.",
				});
				//this.baumWurdeVeraendert.emit();
			});
	}

	baumveraenderungBehandeln() {
		this.rufeNeueAusfuellerlisteAb();
	}

	trackeVeraenderungenAmBaum(index, verwaltbarerAusfueller) {
		return verwaltbarerAusfueller.id;
	}

	onPageChange(pageIndex) {
		// alert(pageIndex);
	}

	isAlive() {
		this.userService.isAlive();
	}

	bearbeiteEingeladenenBenutzer(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		this.isAlive();

		//TODO: Umbau auf REST
		this.getAusfuellerObj(
			verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
		);

		if (this.verwaltbarerAusfueller == null) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary: "Es konnte kein Benutzer geladen werden",
			});
			return;
		}

		if (this.verwaltbarerAusfueller != null) {
			this.ref = this.dialogService.open(BenutzerBearbeitenComponent, {
				header: "Noch nicht registrierten Benutzer bearbeiten",
				width: "100%",
				height: "100%",
				styleClass: "FullScreenDialog",
				contentStyle: {
					height: "100%",
					width: "100%",
					"max-width": "100%",
					"max-height": "100%",
					overflow: "auto",
				},
				baseZIndex: 10000,
				style: { "max-width": "100%", "max-height": "100%" },
				data: {
					verwaltbarerAusfueller: this.verwaltbarerAusfueller,
					einladung: true,
				},
			});
		}
	}

	private calcTabelRows(width, height) {
		this.screenWidth = width;
		this.screenHeight = height;
		this.rows = Math.floor(
			(this.screenHeight - 65 - 40 - 40 - 210) / 41 - 1
		);
		if (this.rows < 4) {
			this.rows = 4;
		}
	}

	private getAusfuellerObj(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		for (let ausfueller of this.ausfuellerListe) {
			// console.log(verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto.ausfuellerName);
			if (
				verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto.ausfuellerId ===
				ausfueller.id
			) {
				this.verwaltbarerAusfueller = ausfueller;
				break;
			}
		}
	}
}

