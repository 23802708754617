import { Component, Input } from '@angular/core';
import { Abschnitt } from '@shared/model/Abschnitt';
import { OrderListModule } from 'primeng/orderlist';

@Component({
	selector: "app-abschnitt-sortieren",
	templateUrl: "./AbschnittSortierenComponent.html",
	styleUrls: ["./AbschnittSortierenComponent.less"],
	standalone: true,
	imports: [OrderListModule],
})
export class AbschnittSortierenComponent {
	@Input()
	abschnitte: Abschnitt[] = [];

	@Input()
	disabled = false;
}
