import {NgFor, NgIf} from '@angular/common';
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from "@form-viewer/service/UserService";
import {NewTabComponent} from "@shared/components/new-tab.component";
import {Fragebogen} from '@shared/model/Fragebogen';
import {Recherchekomplex} from '@shared/model/Recherchekomplex';
import {EditLockService} from '@shared/service/EditLockService';
import {RecherchekomplexService} from '@shared/service/RecherchekomplexService';
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";
import {DialogModule} from 'primeng/dialog';
import {FormEditorVorschauComponent} from "../vorschau/FormEditorVorschauComponent";

@Component({
	selector: "app-form-editor-header",
	templateUrl: "./FormEditorHeaderComponent.html",
	styleUrls: ["./FormEditorHeaderComponent.less"],
	standalone: true,
	imports: [NgIf, NgFor, DialogModule, NewTabComponent, PreventDefaultDirective]
})
export class FormEditorHeaderComponent {
	@Input()
	fragebogen: Fragebogen;

	@Input()
	enableMove: boolean;

	@Output()
	beginMove = new EventEmitter<void>();

	@Output()
	beginEditMetadata = new EventEmitter<void>();

	@ViewChild("vorschau")
	vorschau: NewTabComponent;

	showVorschau = false;
	recherchekomplexeVisible: boolean;
	recherchekomplexe: Recherchekomplex[];

	constructor(
		private lockService: EditLockService,
		private recherchekomplexService: RecherchekomplexService,
		private router: Router,
		public userService: UserService
	) {}

	onBeginMoveClicked() {
		if (!this.moveButtonEnabled()) {
			return;
		}

		this.beginMove.emit();
	}

	onBeginEditMetadataClicked() {
		if (!this.metadataButtonEnabled()) {
			return;
		}

		this.beginEditMetadata.emit();
	}

	metadataButtonEnabled() {
		return !this.lockService.isLocked();
	}

	moveButtonEnabled() {
		return this.enableMove && !this.lockService.isLocked();
	}

	showRecherchekomplexModal() {
		this.recherchekomplexService
			.getRecherchekomplexe()
			.then((r) => (this.recherchekomplexe = r));
		this.recherchekomplexeVisible = true;
	}

	goToEditor() {
		const index = this.fragebogen.veroeffentlicht ? "1" : "0";
		this.router.navigate([`/editor/fragebogen`], { fragment: index });
	}

	openVorschau() {
		this.showVorschau = true;
		this.vorschau.openContent(FormEditorVorschauComponent, "Vorschau");
	}
}
