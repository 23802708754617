import { Component, Input, ViewChild } from '@angular/core';
import { FragebogenStatusService } from '@form-viewer/service/FragebogenStatusService';
import { Fragebogen } from '@shared/model/Fragebogen';
import { NavigationService } from '@shared/service/NavigationService';
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';

const debug = require('debug')('FragebogenAbschliessenComponent');

@Component({
	selector: "app-fragebogen-aufschliessen",
	templateUrl: "./FragebogenAufschliessenComponent.html",
	styleUrls: ["./FragebogenAufschliessenComponent.less"],
	standalone: true,
	imports: [ModalModule],
})
export class FragebogenAufschliessenComponent {
	private static readonly FRAGEBOGEN_AUFGESCHLOSSEN_MELDUNG_ANZEIGE_DAUER_SEKUNDEN = 3;

	@Input()
	fragebogen: Fragebogen;

	@ViewChild("aufschliessenModal", { static: true })
	aufschliessenModal: ModalDirective;

	constructor(
		private navigationService: NavigationService,
		private fragebogenStatusService: FragebogenStatusService
	) {}

	openDialog() {
		this.aufschliessenModal.show();
	}

	closeDialog() {
		this.aufschliessenModal.hide();
	}

	commitAufschliessen() {
		this.closeDialog();

		this.fragebogenStatusService
			.schliesseFragebogenAuf(this.fragebogen)
			.then(() => {
				this.initializeRedirect();
			})
			.catch((error) => {
				debug("Fehler beim Aufschliessen des Fragebogens", error);
			});
	}

	private initializeRedirect() {
		setTimeout(() => {
			this.navigationService.goToFragebogenuebersicht();
		}, FragebogenAufschliessenComponent.FRAGEBOGEN_AUFGESCHLOSSEN_MELDUNG_ANZEIGE_DAUER_SEKUNDEN * 1000);
	}
}
