import { NgIf } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PasswordStrengthComponent } from "./PasswordStrengthComponent";

const zxcvbn = require('zxcvbn/dist/zxcvbn.js');

@Component({
	selector: "app-password-eingabe",
	templateUrl: "./PasswordEingabeComponent.html",
	styleUrls: ["./PasswordEingabeComponent.less"],
	standalone: true,
	imports: [TooltipModule, FormsModule, PasswordStrengthComponent, NgIf]
})
export class PasswordEingabeComponent implements OnInit {
	private static readonly PASSWORD_INVALID_WARNING =
		"Das Passwort enthält ungültige Zeichen: ";
	private static readonly PASSWORD_EMPTY_WARNING =
		"Bitte geben Sie ein Passwort ein.";
	private static readonly PASSWORD_MISSMATCH_WARNING =
		"Die eingegebenen Passwörter stimmen nicht überein.";
	private static readonly PASSWORD_WEAK_WARNING =
		"Das eingegebene Passwort ist zu schwach.";
	private static readonly APPLICATION_SPECIFIC_WORDS = [
		"Juve",
		"Recherche",
		"Steuermarkt",
		"Wirtschaft",
		"Kanzlei",
		"Handbuch",
	];

	// from https://github.com/dropbox/zxcvbn/blob/master/src/feedback.coffee
	private static readonly ZXCVBN_TRANSLATIONS = {
		"use a few words, avoid common phrases":
			"Verwenden Sie mehrere Wörter, vermeiden Sie häufige Phrasen.",
		"no need for symbols, digits, or uppercase letters":
			"Es ist nicht notwendig, Symbole, Ziffern oder Großbuchstaben zu verwenden.",
		"add another word or two. uncommon words are better.":
			"Fügen Sie ein oder zwei weitere Wörter hinzu. Ungewöhnliche Wörter sind besser.",
		"straight rows of keys are easy to guess":
			"Reihen von Tasten sind leicht zu erraten.",
		"short keyboard patterns are easy to guess":
			"Kurze Tastaturmuster sind leicht zu erraten.",
		"use a longer keyboard pattern with more turns":
			"Verwenden Sie ein längeres Tastaturmuster mit mehr Wendungen.",
		'repeats like "aaa" are easy to guess':
			'Wiederholungen wie "aaa" sind leicht zu erraten.',
		'repeats like "abcabcabc" are only slightly harder to guess than "abc"':
			'Wiederholungen wie "abcabcabc" sind nur etwas schwerer zu erraten als "abc".',
		"avoid repeated words and characters":
			"Vermeiden Sie wiederholte Wörter und Zeichen.",
		"avoid sequences": "Vermeiden Sie Sequenzen.",
		"avoid recent years": "Vermeide die letzten Jahre.",
		"avoid years that are associated with you":
			"Vermeiden Sie Jahre, die mit Ihnen verbunden sind.",
		"avoid dates and years that are associated with you":
			"Vermeiden Sie Daten und Jahre, die mit Ihnen verbunden sind.",
		"this is a top-10 common password":
			"Dies ist eines der Top-10 Passwörter.",
		"this is a top-100 common password":
			"Dies ist eines der Top-100 Passwörter.",
		"this is a very common password":
			"Dies ist ein sehr häufiges Passwort.",
		"this is similar to a commonly used password":
			"Dies ähnelt einem häufig verwendeten Passwort.",
		"a word by itself is easy to guess":
			"Ein einzelnes Wort ist leicht zu erraten.",
		"names and surnames by themselves are easy to guess":
			"Vor- und  Familiennamen sind leicht zu erraten.",
		"common names and surnames are easy to guess":
			"Häufige Vor- und Nachnamen sind leicht zu erraten.",
		"capitalization doesn't help very much":
			"Großschreibung hilft nicht sehr.",
		"all-uppercase is almost as easy to guess as all-lowercase":
			"Nur-Großbuchstaben sind fast so einfach zu erraten wie Nur-Kleinbuchstaben.",
		"reversed words aren't much harder to guess":
			"Umgekehrte Wörter sind nicht viel schwerer zu erraten.",
		"predictable substitutions like '@' instead of 'a' don't help very much":
			'Vorhersehbare Substitutionen wie "@" anstelle von "a" helfen nicht sehr.',
	};

	@ViewChild("passwordFeld", { static: true })
	private passwordFeld: ElementRef<HTMLInputElement>;

	password = "";
	matchingPassword = "";

	passwordWarning: string;
	passwordSuggestions: string[];
	passwordScore: number;
	invalidCharacters: string;

	@Input()
	username: string;
	pristine: boolean;

	private static translate(str: string) {
		const key = str.toLowerCase();
		if (key in PasswordEingabeComponent.ZXCVBN_TRANSLATIONS) {
			return PasswordEingabeComponent.ZXCVBN_TRANSLATIONS[key];
		}

		return str;
	}

	ngOnInit(): void {
		this.pristine = true;
	}

	// zusammendfassende Meldung für den SubmitButton
	getErrorMessage() {
		if (this.hasPasswordError()) {
			return this.getPasswordErrorMessage();
		}

		if (this.hasMatchingPasswordError()) {
			return this.getMatchingPasswordErrorMessage();
		}

		return "";
	}

	isPristine(): boolean {
		return this.pristine;
	}

	hasError(): boolean {
		return this.hasMatchingPasswordError() || this.hasPasswordError();
	}

	// Meldung für das erste Passwort-Feld
	getPasswordErrorMessage(): string {
		if (this.isEmpty()) {
			return PasswordEingabeComponent.PASSWORD_EMPTY_WARNING;
		}

		if (this.isWeak()) {
			return PasswordEingabeComponent.PASSWORD_WEAK_WARNING;
		}

		if (this.invalidCharacters) {
			return (
				PasswordEingabeComponent.PASSWORD_INVALID_WARNING +
				this.invalidCharacters
			);
		}

		return "";
	}

	hasPasswordError(): boolean {
		return (
			!this.isPristine() && (this.isWeak() || !!this.invalidCharacters)
		);
	}

	hasAcceptablePassword(): boolean {
		return !this.isPristine() && !this.isWeak() && !this.invalidCharacters;
	}

	// Meldung für das zweite Passwort-Feld
	getMatchingPasswordErrorMessage(): string {
		if (this.isEmpty()) {
			return PasswordEingabeComponent.PASSWORD_EMPTY_WARNING;
		}

		if (this.isPasswordMissmatch()) {
			return PasswordEingabeComponent.PASSWORD_MISSMATCH_WARNING;
		}

		return "";
	}

	hasMatchingPasswordError(): boolean {
		return !this.isPristine() && this.isPasswordMissmatch();
	}

	hasMatchingPassword(): boolean {
		return !this.isPristine() && !this.isPasswordMissmatch();
	}

	// Test-Implementierungen
	private isEmpty(): boolean {
		return this.password.length === 0;
	}

	private isWeak(): boolean {
		return this.passwordScore <= 2;
	}

	private isPasswordMissmatch(): boolean {
		return this.password !== this.matchingPassword;
	}

	validiere() {
		const user_specific_words = [this.username];

		this.pristine = false;
		const analysis = zxcvbn(
			this.password,
			PasswordEingabeComponent.APPLICATION_SPECIFIC_WORDS.concat(
				user_specific_words
			)
		);
		this.passwordWarning = PasswordEingabeComponent.translate(
			analysis.feedback.warning
		);
		this.passwordSuggestions = analysis.feedback.suggestions.map(
			PasswordEingabeComponent.translate
		);
		this.passwordScore = analysis.score;
		this.invalidCharacters = "";
		this.invalidCharacters = this.findNonISO8859(this.password);
	}

	private findNonISO8859(str): string {
		const match = str?.match(/[^\u0000-\u00ff]/g);
		return match ? match.filter(this.onlyUnique).join(", ") : "";
	}

	private onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	onPasswordChanged() {
		this.pristine = false;
		this.validiere();
	}
}
