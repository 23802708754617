import {NgClass} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {PreventSubmitOnEnterDirective} from "@util/PreventSubmitOnEnterDirective";
import {PopoverDirective, PopoverModule} from 'ngx-bootstrap/popover';

@Component({
	selector: "app-editor-info-text",
	templateUrl: "./InfoTextEditComponent.html",
	styleUrls: ["./InfoTextEditComponent.less"],
	standalone: true,
	imports: [PopoverModule, FormsModule, NgClass, PreventSubmitOnEnterDirective]
})
export class InfoTextEditComponent implements OnInit {
	@Output()
	infoTextChanged = new EventEmitter();

	@Input()
	beschreibung: string;

	@Input()
	ueberschrift: string;

	@ViewChild("pop", { static: true })
	pop: PopoverDirective;

	oldValue = "";

	constructor() {}

	ngOnInit(): void {
		this.oldValue = this.beschreibung;
	}

	resetDescription(event) {
		this.beschreibung = this.oldValue;
		this.pop.hide();
		event.stopPropagation();
	}

	saveDescription() {
		this.oldValue = this.beschreibung;
		this.infoTextChanged.emit(this.beschreibung);
		this.pop.hide();
	}

	hasBeschreibung() {
		return !!this.beschreibung;
	}

	preventSubmit(event) {
		event.stopPropagation();
	}
}
