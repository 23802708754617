import { InputTypeAuswahlViewComponent } from '@form-viewer/abschnitt/frage-liste/frage/view/input/input-type-auswahl-view/InputTypeAuswahlViewComponent';
import { InputTypeAuswahlEditComponent } from "@shared/components/input-type/auswahl/InputTypeAuswahlEditComponent";
import { InputTypeAuswahlAntwort } from "@shared/model/antwort/input/InputTypeAuswahlAntwort";
import { InputType } from "@shared/model/frage/input/InputType";

export class InputTypeAuswahl extends InputType {
	static override readonly ID = 'InputTypeAuswahl';

	static override readonly TYPE_DISPLAY_NAME = 'Auswahl';
	static override readonly ANTWORT = InputTypeAuswahlAntwort;

	static override readonly VIEWER = InputTypeAuswahlViewComponent;
	static override readonly EDITOR = InputTypeAuswahlEditComponent;

	options: string[] = [];
	sonstigesTextfeld = false;

	constructor(type?: InputTypeAuswahl) {
		super(type);
		if (type) {
			this.options = type.options;
			this.sonstigesTextfeld = type.sonstigesTextfeld;
		}
	}
}
