import { TabellenZellenSpaltenTupel } from '@form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabellenZellenSpaltenTupel';
import { AntwortZelle } from '@shared/model/antwort/tabellenfrage/AntwortZelle';
import { TabellenSpalte } from '@shared/model/frage/TabellenSpalte';

export class TabellenZeileView {
	titel: string;
	zellenSpaltenTupel: TabellenZellenSpaltenTupel[];

	static erzeugeSpaltenZellenTupel(spalten: TabellenSpalte[], antworten: AntwortZelle[]) {
		const zellenSpaltenTupel = [];
		let spaltenIndex = 0;
		for (const spalte of spalten) {
			zellenSpaltenTupel.push(new TabellenZellenSpaltenTupel(spalte, antworten[spaltenIndex].antwort));
			spaltenIndex++;
		}
		return zellenSpaltenTupel;
	}

	constructor(spalten: TabellenSpalte[], antworten: AntwortZelle[]) {
		this.zellenSpaltenTupel = TabellenZeileView.erzeugeSpaltenZellenTupel(spalten, antworten);
	}

	isEmpty() {
		const zellenMitAntworten = this.zellenSpaltenTupel.filter(
			zellenSpaltenTupel => !zellenSpaltenTupel.antwort.isEmpty()
		);

		return zellenMitAntworten.length === 0;
	}
}
