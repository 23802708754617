import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ausfueller } from '@form-viewer/model/Ausfueller';
import { AlertService } from '@shared/alert/service/AlertService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { AktuelleBerechtigungService } from '@shared/service/AktuelleBerechtigungService';

const debug = require('debug')('AbschnittAusfuellerService');

@Injectable({providedIn: 'root'})
export class AbschnittAusfuellerService {

	constructor(private http: HttpClient, private alertService: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	getAusfuellerFuerAbschnitt(abschnitt: Abschnitt): Promise<Ausfueller[]> {
		debug('Lade Ausfüller für Abschnitt', abschnitt);
		return this.http.get<Abschnitt[]>('/view/abschnitt/unterausfueller', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				abschnitt: abschnitt.id,
			})
		})
			.toPromise()
			.then(response => Ausfueller.buildList(response))
			.catch(this.alertService.handleHttpError);
	}
}
