import { InputTypeGanzzahlViewComponent } from "@form-viewer/abschnitt/frage-liste/frage/view/input/input-type-ganzzahl-view/InputTypeGanzzahlViewComponent";
import { InputTypeGanzzahlEditComponent } from "@shared/components/input-type/ganzzahl/InputTypeGanzzahlEditComponent";
import { InputTypeGanzzahlAntwort } from "@shared/model/antwort/input/InputTypeGanzzahlAntwort";
import { InputType } from "@shared/model/frage/input/InputType";

export class InputTypeGanzzahl extends InputType {
	static override readonly ID = 'InputTypeGanzzahl';
	static override readonly TYPE_DISPLAY_NAME = 'Ganzzahl';
	static override readonly ANTWORT = InputTypeGanzzahlAntwort;

	// add to @NgModule.entryComponents in app.module.ts
	static override readonly VIEWER = InputTypeGanzzahlViewComponent;
	static override readonly EDITOR = InputTypeGanzzahlEditComponent;

	platzhalterText = '';
	vorkommastellen = 0;

	constructor(type?: InputTypeGanzzahl) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
			this.vorkommastellen = type.vorkommastellen;
		}
	}

}
