import { Frage } from '@shared/model/Frage';
import { FrageTypes } from '@shared/model/FrageTypes';

export class FrageBuilderUtil {
	static build(input: any): Frage {
		if (input) {
			const type = FrageTypes.typeForID(input.type);
			if (type) {
				return new type(input);
			}

			return new Frage(input as Frage);
		} else {
			return null;
		}
	}
}
